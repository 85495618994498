export interface NewPolicy {
    id?:number | undefined
    number: string;
    effective_start_date?: Date;
    effective_end_date?: Date;
    members_insured?: number;
    free_plan?: string;
    carrier_id?: number;
    policy_state?: number;
    is_new?: boolean;
    agent_id?: number;
    agent_commission_rule_id?: number;
};

export const newPolicyInitialValues = {
    number: "",
    policy_state: 1,
    is_new: true
}

export interface AllPolicies {
    pageNumber: number,
    pagePolicies: Policy[]
}

export interface PolicyResponse {
    policies: Policy[];
    page: number,
    totalPages: number,
    totalCount: number,
    pageSize: number,
    //filters?: ClientsFilters
}

export interface Policy {
    id: number;
    number: string;
    effective_start_date?: Date;
    effective_end_date?: Date;
    carrier_id?: number;
    carrier?: string;
    client_id?: number;
    client?: string;
    agency_id?: number;
    agent_id?: number;
    agent?: string;
    lives?: number;
    commission_per_life?: number;
    agent_commission_rule_id?: number;
    members_insured?: number;
    free_plan?: string;
    policy_state?: number;
    last_update?: Date;
    created_at?: Date;
}

export interface EditPolicy {
    id?: number;
    number: string;
    effective_start_date?: Date;
    effective_end_date?: Date;
    carrier_id?: number;
    members_insured?: number;
    free_plan?: string;
    policy_state?: number;
    delete_at?: Date;
    is_new?: boolean;
    agent_id?: number;
    agent_commission_rule_id?: number;
}

export interface MatchedPolicyWithCommission {
    id: number;
    policy_number: string;
    carrier_id: number;
    members_insured: number;
    client_id: number;
    effective_start_date: Date;
    effective_end_date?: Date;
    free_plan?: string;
    agent_id: number;
    agent_commission_rule_id?: number;
    billing_date?: Date;
    statement_date?: Date;
    policy_state?: number;
    commission?: number;
    policy_id_to_override?: number;
}