import classNames from "classnames";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import logoAsureis from "../../assets/images/Empty_logo.svg";
import { SellsFilter } from "../../enums/SellsFilter";
import { ChartData } from "../../models/ChartData";
import { fetchMembersTotalByCarrier } from "../../state/dashboard/actions";
import { AppDispatch, RootState } from "../../state/store";
import { getStartEndDates } from "../../utils/functions";
import { LoadingMask } from "../LoadingMask";
import Widget from "./Widget";

interface ChartProps {
  data: ChartData[];
  isLightBlue?: boolean;
  title: string;
  subtitle: string;
  className?: string;
}

export const CarrierMembersSellsWidget = ({
  data,
  isLightBlue,
  title,
  subtitle,
  className,
}: ChartProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const currentUser = useSelector(
    (state: RootState) => state.users.currentUser
  );
  const { carrierTotalMembers, carrierMembersSellFilter } = useSelector(
    (state: RootState) => state.dashboard
  );
  const [reloadingCarrierTotalMembers, setReloadingCarrierTotalMembers] =
    useState(false);
  const [sellFilter, setSellFilter] = useState(
    carrierMembersSellFilter ?? SellsFilter.day
  );

  useEffect(() => {
    if (currentUser) {
      const { start, end, timeZone } = getStartEndDates(sellFilter);

      const params = {
        startDate: start,
        endDate: end,
        timeZone: timeZone,
        sellsFliter: sellFilter,
      };
      dispatch(fetchMembersTotalByCarrier(params));
    }
  }, [currentUser, dispatch, sellFilter]);

  const handleChangeSellsFilter = (filter: SellsFilter) => {
    setSellFilter(filter);
    reloadCarrierTotalMembers(filter);
  };

  const reloadCarrierTotalMembers = (filter: SellsFilter) => {
    setReloadingCarrierTotalMembers(true);
    const { start, end, timeZone } = getStartEndDates(filter);

    const params = {
      startDate: start,
      endDate: end,
      timeZone: timeZone,
      sellsFliter: filter,
    };
    dispatch(fetchMembersTotalByCarrier(params)).then(() => {
      setReloadingCarrierTotalMembers(false);
    });
  };

  const CustomizedAxisTick: FunctionComponent<any> = (props: any) => {
    const { x, y, payload, index } = props;
    const displayValue = index % 2 === 0 ? t(`${payload.value}`) : "";

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          style={{ fontSize: "16" }}
          x={0}
          y={-5}
          dy={13}
          textAnchor="end"
          fill="#666"
          transform="rotate(-40)"
        >
          {t(`${payload.value}`)}
        </text>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="w-full bg-white rounded-lg border-2 p-4 flex flex-col justify-center">
          <p className="text-center text-lg font-medium">{t(`${label}`)}</p>
          <p
            className={classNames({
              "text-center text-lg": true,
              "text-light-blue": isLightBlue,
              "text-gold": !isLightBlue,
            })}
          >{`value: ${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      {reloadingCarrierTotalMembers && <LoadingMask />}
      <Widget>
        <div className="w-full flex flex-row justify-between items-center py-5 px-5">
          <div className="w-full max-w-80 mr-5"></div>
          <div className="flex flex-row items-center">
            <div
              className={classNames({
                "text-center hover:bg-neutral-200 hover:cursor-pointer rounded-lg px-3 py-1":
                  true,
                "bg-neutral-200": sellFilter === SellsFilter.day,
              })}
              style={{ margin: "-1px" }}
              onClick={() => handleChangeSellsFilter(SellsFilter.day)}
            >
              <div className="text-xs text-gray-500">{t("SHOW")}</div>
              <div className="text-blue text-lg font-medium">1D</div>
            </div>
            <div className="bg-neutral-200 h-10 w-px"></div>
            <div
              className={classNames({
                "text-center hover:bg-neutral-200 hover:cursor-pointer rounded-lg px-3 py-1":
                  true,
                "bg-neutral-200": sellFilter === SellsFilter.week,
              })}
              style={{ margin: "-1px" }}
              onClick={() => handleChangeSellsFilter(SellsFilter.week)}
            >
              <div className="text-xs text-gray-500">{t("SHOW")}</div>
              <div className="text-blue text-lg font-medium">1W</div>
            </div>
            <div className="bg-neutral-200 h-10 w-px"></div>
            <div
              className={classNames({
                "text-center hover:bg-neutral-200 hover:cursor-pointer rounded-lg px-3 py-1":
                  true,
                "bg-neutral-200": sellFilter === SellsFilter.month,
              })}
              style={{ margin: "-1px" }}
              onClick={() => handleChangeSellsFilter(SellsFilter.month)}
            >
              <div className="text-xs text-gray-500">{t("SHOW")}</div>
              <div className="text-blue text-lg font-medium">1M</div>
            </div>
            <div className="bg-neutral-200 h-10 w-px"></div>
            <div
              className={classNames({
                "text-center hover:bg-neutral-200 hover:cursor-pointer rounded-lg px-3 py-1":
                  true,
                "bg-neutral-200": sellFilter === SellsFilter.all,
              })}
              style={{ margin: "-1px" }}
              onClick={() => handleChangeSellsFilter(SellsFilter.all)}
            >
              <div className="text-xs text-gray-500">{t("SHOW")}</div>
              <div className="text-blue text-lg font-medium">All</div>
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col">
          <div
            className={classNames({
              "text-xl font-bold text-gold mt-3 flex justify-center": true,
              "text-light-blue": isLightBlue,
              "text-gold": !isLightBlue,
            })}
          >
            {title}
          </div>
          <div className="text-blue text-base text-justify mt-3 mx-3 flex justify-center">
            {subtitle}
          </div>
        </div>
        {carrierTotalMembers.length > 0 && (
          <div style={{ width: "100%", height: "100%" }}>
            <ResponsiveContainer width={"99%"} height={380}>
              <BarChart
                margin={{
                  top: 50,
                  right: 30,
                  left: 20,
                  bottom: 50,
                }}
                data={carrierTotalMembers}
              >
                <CartesianGrid strokeDasharray="3 3" stroke="#eee" />
                <YAxis
                  stroke="#577082"
                  allowDecimals={false}
                  interval={"preserveStartEnd"}
                />
                <XAxis
                  dataKey="key"
                  height={62}
                  interval={carrierTotalMembers.length <= 14 ? 0 : 1}
                  tick={<CustomizedAxisTick />}
                  tickMargin={3}
                  tickSize={5}
                />
                <Tooltip content={<CustomTooltip />} />
                <Bar
                  dataKey="value"
                  fill={isLightBlue ? "#1f99ef" : "#D4AF37"}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        )}
        {carrierTotalMembers.length === 0 && (
          <div className="w-full flex flex-col items-center justify-center mt-7 mb-8">
            <img src={logoAsureis} alt="Asureis Logo" className="h-32" />
            <div className="text-gray-500 text-xl font-medium mt-2">
              {t("NO_MEMBERS_SOLD")}
            </div>
          </div>
        )}
      </Widget>
    </>
  );
};
