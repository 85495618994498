import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline";
import { t } from "i18next";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AgentsTwilioNumbers } from "../../models/User";
import { AppDispatch } from "../../state/store";
import { FormSearchableSelect } from "../form/FormSearchableSelect";
import { LoadingMask } from "../LoadingMask";
import { Modal } from "../Modal";
import { Toast } from "../Toast";
import { startTranferConference } from "../../state/twilio/actions";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  callSid: string;
}

export const TransferCallModal = (props: Props) => {
  const [selectedAgentOnModal, setSelectedAgentOnModal] = useState<string>();
  const [showError, setShowError] = useState(false);
  const users = useSelector((state: any) => state.users);
  const twilio = useSelector((state: any) => state.twilio);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (showError) {
      Toast(
        t(twilio.error?.reason ?? "SOMETHING_WENT_WRONG"),
        t(twilio.error?.cause_info)
      );
      setShowError(false);
    }
  }, [showError, twilio.error]);

  const getAgents = useMemo(() => {
    return users.agentsTwilioNumbers
      ?.filter((x: AgentsTwilioNumbers) => x.agent_id !== users.currentUser?.id)
      .map((user: AgentsTwilioNumbers) => {
        return {
          label: `${user.first_name} ${user.last_name} ${user.twilio_phone}`,
          number: user.twilio_phone,
          value: `${user.first_name} ${user.last_name} ${user.twilio_phone}`,
        };
      });
  }, [users]);

  const handleSubmit = () => {
    const number = getAgents.find(
      (x: { value: string }) => selectedAgentOnModal === x.value
    )?.number;
    dispatch(startTranferConference({ call_sid: props.callSid })).then((e) => {
      if (e.type === "calls/startClientConference/rejected") {
        setShowError(true);
      } else {
        const params = { To: number };
        twilio.device.connect(params);
        props.onClose();
      }
    });
  };

  return (
    <>
      {props.isOpen && (
        <Modal
          isOpen={props.isOpen}
          onClose={props.onClose}
          label={t("TRANSFER_CALL")}
          saveButton={{
            label: t("TRANSFER"),
            icon: <ArrowRightOnRectangleIcon />,
            onClick: handleSubmit,
          }}
        >
          {(users.isLoading || twilio.isLoading) && <LoadingMask />}
          <div className="w-full mb-4">
            {t("SELECT_AGENT_TO_TRANSFER_CALL")}
          </div>
          <FormSearchableSelect
            className="md:w-1/2"
            value={selectedAgentOnModal ?? ""}
            name="agent_number"
            label={t("AGENTS")}
            options={getAgents}
            onChange={(val) => setSelectedAgentOnModal(val)}
          />
        </Modal>
      )}
    </>
  );
};
