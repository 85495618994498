
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { localDateFormat, localTimeFormat } from "../../utils/functions";
import { FormLocalDateInput } from "./FormLocalDateInput";

interface Props {
    name: string
    value?: string | number
    label: string
    type?: string
    className?: string
    placeholder?: string
    isDisabled?: boolean
    min?: number
    max?: number
    control: any
    isRequired?: boolean
    handleOnChange?: (event: any) => void
    handleOnBlur?: (event: any) => void
}

export const ReactiveFormLocalDateInput = (props: Props) => {
    const { t } = useTranslation();
    return (
        <Controller
            name={props.name}
            control={props.control}
            rules={{ 
                required: { value: props.isRequired ?? false, message: t('FIELD_REQUIRED') },
                min: props.min ? { value: props.min!, message: t(`Minimum value is ${props.min}`)} : undefined,
                max: props.max ? { value: props.max!, message: t(`Maximum value is ${props.max}`)} : undefined,
             }}
            render={({
                field: { onChange, value },
                fieldState: { error },
            }) => {
                if (props.type === "date") {
                    value = value ? localDateFormat(value) : ""
                }
                return (<FormLocalDateInput
                    label={props.label}
                    name={props.name}
                    value={value}
                    type={props.type}
                    isDisabled={props.isDisabled}
                    handleOnChange={(e) => {
                        onChange(e);
                        if(props.handleOnChange) {
                            props.handleOnChange(e);
                        }
                    }}
                    onBlur={props.handleOnBlur}
                    error={error ? error.message ? error.message : t("FIELD_REQUIRED") : ""}
                    className={props.className}
                    placeholder={props.placeholder} />)
            }} 
        />
    )
}