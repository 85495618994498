import classNames from "classnames";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import logoAsureis from "../../assets/images/Empty_logo.svg";
import { SellsFilter } from "../../enums/SellsFilter";
import { AgentMembers } from "../../models/Dashboard";
import { fetchAllAgentsTotalMembers } from "../../state/dashboard/actions";
import { AppDispatch, RootState } from "../../state/store";
import { getStartEndDates } from "../../utils/functions";
import { LoadingMask } from "../LoadingMask";
import { SearchBar } from "../SearchBar";
import Widget from "./Widget";

interface Props {
  className?: string;
  allAgentMembers: () => void;
}

export const AllMembersSellsWidget = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const currentUser = useSelector(
    (state: RootState) => state.users.currentUser
  );
  const { allAgentsTotalMembers, membersSellFilter } = useSelector(
    (state: RootState) => state.dashboard
  );
  const [reloadingAgentTotalMembers, setReloadingAgentTotalMembers] =
    useState(false);
  const [sellFilter, setSellFilter] = useState(
    membersSellFilter ?? SellsFilter.day
  );
  const [filteredAgentMembers, setFilteredAgentMembers] = useState<
    AgentMembers[]
  >([]);
  const [searchValue, setSearchValue] = useState<string>("");

  useEffect(() => {
    if (currentUser) {
      const { start, end, timeZone } = getStartEndDates(sellFilter);

      const params = {
        startDate: start,
        endDate: end,
        timeZone,
        sellsFliter: sellFilter,
      };
      dispatch(fetchAllAgentsTotalMembers(params));
    }
  }, [currentUser, dispatch, sellFilter]);

  useEffect(() => {
    if (allAgentsTotalMembers.length > 0) {
      setFilteredAgentMembers(
        allAgentsTotalMembers.length > 6
          ? allAgentsTotalMembers.slice(0, 6)
          : allAgentsTotalMembers
      );
    }
  }, [allAgentsTotalMembers]);

  const handleChangeSellsFilter = (filter: SellsFilter) => {
    setSellFilter(filter);
    reloadAgentTotalMembers(filter);
  };

  const reloadAgentTotalMembers = (filter: SellsFilter) => {
    setReloadingAgentTotalMembers(true);
    const { start, end, timeZone } = getStartEndDates(filter);

    const params = {
      startDate: start,
      endDate: end,
      timeZone: timeZone,
      sellsFliter: filter,
    };
    dispatch(fetchAllAgentsTotalMembers(params)).then(() => {
      setReloadingAgentTotalMembers(false);
    });
  };

  const handleSearchAgent = (event: any) => {
    const searchFilter = event.target.value;
    setSearchValue(searchFilter);
    if (searchFilter.length > 0) {
      const fAgentMembers = allAgentsTotalMembers.filter((agent) =>
        agent.agent
          .toLocaleLowerCase()
          .includes(searchFilter.toLocaleLowerCase())
      );
      setFilteredAgentMembers(fAgentMembers);
    } else {
      setFilteredAgentMembers(
        allAgentsTotalMembers.length > 6
          ? allAgentsTotalMembers.slice(0, 6)
          : allAgentsTotalMembers
      );
    }
  };

  return (
    <>
      {reloadingAgentTotalMembers && <LoadingMask />}
      <Widget>
        <div className="w-full h-full p-5">
          <div className="w-full flex flex-row justify-between items-center mb-4">
            <div className="w-full max-w-80 mr-5">
              <SearchBar value={searchValue} onChange={handleSearchAgent} />
            </div>
            <div className="flex flex-row items-center">
              <div
                className={classNames({
                  "text-center hover:bg-neutral-200 hover:cursor-pointer rounded-lg px-3 py-1":
                    true,
                  "bg-neutral-200": sellFilter === SellsFilter.day,
                })}
                style={{ margin: "-1px" }}
                onClick={() => handleChangeSellsFilter(SellsFilter.day)}
              >
                <div className="text-xs text-gray-500">{t("SHOW")}</div>
                <div className="text-blue text-lg font-medium">1D</div>
              </div>
              <div className="bg-neutral-200 h-10 w-px"></div>
              <div
                className={classNames({
                  "text-center hover:bg-neutral-200 hover:cursor-pointer rounded-lg px-3 py-1":
                    true,
                  "bg-neutral-200": sellFilter === SellsFilter.week,
                })}
                style={{ margin: "-1px" }}
                onClick={() => handleChangeSellsFilter(SellsFilter.week)}
              >
                <div className="text-xs text-gray-500">{t("SHOW")}</div>
                <div className="text-blue text-lg font-medium">1W</div>
              </div>
              <div className="bg-neutral-200 h-10 w-px"></div>
              <div
                className={classNames({
                  "text-center hover:bg-neutral-200 hover:cursor-pointer rounded-lg px-3 py-1":
                    true,
                  "bg-neutral-200": sellFilter === SellsFilter.month,
                })}
                style={{ margin: "-1px" }}
                onClick={() => handleChangeSellsFilter(SellsFilter.month)}
              >
                <div className="text-xs text-gray-500">{t("SHOW")}</div>
                <div className="text-blue text-lg font-medium">1M</div>
              </div>
              <div className="bg-neutral-200 h-10 w-px"></div>
              <div
                className={classNames({
                  "text-center hover:bg-neutral-200 hover:cursor-pointer rounded-lg px-3 py-1":
                    true,
                  "bg-neutral-200": sellFilter === SellsFilter.all,
                })}
                style={{ margin: "-1px" }}
                onClick={() => handleChangeSellsFilter(SellsFilter.all)}
              >
                <div className="text-xs text-gray-500">{t("SHOW")}</div>
                <div className="text-blue text-lg font-medium">All</div>
              </div>
            </div>
          </div>
          {allAgentsTotalMembers.length > 0 && (
            <div className="w-full">
              <div className="w-full grow grid grid-cols-5 mb-3 mt-5">
                <div className="col-span-3 text-lg font-semibold ml-3">
                  {t("AGENT")}
                </div>
                <div className="col-span-2 flex flex-row justify-around items-center">
                  <div className="w-full text-lg font-semibold text-center">
                    {t("POLICIES")}
                  </div>
                  <div className="w-full text-lg font-semibold text-center">
                    {t("MEMBERS")}
                  </div>
                </div>
              </div>
              {filteredAgentMembers.map((item, index) => {
                return (
                  <div className="w-full">
                    <div className="w-full grow grid grid-cols-5 mb-3">
                      <div className="col-span-3 text-gray-700 ml-3 text-xl">
                        {item.agent}
                      </div>
                      <div className="col-span-2 flex flex-row justify-around items-center">
                        <div className="w-full text-blue text-2xl text-center border-r">
                          {item.totalPolicies}
                        </div>
                        <div className="w-full text-gold text-2xl font-medium text-center">
                          {item.totalMembers}
                        </div>
                      </div>
                    </div>
                    {index !== filteredAgentMembers.length - 1 && (
                      <hr className="ml-3 mr-2 mb-3"></hr>
                    )}
                  </div>
                );
              })}
              {allAgentsTotalMembers.length - filteredAgentMembers.length >
                0 && (
                <div className="flex justify-end">
                  <label
                    className="underline cursor-pointer text-blue font-semibold mx-6 mb-1 mt-4"
                    onClick={() => props.allAgentMembers()}
                  >
                    {t("SEE_ALL")}
                  </label>
                </div>
              )}
            </div>
          )}
          {allAgentsTotalMembers.length === 0 && (
            <div className="w-full h-full flex flex-col items-center justify-center">
              <img src={logoAsureis} alt="Asureis Logo" className="h-32" />
              <div className="text-gray-500 text-xl font-medium mt-2">
                {t("NO_MEMBERS_SOLD")}
              </div>
            </div>
          )}
        </div>
      </Widget>
    </>
  );
};
