import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import Button from "../../components/Button";
import { LoadingMask } from "../../components/LoadingMask";
import { Toast } from "../../components/Toast";
import { Form } from "../../components/form/Form";
import { ReactiveFormInput } from "../../components/form/ReactiveFormInput";
import { ReactiveFormSelect } from "../../components/form/ReactiveFormSelect";
import { Agency, BillingPeriod } from "../../models/Agency";
import {
  createAgencyBillingPeriod,
  createAgencyTwilioConfig,
  endAgencyBillingPeriod,
  updateAgency,
  updateAgencyTwilioConfig,
} from "../../state/agencies/action";
import { AppDispatch } from "../../state/store";
import { setCurrentUserAgency } from "../../state/users/actions";

export const AgencyDetails = () => {
  const { t } = useTranslation();
  const currentAgency = useSelector(
    (state: any) => state.users.currentUser?.agency
  );
  const dispatch = useDispatch<AppDispatch>();
  const isUserLoading = useSelector((state: any) => state.users.isLoading);
  const isAgencyLoading = useSelector((state: any) => state.agencies.isLoading);
  const error = useSelector((state: any) => state.agencies.error);
  const billingPeriods = useSelector((state: any) =>
    state.agencies.billingPeriods.slice(0, 2)
  );
  const [showError, setShowError] = useState(false);

  const methods = useForm<Agency>({
    defaultValues: currentAgency,
  });

  const billingPeriodForm = useForm<BillingPeriod>();

  const { handleSubmit, control, reset } = methods;

  const currentYear = new Date().getFullYear();

  const lastBillingPeriod = () =>
    billingPeriods.filter((el: any) => !el.is_active)[0];
  const currentBillingPeriod = () =>
    billingPeriods.find((el: any) => el.is_active);

  const months = useMemo(() => {
    return Array.from({ length: 12 }, (_, i) => i).map((i) => {
      return { value: `${i+1}`, label: moment.months()[i] };
    });
  }, []);

  const years = useMemo(() => {
    return [currentYear - 1, currentYear, currentYear + 1].map((i) => {
      return { value: `${i}`, label: `${i}` };
    });
  },[]);

  useEffect(() => {
    if (showError) {
      Toast(t(error?.reason ?? "SOMETHING_WENT_WRONG"), t(error?.cause_info));
      setShowError(false);
    }
  }, [showError, error, t]);

  useEffect(() => {
    reset(currentAgency);
  }, [currentAgency, reset]);

  const isLoading = useMemo(() => {
    return isUserLoading && isAgencyLoading;
  }, [isUserLoading, isAgencyLoading]);

  const onSubmit: SubmitHandler<Agency> = async (data: Agency) => {
    const twilioConfig = {
      label: data.main_twilio_config_number?.label,
      twilio_auth_token: data.main_twilio_config_number?.twilio_auth_token,
      twilio_account_sid: data.main_twilio_config_number?.twilio_account_sid,
      twilio_twiml_app_sid:
        data.main_twilio_config_number?.twilio_twiml_app_sid,
      messaging_service_sid:
        data.main_twilio_config_number?.messaging_service_sid,
      twilio_api_key: data.main_twilio_config_number?.twilio_api_key,
      twilio_api_secret: data.main_twilio_config_number?.twilio_api_secret,
    };
    if (data.main_twilio_config_number_id) {
      dispatch(
        updateAgencyTwilioConfig({
          twilioConfigId: data.main_twilio_config_number_id,
          data: twilioConfig,
        })
      ).then((e) => {
        if (e.type === "agencies/updateTwilioConfig/rejected") {
          setShowError(true);
        }
      });
    } else {
      dispatch(createAgencyTwilioConfig(twilioConfig)).then((e) => {
        if (e.type === "agencies/createTwilioConfig/rejected") {
          setShowError(true);
        }
      });
    }
    dispatch(updateAgency(data)).then((e) => {
      if (e.type === "agencies/update/rejected") {
        dispatch(setCurrentUserAgency(data));
        setShowError(true);
      } else {
        toast(t("SAVED_SUCCESFULLY"));
      }
    });
  };

  const createBillingPeriod: SubmitHandler<BillingPeriod> = async (
    data: BillingPeriod
  ) => {
    if (!data.month || !data.year || !moment(data.end_date).isValid())
      return Toast(t("PLEASE_PROVIDE_REQUIRED_VALUES"));

    dispatch(
      createAgencyBillingPeriod({
        agencyId: currentAgency.id,
        data: { ...data, month: Number(data.month) - 1, year: Number(data.year) },
      })
    ).then((e) => {
      if (e.type === "agencies/createBillingPeriod/rejected") {
        setShowError(true);
      } else {
        Toast(t("SAVED_SUCCESFULLY"));
      }
    });
  };

  const endBillingPeriod = async () => {
    await dispatch(endAgencyBillingPeriod(currentAgency.id)).then((e) => {
      if (e.type === "agencies/endBillingPeriod/rejected") {
        setShowError(true);
      } else {
        Toast(t("SAVED_SUCCESFULLY"));
      }
    });
  };

  return (
    <div className="w-full grow px-5 space-y-4 flex flex-col">
      {isLoading && <LoadingMask />}
      <ToastContainer progressStyle={{ background: "#D4AF37" }} />
      <Form handleOnSubmit={handleSubmit(onSubmit)}>
        <div className="text-black max-h-full p-5 w-screen md:w-full relative overflow-x-auto bg-white rounded-lg shadow transition-all duration-500 ease-in-out overflow-hidden">
          <div className="text-xl font-bold text-black pl-3">{t("AGENCY")}</div>
          <div className="pb-5 pt-5  w-full flex flex-wrap">
            <ReactiveFormInput
              control={control}
              className="md:w-1/2"
              label={t("NAME")}
              name="name"
              isRequired
            />
            <ReactiveFormInput
              control={control}
              className="md:w-1/2"
              label={t("FEDERAL_TAX_NUMBER")}
              name="federal_tax_number"
            />
            <ReactiveFormInput
              control={control}
              label={t("NOTES")}
              name="notes"
            />
          </div>
        </div>
        <div className="text-black max-h-full p-5 w-screen md:w-full relative overflow-x-auto bg-white rounded-lg shadow transition-all duration-500 ease-in-out overflow-hidden mt-4">
          <div className="text-md font-bold text-black pl-3 mt-2">
            {t("BILLING")}
          </div>
          <div className="py-5 w-full flex flex-col gap-y-5">
            <div className="px-3">
              <label className="block tracking-wide text-gray-700 font-bold text-sm mb-2">
                {t("LAST_BILLING_PERIOD")}
              </label>
              {lastBillingPeriod() ? (
                <label>
                  {moment({
                    month: lastBillingPeriod().month,
                    year: lastBillingPeriod().year,
                  }).format("MMMM, yyyy")}
                </label>
              ) : (
                t("UNAVAILABLE")
              )}
            </div>
            <div>
              <label className="block tracking-wide text-gray-700 font-bold text-sm mb-2 px-3 ">
                {t("CURRENT_BILLING_PERIOD")}
              </label>
              {currentBillingPeriod() ? (
                <div className="flex flex-row px-3 divide-x items-center py-3">
                  <div className="mr-3">
                    <label>
                      {moment({
                        month: currentBillingPeriod().month,
                        year: currentBillingPeriod().year,
                      }).format("MMMM, YYYY")}
                    </label>
                    <br></br>
                    <label className="text-sm text-gray-600">
                      {t("ENDS_ON", {
                        end_date: moment(
                          currentBillingPeriod().end_date
                        ).format("MMMM DD, YYYY"),
                      })}
                    </label>
                  </div>
                  <Button
                    onClick={() => endBillingPeriod()}
                    classNames="ml-3"
                    isTerciary={true}
                  >
                    <span className="flex items-center text-red-600">
                      <div className="w-8 p-1 aspect-square mr-2">
                        <XMarkIcon className="aspect-square stroke-red-600" />
                      </div>
                      {t("END_NOW")}
                    </span>
                  </Button>
                </div>
              ) : (
                <div className="flex flex-row divide-x">
                  <div className="w-1/3 flex flex-row">
                    <ReactiveFormSelect
                      className="md:w-1/2"
                      name="month"
                      label={t("MONTH")}
                      options={months}
                      control={billingPeriodForm.control}
                      isRequired
                    />

                    <ReactiveFormSelect
                      className="md:w-1/2"
                      name="year"
                      label={t("YEAR")}
                      value={`${currentYear}`}
                      options={years}
                      control={billingPeriodForm.control}
                      isRequired
                    />
                  </div>

                  <div className="w-2/3 flex flex-row items-center">
                    <ReactiveFormInput
                      className="md:w-1/4"
                      type="date"
                      name="end_date"
                      label={t("END_DATE")}
                      control={billingPeriodForm.control}
                      isRequired
                    />

                    <div className="pt-4">
                      <Button
                        onClick={billingPeriodForm.handleSubmit(
                          createBillingPeriod
                        )}
                      >
                        <span className="flex items-center font-semibold pr-3">
                          <div className="w-8 p-1 aspect-square mr-2">
                            <CheckIcon />
                          </div>
                          {t("START_BILLING_PERIOD")}
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="text-black max-h-full p-5 w-screen md:w-full relative overflow-x-auto bg-white rounded-lg shadow transition-all duration-500 ease-in-out overflow-hidden mt-4">
          <div className="text-md font-bold text-black pl-3 mt-2">
            {t("TWILIO_CONFIG")}
          </div>
          <div className="pb-5 pt-5 w-full flex flex-wrap">
            <ReactiveFormInput
              control={control}
              className="md:w-1/3"
              label={t("LABEL")}
              name={`main_twilio_config_number.label`}
            />
            <ReactiveFormInput
              control={control}
              className="md:w-1/3"
              label={t("ACCOUNT_SID")}
              name={`main_twilio_config_number.twilio_account_sid`}
            />
            <ReactiveFormInput
              control={control}
              className="md:w-1/3"
              label={t("AUTH_TOKEN")}
              name={`main_twilio_config_number.twilio_auth_token`}
            />
            <ReactiveFormInput
              control={control}
              className="md:w-1/3"
              label={t("TWIML_APP_SID")}
              name={`main_twilio_config_number.twilio_twiml_app_sid`}
            />
            <ReactiveFormInput
              control={control}
              className="md:w-1/3"
              label={t("API_KEY")}
              name={`main_twilio_config_number.twilio_api_key`}
            />
            <ReactiveFormInput
              control={control}
              className="md:w-1/3"
              label={t("API_SECRET")}
              name={`main_twilio_config_number.twilio_api_secret`}
            />
            <ReactiveFormInput
              control={control}
              className="md:w-1/3"
              label={t("MESSAGING_SERVICE_SID")}
              name={`main_twilio_config_number.messaging_service_sid`}
            />
          </div>
        </div>
      </Form>
      <div className="pb-5 text-right">
        <Button onClick={handleSubmit(onSubmit)}>
          <span className="flex items-center font-semibold pr-3">
            <div className="w-8 p-1 aspect-square mr-2">
              <CheckIcon />
            </div>
            {t("SAVE")}
          </span>
        </Button>
      </div>
    </div>
  );
};
