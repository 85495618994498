import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosResponse } from "axios";
import { axiosClient } from "../axiosClient";
import { CommissionRuleType } from "../../models/CommissionRule";
import { CommissionUploadRequest } from "../../models/CommissionUploadRequest";
import { Commission, CommissionResponse } from "../../models/Commission";
import { MatchedPolicyWithCommission } from "../../models/Policies";

export const fetchCommissionRuleTypes = createAsyncThunk('commissions/getCommissionRuleTypes', async (data: {apiPath: string, includeInactive: boolean}) => {
    const response: AxiosResponse<CommissionRuleType[]> = await (await axiosClient()).get(`${data.apiPath}?include_inactive=${data.includeInactive}`);
    return response.data
});

export const createCommissionRuleType = createAsyncThunk('commissions/createCommissionRuleType', async (data: {apiPath: string, body: CommissionRuleType}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<CommissionRuleType> = await (await axiosClient()).post(data.apiPath, data.body);
        return response.data
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const updateCommissionRuleType = createAsyncThunk('commissions/updateCommissionRuleType', async (data: {apiPath: string,  ruleTypeId: number, body: CommissionRuleType}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<CommissionRuleType> = await (await axiosClient()).patch(`${data.apiPath}/${data.ruleTypeId}`, data.body);
        return response.data
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const deleteCommissionRuleType = createAsyncThunk('commissions/deleteCommissionRuleType', async (data: {apiPath: string, ruleTypeId: number }, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<CommissionRuleType> = await (await axiosClient()).delete(`${data.apiPath}}/${data.ruleTypeId}`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const generateComissionsReport = createAsyncThunk('commissions/generateComissionsReport', 
    async (data: {agencyId: number, carrierId: number, matches: any, file: File, progressCallback?: ((progress: number) => void)}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<CommissionResponse> = await (await axiosClient())
            .postForm(`/agencies/${data.agencyId}/carriers/${data.carrierId}/commissions`, {matches: data.matches, statement: data.file},
                {
                    onUploadProgress: (progressEvent) => {
                        data.progressCallback && data.progressCallback(progressEvent.progress!);
                    }
               }
            );
        return response.data
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const getComissionsReport = createAsyncThunk('commissions/getComissionsReport', async (data: {agencyId: number, billingPeriodId: number, pageSize: number, page: number, status?: string}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<CommissionResponse> = await (await axiosClient()).get(`/agencies/${data.agencyId}/commissions/${data.billingPeriodId}?pageSize=${data.pageSize}&page=${data.page}${data.status && data.status != 'confirmed' ? `&status=${data.status}` : ''}`);
        return response.data
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const createMatchedPolicy = createAsyncThunk("commissions/createMatchedPolicy", async (params: {agencyId: number, body: MatchedPolicyWithCommission}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<any[]> = await (await axiosClient()).put(`/agencies/${params.agencyId}/commissions`, {...params.body});
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});


export const pastPage = createAsyncThunk("commissions/pastPage", async () => {
    return true;
});
