import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosClient } from "../axiosClient";
import { AxiosResponse } from "axios";
import { EditReminder, Reminder } from "../../models/Reminder";

export const fetchUserReminders = createAsyncThunk('user/getReminders', async (params: {startDate: string, timeZone: string}) => {
    const response: AxiosResponse<Reminder[]> = await (await axiosClient()).get(`/users/reminders`, {params: {startDate: params.startDate, timeZone: params.timeZone}});
    return response.data
});

export const createUserReminder = createAsyncThunk('user/createReminders', async (params:{newReminder: EditReminder}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<Reminder> = await (await axiosClient()).post(`/users/reminders`, {...params.newReminder});
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const updateUserReminder = createAsyncThunk('user/updateReminders', async (params:{reminderId: number, editReminder: EditReminder}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<Reminder> = await (await axiosClient()).patch(`/users/reminders/${params.reminderId}`, {...params.editReminder});
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const deleteUserReminder = createAsyncThunk('user/deleteReminders', async (params:{reminderId: Number}, { rejectWithValue }) => {
    try {
        const response: AxiosResponse<Reminder> = await (await axiosClient()).delete(`/users/reminders/${params.reminderId}`);
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});