import classNames from "classnames";
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import io from "socket.io-client";
import { Device } from "twilio-client";
import { Call } from "./components/Calls/Call";
import { IncomingCall } from "./components/Calls/IncomingCall";
import GuardedRoute from "./components/GuardedRoute";
import { Toast } from "./components/Toast";

import {
  AgencyPath,
  ApplicationPublicFormPath,
  AutomationPath,
  ClientPublicFormPath,
  ClientsPath,
  CommissionsPath,
  ConversationsPath,
  DashboardPath,
  DocumentsPath,
  ForgotPasswordPath,
  LeadsPath,
  LoginPath,
  MetadataPath,
  NewAutomationPath,
  NewClientPath,
  NewLeadPath,
  NewUserPath,
  PhonePath,
  PoliciesPath,
  PolicySellsReportPath,
  ProfilePath,
  SalesPolicyLeadBoardPath,
  TagsPath,
  UpdateAutomationPath,
  UpdateClientPath,
  UpdateLeadPath,
  UpdateUserPath,
  UsersPath,
} from "./constants/Routes";

import { TwilioDeviceState } from "./enums/TwilioDeviceState";
import { IncomingMessage } from "./models/TwilioConversation";
import { AgencyDetails } from "./pages/Agency/AgencyDetails";
import { ApplicationPublicForm } from "./pages/ApplicationPublicForm";
import { Automations } from "./pages/Automations/Automations";
import NewAutomation from "./pages/Automations/NewAutomation";
import UpdateAutomation from "./pages/Automations/UpdateAutomation";
import { ClientPublicForm } from "./pages/ClientPublicForm";
import { Clients } from "./pages/Clients/Clients";
import { NewClient } from "./pages/Clients/NewClient";
import { UpdateClient } from "./pages/Clients/UpdateClient";
import { Commissions } from "./pages/Commissions/Commissions";
import { Conversations } from "./pages/Conversations/Conversations";
import { Phone } from "./pages/Conversations/Phone";
import { Dashboard } from "./pages/Dashboard/DashBoard";
import { Documents } from "./pages/Documents/Documents";
import { Home } from "./pages/Home/Home";
import { CreateLead } from "./pages/Leads/CreateLead";
import { Leads } from "./pages/Leads/Leads";
import { UpdateLead } from "./pages/Leads/UpdateLead";
import { ForgotPassword } from "./pages/Login/ForgotPassword";
import { Login } from "./pages/Login/Login";
import { Metadata } from "./pages/Metadata/Metadata";
import { Policies } from "./pages/Policies/Policies";
import { Profile } from "./pages/Profile/Profile";
import { SalesPolicyLeadBoard } from "./pages/SalesPolicyLeadBoard";
import { Tags } from "./pages/Tags/Tags";
import { CreateUser } from "./pages/Users/CreateUser";
import { UpdateUser } from "./pages/Users/UpdateUser";
import { Users } from "./pages/Users/Users";
import { PolicySellsReport } from "./pages/Reports/PolicySellsReport";
import {
  fetchAgencyBillingPeriods,
  setCurrentAgency,
} from "./state/agencies/action";
import { fetchAutomations } from "./state/automations/action";
import { fetchClients } from "./state/clients/actions";
import { fetchLeads } from "./state/leads/actions";
import { fetchMetadataMenuItems } from "./state/metadata/action";
import { fetchPolicies } from "./state/policies/actions";
import { AppDispatch } from "./state/store";
import { fetchTags } from "./state/tags/action";
import { fetchDocumentTemplates } from "./state/templates/actions";
import {
  fetchCalls,
  fetchConversations,
  fetchPhoneContacts,
  fetchVoiceToken,
  finishTranferConference,
  handleIncomingMessage,
  health,
  setTwilioDevice,
  setTwilioDeviceState,
} from "./state/twilio/actions";
import {
  fetchAgentsTwilioNumbers,
  fetchUser,
  fetchUsers,
  fetchUserTwilioPhones,
  removeUser,
} from "./state/users/actions";
import { isLoggedIn } from "./utils/functions";
import { TransferCallModal } from "./components/Calls/TransferCallModal";
import { AgentsTwilioNumbers } from "./models/User";
import { ConferenceModal } from "./components/Calls/ConferenceModal";

export const App = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const [isRinging, setIsRinging] = useState(false);
  const [incomingCall, setIncomingCall] = useState<any>();
  const [currentCall, setIsCurrentCall] = useState(false);
  const [isCallMute, setIsCallMute] = useState(false);
  const twilio = useSelector((state: any) => state.twilio);
  const users = useSelector((state: any) => state.users);
  const dashboard = useSelector((state: any) => state.dashboard);
  const clients = useSelector((state: any) => state.clients);
  const leads = useSelector((state: any) => state.leads);
  const [isUserSignedIn, setIsUserSignedIn] = useState(isLoggedIn);
  const [errorOnCurrentUser, setErrorOnCurrentUser] = useState(false);
  const [showPreloader, setShowPreloader] = useState(true);
  const [openTransferModal, setOpenTransferModal] = useState(false);
  const [openConferenceModal, setOpenConferenceModal] = useState(false);
  const [clientPhone, setClientPhone] = useState<string>();
  const [phone, setPhone] = useState("");
  const [phoneLabel, setPhoneLabel] = useState("");
  const location = useLocation();

  useEffect(() => {
    if (errorOnCurrentUser) {
      //FirebaseService.logout();
      Toast(
        t(users?.error?.reason ?? "SOMETHING_WENT_WRONG"),
        t(users?.error?.cause_info)
      );
      setErrorOnCurrentUser(false);
    }
  }, [errorOnCurrentUser, users?.error, t]);

  useEffect(() => {
    async function load() {
      if (users?.currentUser) {
        await Promise.all([
          dispatch(fetchUserTwilioPhones()),
          dispatch(fetchAutomations()),
          dispatch(fetchTags(users?.currentUser.agency_id)),
          dispatch(fetchClients({ page: 0, pageSize: 10, filters: {} })),
          dispatch(fetchPolicies({ page: 0, pageSize: 10 })),
          dispatch(fetchLeads()),
          dispatch(fetchPhoneContacts()),
          dispatch(health()),
          dispatch(fetchUsers(users?.currentUser.agency_id)),
          dispatch(fetchDocumentTemplates()),
          dispatch(fetchTags(users?.currentUser.agency_id)),
          dispatch(setCurrentAgency(users?.currentUser.agency)),
          dispatch(fetchMetadataMenuItems()),
          dispatch(fetchAgentsTwilioNumbers()),
          dispatch(fetchAgencyBillingPeriods(users?.currentUser.agency_id)),
        ]);
      }
    }
    load();
  }, [users?.currentUser]);

  const mainTwilioNumber = useMemo(
    () => users?.currentUser?.main_twilio_number?.twilio_phone,
    [users?.currentUser?.main_twilio_number?.twilio_phone]
  );

  useEffect(() => {
    async function loadToken() {
      if (mainTwilioNumber) {
        await dispatch(
          fetchVoiceToken(mainTwilioNumber)
        );
        await dispatch(
          fetchConversations(mainTwilioNumber)
        );
        await dispatch(
          fetchCalls(mainTwilioNumber)
        );
      }
    }
    loadToken();
  }, [mainTwilioNumber]);

  useEffect(() => {
    if (users?.currentUser) {
      const socket = io(process.env.REACT_APP_SOCKET_URL ?? "");
      //const socket = io("ws://localhost:8080" ?? "");
      socket.emit("registry", {
        userId: users?.currentUser.id,
        phone: mainTwilioNumber,
      });
      socket.on("connect", () => {});
      socket.on("registry", (data) => {});
      socket.on("disconnect", () => {});
      socket.on("incommingMessage", (message: IncomingMessage) => {
        dispatch(handleIncomingMessage(message));
      });
      return () => {
        socket.disconnect();
      };
    }
  }, [users?.currentUser, mainTwilioNumber, dispatch]);

  useEffect(() => {
    if (twilio?.voiceToken) {
      const device2 = new Device();
      device2.setup(twilio?.voiceToken ?? "", {
        // El evento 'tokenWillExpire' se emitirá 30 segundos antes de que el token de acceso caduque
        tokenRefreshMs: 30000,
      });

      device2.on("incoming", (connection) => {
        setIsRinging(true);
        setPhone(connection.parameters.From);
        setIncomingCall(connection);
      });

      device2.on("ready", (device) => {
        dispatch(setTwilioDeviceState(TwilioDeviceState.READY));
      });

      device2.on("offline", (device) => {
        dispatch(setTwilioDeviceState(TwilioDeviceState.OFFLINE));
      });

      device2.on("error", (device: any) => {
        dispatch(setTwilioDeviceState(TwilioDeviceState.ERROR));
      });

      device2.on("connect", (connection) => {
        setPhoneLabel(connection.message?.label);
        connection.message.To
          ? setPhone(connection.message.To)
          : setPhone(
              connection.parameters.To?.startsWith("client")
                ? connection.parameters.From
                : connection.parameters.To
            );
        setIncomingCall(connection);
        setIsCurrentCall(true);
        dispatch(setTwilioDeviceState(TwilioDeviceState.CONNECT));
      });

      device2.on("disconnect", (connection) => {
        setIsRinging(false);
        setPhone("");
        setIncomingCall(null);
        setIsCurrentCall(false);
        dispatch(setTwilioDeviceState(TwilioDeviceState.READY));
      });

      device2.on("tokenWillExpire", () => {
        dispatch(
          fetchVoiceToken(mainTwilioNumber)
        );
      });
      dispatch(setTwilioDevice(device2));
    }
  }, [twilio?.voiceToken, dispatch, users.currentUser, mainTwilioNumber]);

  useEffect(() => {
    return () => {
      if (twilio.device) {
        twilio.device.destroy();
      }
    };
  }, [twilio.device]);

  const phoneNames = useMemo(() => {
    const newMap = new Map();
    twilio?.phoneContacts?.leads?.forEach((element: any) => {
      newMap.set(element.phone, element);
    });
    twilio?.phoneContacts?.clients?.forEach((element: any) => {
      newMap.set(element.phone, element);
    });
    users?.agentsTwilioNumbers?.forEach((element: AgentsTwilioNumbers) => {
      newMap.set(element.twilio_phone, {
        name: `${element.first_name} ${element.last_name}`,
      });
    });
    return newMap;
  }, [twilio.phoneContacts, users?.agentsTwilioNumbers]);

  const callDetails = useMemo(() => {
    if (phoneLabel) {
      return {
        phone: phone,
        name:
          phone === mainTwilioNumber
            ? "Conference"
            : phoneLabel,
      };
    }
    const client = phoneNames.get(phone);
    return {
      phone: phone,
      name: client ? `${client.name}` : "",
    };
  }, [phoneLabel, phoneNames, phone, mainTwilioNumber]);

  const acceptCall = useCallback(() => {
    setIsCurrentCall(true);
    setIsRinging(false);
    incomingCall?.accept();
  }, [incomingCall]);

  const endCall = useCallback(() => {
    setIsCurrentCall(false);
    setIsRinging(false);
    incomingCall?.reject();
    twilio.device?.disconnectAll();
  }, [twilio.device, incomingCall]);

  const muteCall = useCallback(() => {
    setIsCallMute(true);
    incomingCall?.mute(true);
  }, [incomingCall]);

  const unmuteCall = useCallback(() => {
    setIsCallMute(false);
    incomingCall?.mute(false);
  }, [incomingCall]);

  const makePhoneCall = useCallback(
    (phone: string) => {
      if (twilio.device) {
        const params = { To: phone };
        twilio.device.connect(params);
      }
    },
    [twilio.device]
  );

  useEffect(() => {
    if (location.pathname.startsWith("/register/")) {
      setShowPreloader(false);
    } else if (
      location.pathname !== ForgotPasswordPath &&
      location.pathname !== LoginPath &&
      clients &&
      twilio &&
      users &&
      leads &&
      dashboard &&
      users.currentUser?.id &&
      !clients.isLoading &&
      !twilio.isLoading &&
      !users.isLoading &&
      !leads.isLoading &&
      !dashboard.isLoading
    ) {
      setShowPreloader(false);
    }
  }, [clients, twilio, users, leads, dashboard, location]);

  useLayoutEffect(() => {
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGIN_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    };

    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
    const auth = getAuth(app);
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        dispatch(fetchUser()).then((e) => {
          if (e.type === "users/currentUser/rejected") {
            setErrorOnCurrentUser(true);
          }
        });
        setIsUserSignedIn(true);
      } else {
        //Remove user from local storage
        dispatch(removeUser());
        setIsUserSignedIn(false);
      }
    });
  }, []);

  const toggleTransferModal = () => {
    setOpenTransferModal(!openTransferModal);
    setClientPhone(phone);
  };

  const transferFinalStep = (accepted: boolean) => {
    dispatch(
      finishTranferConference({
        call_sid: incomingCall.parameters.CallSid,
        client_conference_id: twilio.transferConferenceId,
        transfer_was_accepted: accepted,
      })
    );
    if (!accepted && clientPhone) {
      setPhone(clientPhone);
    }
  };

  const toggleConferenceModal = () => {
    setOpenConferenceModal(!openConferenceModal);
    //setClientPhone(phone);
  };

  return (
    <>
      <div
        className={classNames({
          "bg-blue flex items-center justify-center h-screen fixed top-0 left-0 right-0 bottom-0 z-[100]":
            true,
          block: showPreloader,
          preloader: !showPreloader,
          hidden:
            location.pathname === LoginPath ||
            location.pathname === ForgotPasswordPath,
        })}
      >
        <div className="flex justify-center items-center h-screen">
          <svg
            className="loading-triangle -mx-1.5"
            width="48.12"
            height="46"
            xmlns="http://www.w3.org/2000/svg"
          >
            <polygon points="0,10.6 24.06,46 48.12,10.6" fill="white" />
          </svg>
          <svg
            className="loading-triangle -mx-1.5"
            width="48.12"
            height="46"
            xmlns="http://www.w3.org/2000/svg"
          >
            <polygon points="48.12,46 24.06,10.6 0,46" fill="white" />
          </svg>
          <svg
            className="loading-triangle -mx-1.5"
            width="48.12"
            height="46"
            xmlns="http://www.w3.org/2000/svg"
          >
            <polygon points="0,10.6 24.06,46 48.12,10.6" fill="white" />
          </svg>
        </div>
      </div>
      <div id="content">
        {isRinging && (
          <IncomingCall
            accept={acceptCall}
            decline={endCall}
            callDetails={callDetails}
          />
        )}
        {currentCall && (
          <Call
            transferFinalStep={transferFinalStep}
            transfer={toggleTransferModal}
            decline={endCall}
            callDetails={callDetails}
            unmuteCall={unmuteCall}
            muteCall={muteCall}
            isMute={isCallMute}
            conference={toggleConferenceModal}
          />
        )}
        {isRinging && (
          <div className="opacity-50 pointer-events-none fixed w-full h-full z-[200] bg-asureisGray"></div>
        )}
        <TransferCallModal
          isOpen={openTransferModal}
          onClose={toggleTransferModal}
          callSid={incomingCall?.parameters?.CallSid}
        />
        <ConferenceModal
          isOpen={openConferenceModal}
          onClose={toggleConferenceModal}
          callSid={incomingCall?.parameters?.CallSid}
        />
        <Routes>
          <Route path={ClientPublicFormPath} element={<ClientPublicForm />} />
          <Route
            path={ApplicationPublicFormPath}
            element={<ApplicationPublicForm />}
          />

          {/* Non-Authenticated Routes: accessible only if user in not authenticated */}
          <Route
            element={
              <GuardedRoute
                isRouteAccessible={!isUserSignedIn}
                redirectRoute={DashboardPath}
              />
            }
          >
            <Route path={LoginPath} element={<Login />} />
            <Route path="*" element={<Navigate to={LoginPath} />} />
            <Route path={ForgotPasswordPath} element={<ForgotPassword />} />
          </Route>

          {/* Authenticated Routes */}
          <Route
            element={
              <GuardedRoute
                isRouteAccessible={isUserSignedIn}
                redirectRoute={LoginPath}
              />
            }
          >
            <Route path="/" element={<Home />}>
              <Route index element={<Navigate to={DashboardPath} />} />
              <Route path="*" element={<Navigate to={DashboardPath} />} />
              <Route
                path={ClientsPath}
                element={<Clients makePhoneCall={makePhoneCall} />}
              />
              <Route path={NewClientPath} element={<NewClient />} />
              <Route path={PoliciesPath} element={<Policies />} />
              <Route
                path={ConversationsPath}
                element={
                  <Conversations
                    phoneNames={phoneNames}
                    makePhoneCall={makePhoneCall}
                  />
                }
              />
              <Route
                path={PhonePath}
                element={
                  <Phone
                    phone={phone}
                    phoneNames={phoneNames}
                    isCurrentCall={currentCall}
                    endCall={endCall}
                    unmuteCall={unmuteCall}
                    muteCall={muteCall}
                    isMute={isCallMute}
                    makePhoneCall={makePhoneCall}
                  />
                }
              />
              <Route
                path={LeadsPath}
                element={<Leads makePhoneCall={makePhoneCall} />}
              />
              <Route path={NewLeadPath} element={<CreateLead />} />
              <Route path={DocumentsPath} element={<Documents />} />
              <Route path={UsersPath} element={<Users />} />
              <Route path={NewUserPath} element={<CreateUser />} />
              <Route path={ProfilePath} element={<Profile />} />
              <Route path={AgencyPath} element={<AgencyDetails />} />
              <Route path={UpdateClientPath} element={<UpdateClient />} />
              <Route path={UpdateLeadPath} element={<UpdateLead />} />
              <Route path={UpdateUserPath} element={<UpdateUser />} />
              <Route path={AutomationPath} element={<Automations />} />
              <Route path={NewAutomationPath} element={<NewAutomation />} />
              <Route
                path={UpdateAutomationPath}
                element={<UpdateAutomation />}
              />
              <Route path={TagsPath} element={<Tags />} />
              <Route path={DashboardPath} element={<Dashboard />} />
              <Route path={MetadataPath} element={<Metadata />} />
              <Route path={CommissionsPath} element={<Commissions />} />
            </Route>
            <Route
              path={SalesPolicyLeadBoardPath}
              element={<SalesPolicyLeadBoard />}
            />
            <Route
              path={PolicySellsReportPath}
              element={<PolicySellsReport />}
            />
          </Route>
        </Routes>
      </div>
    </>
  );
};
