import {
    ClipboardDocumentListIcon,
    PencilIcon
} from "@heroicons/react/24/solid";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Button from "../../components/Button";
import FilteredTable from "../../components/FilteredTable";
import { LoadingMask } from "../../components/LoadingMask";
import { PolicySellsReportPath } from "../../constants/Routes";
import { PolicyStatusLabel } from "../../enums/PolicyState";
import { RoleEnum } from "../../enums/Role";
import { Policy } from "../../models/Policies";
import {
    fetchPolicies,
    pastPage
} from "../../state/policies/actions";
import { AppDispatch } from "../../state/store";
import {
    longLocalDateFormat,
    shortDateFormat
} from "../../utils/functions";
import { PolicyFilterModal } from "./PolicyFilterModal";
import { PolicyUpdateModal } from "./PolicyUpdateModal";

interface Props {}

export const Policies = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const pagePolicies = useSelector((state: any) => state.policies.pagePolicies);
  const paginatedOptions = useSelector(
    (state: any) => state.policies.paginatedOptions
  );
  const isLoading = useSelector(
    (state: any) => state.policies.isLoadingPolicies
  );
  const currentUser = useSelector((state: any) => state.users.currentUser);
  const [isFilterModalOpen, setFilterModalOpen] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();

  const [openPolicyUpdateModal, setOpenPolicyUpdateModal] = useState<{
    policy?: Policy;
    show: boolean;
  }>({ show: false, policy: undefined });

  const columns = useMemo(() => {
    const showColumns = [
      {
        Header: t("POLICY_NUMBER"),
        accessor: "number",
      },
      {
        Header: t("CARRIER"),
        Cell: (row: any) => t(`${row.row.original.carrier}`),
      },
      {
        Header: t("CLIENT"),
        accessor: "client",
      },
      {
        Header: t("EFFECTIVE_START_DATE"),
        Cell: (row: any) =>
          shortDateFormat(`${row.row.original.effective_start_date}`),
      },
      {
        Header: t("STATUS"),
        Cell: (row: any) =>
          row.row.original.policy_state != null
            ? PolicyStatusLabel(row.row.original.policy_state)
            : "-",
      },
      {
        Header: t("FREE_PLAN"),
        Cell: (row: any) =>
          `${Number(row.row.original.free_plan) === 1 ? t("YES") : t("NO")}`,
      },
    ];

    const actions = {
      Header: " ",
      Cell: (row: any) => {
        return (
          <div className="flex flex-row justify-center divide-x">
            <Button
              isTerciary
              tooltip={t("UPDATE_POLICY")}
              iconOnly
              onClick={() => showPolicyUpdateModal(row.row.original)}
              classNames="mr-2"
            >
              <PencilIcon className="h-5 aspect-square text-blue" />
            </Button>
          </div>
        );
      },
    };

    if (currentUser?.role_id !== RoleEnum.AGENT) {
      return [
        ...showColumns,
        {
          Header: t("AGENT"),
          Cell: (row: any) => `${row.row.original.agent ?? ""}`,
        },
        {
          Header: t("LAST_UPDATE"),
          Cell: (row: any) =>
            row.row.original.last_update != null
              ? longLocalDateFormat(`${row.row.original.last_update}`)
              : "-",
        },
        actions,
      ];
    }
    return [
      ...showColumns,
      {
        Header: t("LAST_UPDATE"),
        Cell: (row: any) =>
          row.row.original.last_update != null
            ? longLocalDateFormat(`${row.row.original.last_update}`)
            : "-",
      },
      actions,
    ];
  }, [t, currentUser]);

  const getPaginationOptions = useMemo(() => {
    return {
      page: paginatedOptions.page,
      pageSize: paginatedOptions.pageSize,
      totalPages: paginatedOptions.totalPages,
      totalCount: paginatedOptions.totalCount,
      setPage: (value: number) => {
        value < paginatedOptions.page
          ? dispatch(pastPage())
          : dispatch(
              fetchPolicies({
                page: value,
                pageSize: paginatedOptions.pageSize,
              })
            );
      },
      setPageSize: (value: number) =>
        dispatch(
          fetchPolicies({ page: paginatedOptions.page, pageSize: value })
        ),
    };
  }, [dispatch, paginatedOptions]);

  const showPolicyUpdateModal = (policy: Policy) => {
    setOpenPolicyUpdateModal({
      show: true,
      policy: policy,
    });
  };

  return (
    <>
      {isLoading && <LoadingMask />}
      <PolicyUpdateModal
        info={openPolicyUpdateModal}
        onClose={() =>
          setOpenPolicyUpdateModal({ show: false, policy: undefined })
        }
      />
      <ToastContainer progressStyle={{ background: "#D4AF37" }} />
      <FilteredTable
        columns={columns}
        data={pagePolicies}
        isPaginated
        paginationOptions={getPaginationOptions}
        hasSearchBar={false}
        secondaryActionButton={
          currentUser?.role_id == RoleEnum.ADMIN ||
          currentUser?.role_id == RoleEnum.SUPER_ADMIN ? (
            <Button onClick={() => navigate(PolicySellsReportPath)}>
              <span className="flex items-center font-semibold pr-3">
                <div className="w-8 p-1 aspect-square mr-2">
                  <ClipboardDocumentListIcon />
                </div>
                {t("POLICY_SALES_REPORT")}
              </span>
            </Button>
          ) : (
            <div></div>
          )
        }
        /*buttonAction={<Button onClick={() => setFilterModalOpen(true)} iconOnly classNames="w-9 p-1 aspect-square mr-4 ">
                <FunnelIcon className=""></FunnelIcon></Button>}
                */
      />
      <PolicyFilterModal
        isFilterModalOpen={isFilterModalOpen}
        setFilterModalOpen={() => setFilterModalOpen(false)}
      />
    </>
  );
};
