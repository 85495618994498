import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchAgentTotalMembers, fetchAllAgentsTotalMembers, fetchMembersTotalByCarrier, fetchNewClientFromLeads, fetchNewLeads, fetchUnContactedAnsweredLeads } from "./actions";
import { AgentMembers } from "../../models/Dashboard";

interface DashboardState {
    isNewLeadLoading: boolean
    isNewClientLoading: boolean
    isUnContactedAnsweredLeadLoading: boolean
    isNewLeadLoaded: boolean
    isNewClientLoaded: boolean
    isUnContactedAnsweredLeadLoaded: boolean
    error: string | null
    newLeads: any[]
    newClientLeads: any[]
    unContactedLeads: any[]
    unAnsweredLeads: any[]
    isAgentTotalMembersLoading: boolean
    isAgentTotalMembersLoaded: boolean
    agentTotalMembers: AgentMembers
    membersSellFilter: any
    isAllAgentsTotalMembersLoading: boolean
    isAllAgentsTotalMembersLoaded: boolean
    allAgentsTotalMembers: AgentMembers[]
    isCarrierTotalMembersLoading: boolean
    isCarrierTotalMembersLoaded: boolean
    carrierTotalMembers: any[]
    carrierMembersSellFilter: any
}

const initialState: DashboardState = {
    isNewLeadLoading: false,
    isNewClientLoading: false,
    isUnContactedAnsweredLeadLoading: false,
    isNewLeadLoaded: false,
    isNewClientLoaded: false,
    isUnContactedAnsweredLeadLoaded: false,
    error: null,
    newLeads: [],
    newClientLeads: [],
    unContactedLeads: [],
    unAnsweredLeads: [],
    isAgentTotalMembersLoading: false,
    isAgentTotalMembersLoaded: false,
    agentTotalMembers: {
        agentId: 0,
        agent: "",
        totalPolicies: 0,
        totalMembers: 0
    },
    membersSellFilter: null,
    isAllAgentsTotalMembersLoading: false,
    isAllAgentsTotalMembersLoaded: false,
    allAgentsTotalMembers: [],
    isCarrierTotalMembersLoading: false,
    isCarrierTotalMembersLoaded: false,
    carrierTotalMembers: [],
    carrierMembersSellFilter: null
}

// slice
const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchNewLeads.pending, (state) => {
                state.isNewLeadLoading = true;
            })
            .addCase(fetchNewLeads.fulfilled, (state, action: any) => {
                state.isNewLeadLoading = false;
                state.newLeads = action.payload;
                state.isNewLeadLoaded = true;
            })
            .addCase(fetchNewLeads.rejected, (state, action: any) => {
                state.isNewLeadLoading = false;
                state.error = action.payload;
            });
        builder
            .addCase(fetchNewClientFromLeads.pending, (state) => {
                state.isNewClientLoading = true;
            })
            .addCase(fetchNewClientFromLeads.fulfilled, (state, action: any) => {
                state.isNewClientLoading = false;
                state.newClientLeads = action.payload;
                state.isNewClientLoaded = true;
            })
            .addCase(fetchNewClientFromLeads.rejected, (state, action: any) => {
                state.isNewClientLoading = false;
                state.error = action.payload;
            });
        builder
            .addCase(fetchUnContactedAnsweredLeads.pending, (state) => {
                state.isUnContactedAnsweredLeadLoading = true;
            })
            .addCase(fetchUnContactedAnsweredLeads.fulfilled, (state, action: any) => {
                state.isUnContactedAnsweredLeadLoading = false;
                state.unContactedLeads = action.payload.un_contact_results;
                state.unAnsweredLeads = action.payload.un_answered_results;
                state.isUnContactedAnsweredLeadLoaded = true;
            })
            .addCase(fetchUnContactedAnsweredLeads.rejected, (state, action: any) => {
                state.isUnContactedAnsweredLeadLoading = false;
                state.error = action.payload;
            });
        builder
            .addCase(fetchAgentTotalMembers.pending, (state) => {
                state.isAgentTotalMembersLoading = true;
            })
            .addCase(fetchAgentTotalMembers.fulfilled, (state, action: PayloadAction<any>) => {
                state.isAgentTotalMembersLoading = false;
                state.agentTotalMembers = action.payload.agentMembers;
                state.membersSellFilter = action.payload.sellFilter;
                state.isAgentTotalMembersLoaded = true;
            })
            .addCase(fetchAgentTotalMembers.rejected, (state, action: any) => {
                state.isAgentTotalMembersLoading = false;
                state.error = action.payload;
            });
        builder
            .addCase(fetchAllAgentsTotalMembers.pending, (state) => {
                state.isAllAgentsTotalMembersLoading = true;
            })
            .addCase(fetchAllAgentsTotalMembers.fulfilled, (state, action: PayloadAction<any>) => {
                state.isAllAgentsTotalMembersLoading = false;
                state.allAgentsTotalMembers = action.payload.allAgentsMembers;
                state.membersSellFilter = action.payload.sellFilter;
                state.isAllAgentsTotalMembersLoaded = true;
            })
            .addCase(fetchAllAgentsTotalMembers.rejected, (state, action: any) => {
                state.isAllAgentsTotalMembersLoading = false;
                state.error = action.payload;
            });
        builder
            .addCase(fetchMembersTotalByCarrier.pending, (state) => {
                state.isCarrierTotalMembersLoading = true;
            })
            .addCase(fetchMembersTotalByCarrier.fulfilled, (state, action: PayloadAction<any>) => {
                state.isCarrierTotalMembersLoading = false;
                state.carrierMembersSellFilter = action.payload.sellFilter;
                state.carrierTotalMembers = action.payload.carrierMembers;
                state.isCarrierTotalMembersLoaded = true;
            })
            .addCase(fetchMembersTotalByCarrier.rejected, (state, action: any) => {
                state.isCarrierTotalMembersLoading = false;
                //state.error = action.payload;
            });
    },
});

export default dashboardSlice.reducer;