import { useTranslation } from "react-i18next";
import { EditReminder, EditReminderModal, newReminderInitialValues } from "../../models/Reminder";
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../state/store";
import { Fragment, useEffect, useMemo, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { ReactiveFormInputArea } from "../form/ReactiveFormInputArea";
import { ReactiveFormLocalDateInput } from "../form/ReactiveFormLocalDateInput";
import { fullDateFormat } from "../../utils/functions";
import moment from "moment";
import { ReactiveFormRadioButtonSelect } from "../form/ReactiveFormRadioButtonSelect";
import { ReactiveFormSMSelect } from "../form/ReactiveFormSMSelect";
import { ReminderModal } from "../ReminderModal";
import { createUserReminder, deleteUserReminder, updateUserReminder } from "../../state/reminders/action";
import { MessageTypeEnum } from "../../enums/MessageType";
import { SuccessErrorModal } from "../SuccessErrorModal";
import { LoadingMask } from "../LoadingMask";
import { Toast } from "../Toast";
import { TrashIcon } from "@heroicons/react/24/outline";
import { ConfirmationModal } from "../ConfirmationModal";

interface Props {
    info: {
      show: boolean;
      update: boolean;
      reminder?: EditReminderModal;
      index?: number;
    };
    onClose: () => void;
}

export const UserReminderModal: React.FC<Props> = ({
    info,
    onClose,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const currentUser = useSelector((state: RootState) => state.users.currentUser);
    const users = useSelector((state: RootState) => state.users.users);
    const error = useSelector((state: any) => state.reminders.error);
    const [isLoading, setIsLoading] = useState(false);
    const [showReminderError, setShowReminderError] = useState(false);
    const [showAnotherAgents, setShowAnotherAgents] = useState(false);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
    const [showSuccessErrorModal, setShowSuccessErrorModal] = useState<{
        show: boolean;
        messageType: number;
        message: string;
    }>({ show: false, messageType: 2, message: "" });

    useEffect(() => {
        if (showReminderError) {
            Toast(
                t(error?.reason ?? "SOMETHING_WENT_WRONG"),
                t(error?.cause_info)
            );
        }
    }, [showReminderError, error, t]);

    const methods = useForm<EditReminderModal>({
        defaultValues: newReminderInitialValues,
    });
    const { handleSubmit, control, setValue, getValues, reset } = methods;

    useEffect(() => {
        info.reminder ? reset(info.reminder) : reset(newReminderInitialValues);
    }, [info.reminder, reset]);

    const onSubmitInvalid: SubmitErrorHandler<any> = (data) => {
        toast(t("PLEASE_ENTER_ALL_POLICY_REQUIRED_FIELDS"));
      }
    
    const onSubmit: SubmitHandler<any> = async (data: any) => {
        if(checkInvalidDate(data)) {
            handleShowSuccessErrorModal(
                MessageTypeEnum.FAILED,
                t("REMINDER_INVALID_DATETIME")
            );
        } else {
            createEditReminder(data);
        }

    };

    const createEditReminder = (data: any) => {
        setIsLoading(true);

        const reminderDate = moment(data.date);
        const timeSplit = data.time.split(":");
        reminderDate.hours(timeSplit[0]).minutes(timeSplit[timeSplit.length - 1]).seconds(0);

        const nEReminder: EditReminder = {};
        nEReminder.message = data.message;
        nEReminder.date = fullDateFormat(reminderDate.toISOString());

        if(data.is_to_myself === "0") {
            if(data.agent != null) {
                nEReminder.agent_id = data.agent;
            } else {
                handleShowSuccessErrorModal(
                    MessageTypeEnum.FAILED,
                    t("PLEASE_SELECT_AN_AGENT")
                );
            }
        }

        if(info.update) {
            nEReminder.id = info.reminder?.id;
            dispatch(updateUserReminder({reminderId: info.reminder!.id!, editReminder: nEReminder})).then((e) => {
                if (e.type === "user/updateReminders/rejected") {
                    setIsLoading(false);
                    setShowReminderError(true);
                } else {
                    setIsLoading(false);
                    toast(t("SUCCESSFULLY_UPDATE_USER_REMINDER"));
                    closeModal();
                }
            });
        } else {
            dispatch(createUserReminder({newReminder: nEReminder})).then((e) => {
                if (e.type === "user/createReminders/rejected") {
                    setIsLoading(false);
                    setShowReminderError(true);
                } else {
                    setIsLoading(false);
                    toast(t("SUCCESSFULLY_SAVED_USER_REMINDER"));
                    closeModal();
                }
            });
        }
    }

    const checkInvalidDate = (data: any) => {
        const reminderDate = moment(data.date);
        const timeSplit = data.time.split(":");
        reminderDate.hours(timeSplit[0]).minutes(timeSplit[timeSplit.length - 1]).seconds(0);
        const todayDate = moment();
    
        if (reminderDate.isValid()) {
            if (reminderDate.isBefore(todayDate)) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    const onDeleteClient = () => {
        setIsConfirmationModalOpen(false);
        setIsLoading(true);

        dispatch(deleteUserReminder({reminderId: info.reminder!.id!})).then((e) => {
            if (e.type === "user/deleteReminders/rejected") {
                setIsLoading(false);
                setShowReminderError(true);
            } else {
                setIsLoading(false);
                toast(t("SUCCESSFULLY_DELETE_USER_REMINDER"));
                closeModal();
            }
        });
    }

    const closeModal = () => {
        reset();
        setShowAnotherAgents(false);
        onClose();
    };

    const agents = useMemo(() => {
        let list = users.filter((x: any) => x.id !== currentUser?.id);
        return list.map((x: any) => {
          return {
            label: `${x.first_name} ${x.last_name}`,
            value: x.id,
          };
        });
    }, [currentUser?.id, users]);

    const handleOnChangeToWhoSelector = (e: any) => {
        if(e.target.value === 0) {
            setShowAnotherAgents(true);
        } else {
            setShowAnotherAgents(false);
        }
    }

    const handleShowSuccessErrorModal = (
        messageType: number,
        message: string
      ) => {
        setShowSuccessErrorModal({
          show: true,
          messageType: messageType,
          message: message,
        });
    };

    const handleCloseSuccessErrorModal = () => {
        setShowSuccessErrorModal({
            show: false,
            messageType: MessageTypeEnum.FAILED,
            message: "",
        });
        setShowReminderError(false);
    };

    const openConfirmationModal = () => {
        setIsConfirmationModalOpen(true);
    };

    const showDeleteConfirmationModal = () => {
        return <ConfirmationModal isDeleteConfirm={true} isOpen={isConfirmationModalOpen} onClose={() => setIsConfirmationModalOpen(false)} label={t("DELETE_CLIENT")} saveButton={{ label: t("DELETE"), icon: <TrashIcon />, onClick: onDeleteClient }}>
            <div className='w-full flex flex-wrap items-center justify-center'>
                <label className="text-lg font-semibold py-4">{t('DELETE_CLIENT_MESSAGE')}</label>
            </div>
        </ConfirmationModal>
    };

    return(
        <div>
            <ReminderModal
                isOpen={info.show}
                onClose={closeModal}
                label={info.update ? t('UPDATE_REMINDER') : t('NEW_REMINDER')}
                onCancel={!info.update ? closeModal : undefined}
                onSave={handleSubmit(onSubmit, onSubmitInvalid)}
                onDelete={info.update ?  openConfirmationModal : undefined}
            >
                <div className="w-full flex flex-wrap">
                    <Fragment>
                        { !info.update &&
                            <ReactiveFormRadioButtonSelect
                                className="md:w-1/2"
                                control={control}
                                name={`is_to_myself`}
                                label={t("WHO_IS_REMINDER_FOR")}
                                handleOnChange={handleOnChangeToWhoSelector}
                                options={[
                                    { label: t("MYSELF"), value: "1" },
                                    { label: t("ANOTHER_AGENT"), value: "0" },
                                ]}
                            />
                        }
                        { !info.update && showAnotherAgents &&
                            <ReactiveFormSMSelect
                                isMulti={false}
                                className={"md:w-1/2"}
                                selectClassName={"basic-single mb-3"}
                                name="agent"
                                label={t("AGENT")}
                                control={control}
                                options={agents}
                                menuPosition={"fixed"}
                                placeholder={"Select agent"}
                            />
                        }
                        <ReactiveFormInputArea
                            control={control}
                            className="md:w-1/1"
                            label={t("MESSAGE")}
                            name={`message`}
                            isRequired
                        />
                        <ReactiveFormLocalDateInput
                            control={control}
                            type="date"
                            className="md:w-1/2"
                            label={t("DATE")}
                            name={`date`}
                            isRequired
                        />
                        <ReactiveFormLocalDateInput
                            control={control}
                            type="time"
                            className="md:w-1/2"
                            label={t("TIME")}
                            name={`time`}
                            isRequired
                        />
                    </Fragment>
                </div>
            </ReminderModal>
            <SuccessErrorModal
                info={showSuccessErrorModal}
                onClose={handleCloseSuccessErrorModal}
            />
            {showDeleteConfirmationModal()}
            <ToastContainer progressStyle={{ background: "#D4AF37" }} />
            {isLoading && <LoadingMask/>}
        </div>
    )
}