import {
  CheckIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import ReactFlow, {
  Connection,
  Edge,
  addEdge,
  useEdgesState,
  useNodesState,
} from "reactflow";
import "reactflow/dist/style.css";
import Button from "../../components/Button";
import { LoadingMask } from "../../components/LoadingMask";
import { Form } from "../../components/form/Form";
import { FormCheckbox } from "../../components/form/FormCheckbox";
import { FormInput } from "../../components/form/FormInput";
import { FormSelect } from "../../components/form/FormSelect";
import { AutomationPath } from "../../constants/Routes";
import { EMAIL_REGEX } from "../../constants/constants";
import {
  AutomationTriggerTypeEnum,
  AutomationTriggerTypeLabel,
  getAutomationTriggerTypes,
} from "../../enums/AutomationTriggerType";
import { LeadStatusEnum, getLeadStatuses } from "../../enums/LeadStatus";
import {
  AnswerTextType,
  StepAnswerTypeEnum,
  StepAnswerTypeLabel,
  getAnswerTextTypes,
  getStepAnswerTypes,
} from "../../enums/StepAnswerType";
import { TimeUnitEnum, getTimeUnits } from "../../enums/TimeUnits";
import {
  StepStruct,
  StepTrigger,
  UpdateAnswerStruc,
  UpdateStepStruct
} from "../../models/Automation";
import {
  fetchAutomationById,
  updateAutomation,
} from "../../state/automations/action";
import { AppDispatch } from "../../state/store";
import { Toast } from "../../components/Toast";

const initialNodes = [
  {
    id: "1",
    position: { x: 100, y: 0 },
    data: { label: "", info: {} as StepStruct },
  },
];
const initialEdges = [
  { id: "e1-2", source: "1", target: "2", label: "Yes" },
  { id: "e1-3", source: "1", target: "3", label: "No" },
];

interface FormData {
  name: string;
  code: string;
}

const initialState: FormData = {
  name: "",
  code: "",
};

const rootX = 0; // Initial x position for the root node
const rootY = 0; // Initial y position for the root node
const horizontalSpacing = 200; // Horizontal spacing between nodes
const verticalSpacing = 100; // Vertical spacing between nodes

export default function UpdateAutomation() {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [formData, setFormData] = useState<FormData>(initialState);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const onConnect = useCallback(
    (params: Edge | Connection) => setEdges((eds) => addEdge(params, eds)),
    [setEdges]
  );
  const location = useLocation();
  const currentUser = useSelector((state: any) => state.users.currentUser);
  const automation = location.state.automation;
  const currentAutomation = useSelector(
    (state: any) => state.automations.currentAutomation
  );
  const isLoading = useSelector((state: any) => state.automations.isLoading);
  const error = useSelector((state: any) => state.automations.error);
  const [stateAutomation, setAutomation] = useState(currentAutomation);
  const [selectedNode, setSelectedNode] = useState<any>(null);
  const [selectedAnswer, setSelectedAnswer] = useState<any>(null);
  const [collapsed, setCollapsed] = useState(true);
  const [showError, setShowError] = useState(false)
  const Icon = collapsed ? ChevronDoubleRightIcon : ChevronDoubleLeftIcon;

  useEffect(() => {
    if (showError) {
      Toast(t(error?.reason ?? "SOMETHING_WENT_WRONG"), t(error?.cause_info));
      setShowError(false);
    }
  }, [showError, error, t]);
  
  useEffect(() => {
    setFormData(automation);
    dispatch(fetchAutomationById({ automationId: automation.id }));
  }, [automation, dispatch, currentUser]);

  const parseTriggers = (triggers: StepTrigger[]) => {
    return triggers?.map((trigger: StepTrigger) => {
      return {
        ...trigger,
        state: trigger.configuration
          ? JSON.parse(trigger.configuration).lead_state
          : undefined,
      };
    });
  };

  const getAnswerTextTypesByRegex = (
    configObject: { regex: any } | undefined
  ) => {
    const regex = configObject?.regex;
    if (regex === EMAIL_REGEX) {
      return AnswerTextType.email;
    } else if (regex) {
      return AnswerTextType.custom;
    }
    return AnswerTextType.plainText;
  };

  const addQuestionsToTree = (
    node: any,
    currentEdges: any[],
    currentNodes: any[],
    xPos: number,
    yPos: number,
    xSpacing: number,
    ySpacing: number
  ) => {
    const answers = currentAutomation[node.id];
    answers?.forEach((x: any, index: number) => {
      const targetX = xPos + index * xSpacing; // Calculate the x position
      const targetY = yPos + ySpacing; // Calculate the y position
      currentEdges.push({
        id: `${x.id}`,
        source: `${node.id}`,
        target: `${x.nextStep.id}`,
        label: StepAnswerTypeLabel(x.typeId),
        data: {
          ...x,
          configuration: JSON.parse(x.configuration),
          textType: getAnswerTextTypesByRegex(JSON.parse(x.configuration)),
        },
      });
      currentNodes.push({
        id: `${x.nextStep.id}`,
        data: {
          label: x.nextStep.question,
          info: { ...x.nextStep, triggers: parseTriggers(x.nextStep.triggers) },
        },
        position: { x: targetX, y: targetY },
      });
      addQuestionsToTree(
        x.nextStep,
        currentEdges,
        currentNodes,
        targetX,
        targetY,
        xSpacing,
        ySpacing
      );
    });
  };

  useEffect(() => {
    if (stateAutomation) {
      const currentEdges:
        | React.SetStateAction<Edge<any>[]>
        | { id: any; source: any; target: any; label: any }[] = [];
      const node = stateAutomation["-1"];
      const currentNodes = [
        {
          id: `${node.id}`,
          data: {
            label: node.question,
            info: { ...node, triggers: parseTriggers(node.triggers) },
          },
          position: { x: 100, y: 0 },
        },
      ];
      addQuestionsToTree(
        node,
        currentEdges,
        currentNodes,
        rootX,
        rootY,
        horizontalSpacing,
        verticalSpacing
      );
      setEdges(currentEdges);
      setNodes(currentNodes);
    }
  }, [stateAutomation, setEdges, setNodes]);

  useEffect(() => {
    if (currentAutomation) {
      setAutomation(currentAutomation);
    }
  }, [currentAutomation]);

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const updatedAutomation = {
      name: formData.name,
      code: formData.code,
      steps: nodes.map((node) => {
        return {
          id: node.data.info.id,
          question: node.data.info.question,
          is_last_step: node.data.info.isLastStep,
          send_this_question: node.data.info.sendThisQuestion,
          is_first_step: node.data.info.isFirstStep,
          triggers: node.data.info.triggers,
        } as UpdateStepStruct;
      }),
      answers: edges.map((edge) => {
        return {
          id: edge.data.id,
          type: edge.data.type,
          configuration: JSON.stringify(edge.data.configuration),
        } as UpdateAnswerStruc;
      }),
    };
    dispatch(
      updateAutomation({
        automationId: automation.id,
        automation: updatedAutomation,
      })
    ).then((e) => {
      if (e.type === "automations/update/rejected") {
        setShowError(true);
      } else {
        toast(t("SAVED_SUCCESFULLY"));
        navigate(AutomationPath);
      }
    });
  };

  const handleNodeClick = (event: any, node: any) => {
    setCollapsed(true);
    setSelectedNode(node.data.info);
    setSelectedAnswer(null);
  };

  const handleEdgeClick = (event: any, edge: Edge<any>) => {
    setCollapsed(true);
    setSelectedAnswer(edge.data);
    setSelectedNode(null);
  };

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  const handleChangeNode = (event: any) => {
    const { name, value, checked } = event.target;
    var newValue: any;
    if (name === "isLastStep" || name === "sendThisQuestion") {
      newValue = checked;
    } else {
      newValue = value;
    }
    setSelectedNode((prevFormData: any) => ({
      ...prevFormData!,
      [name]: newValue!,
    }));
    const updatedNodes = nodes;
    const currentUpdatedNode = updatedNodes.find(
      (x) => +x.id === selectedNode.id
    );
    const currentUpdatedNodeIndex = updatedNodes.findIndex(
      (x) => +x.id === selectedNode.id
    );
    currentUpdatedNode!.data!.info! = { ...selectedNode!, [name]: newValue! };
    if (name === "question") {
      currentUpdatedNode!.data.label = newValue;
    }
    updatedNodes[currentUpdatedNodeIndex] = currentUpdatedNode!;
    setNodes(updatedNodes);
  };

  const handleChangeEdge = (event: any) => {
    const { name, value } = event.target;
    let updatedSelectedEdge;
    if (selectedAnswer.typeId === StepAnswerTypeEnum.NOT_ANSWER) {
      let configObject: { unit: string; timeNumber: number };
      if (name === "unit") {
        configObject = {
          unit: value,
          timeNumber: selectedAnswer.configuration?.timeNumber ?? 1,
        };
      } else {
        configObject = {
          timeNumber: value,
          unit: selectedAnswer.configuration?.unit ?? TimeUnitEnum.DAYS,
        };
      }
      setSelectedAnswer((prevFormData: any) => ({
        ...prevFormData!,
        configuration: configObject,
      }));
      updatedSelectedEdge = {
        ...selectedAnswer!,
        configuration: configObject,
      };
    } else if (selectedAnswer.typeId === StepAnswerTypeEnum.TEXT) {
      if (name === "textType") {
        if (+value === AnswerTextType.email) {
          setSelectedAnswer((prevFormData: any) => ({
            ...prevFormData!,
            textType: value,
            configuration: { regex: EMAIL_REGEX },
          }));
          updatedSelectedEdge = {
            ...selectedAnswer!,
            textType: value,
            configuration: { regex: EMAIL_REGEX },
          };
        } else {
          setSelectedAnswer((prevFormData: any) => ({
            ...prevFormData!,
            configuration: { regex: "" },
            textType: value,
          }));
          updatedSelectedEdge = {
            ...selectedAnswer!,
            configuration: { regex: "" },
            textType: value,
          };
        }
      } else {
        setSelectedAnswer((prevFormData: any) => ({
          ...prevFormData!,
          configuration: { regex: value },
        }));
        updatedSelectedEdge = {
          ...selectedAnswer!,
          configuration: { regex: value },
        };
      }
    }
    const updatedEdges = edges;
    const currentUpdatedEdge = updatedEdges.find(
      (x) => +x.id === selectedAnswer.id
    );
    const currentUpdatedEdgeIndex = updatedEdges.findIndex(
      (x) => +x.id === selectedAnswer.id
    );
    currentUpdatedEdge!.data = updatedSelectedEdge;
    if (name === "typeId") {
      currentUpdatedEdge!.label = StepAnswerTypeLabel(value);
    }
    updatedEdges[currentUpdatedEdgeIndex] = currentUpdatedEdge!;
    setEdges(updatedEdges);
  };

  const filteredTriggers = useMemo(() => {
    if (selectedNode) {
      // Assuming getAutomationTriggerTypes() returns an array of all triggers
      const allTriggers = getAutomationTriggerTypes();
      // Filter out triggers that are already present in selectedStep.triggers
      const triggersNotInSelectedStep = allTriggers.filter(
        (trigger) =>
          !selectedNode.triggers?.find(
            (x: StepTrigger) => `${x.step_trigger_type}` === `${trigger.key}`
          )
      );
      return triggersNotInSelectedStep;
    }
    return [];
  }, [selectedNode]);

  const addTrigger = (event: any) => {
    const { value } = event.target;
    setSelectedNode((prevFormData: any) => ({
      ...prevFormData!,
      triggers: prevFormData!.triggers?.concat({
        step_trigger_type: value,
        state:
          `${value}` === `${AutomationTriggerTypeEnum.CHANGE_LEAD_STATE}`
            ? LeadStatusEnum.NEW
            : undefined,
      }) ?? [
        {
          step_trigger_type: value,
          state:
            `${value}` === `${AutomationTriggerTypeEnum.CHANGE_LEAD_STATE}`
              ? LeadStatusEnum.NEW
              : undefined,
        },
      ],
    }));
    const updatedNodes = nodes;
    const currentUpdatedNode = updatedNodes.find(
      (x) => +x.id === selectedNode.id
    );
    const currentUpdatedNodeIndex = updatedNodes.findIndex(
      (x) => +x.id === selectedNode.id
    );
    currentUpdatedNode!.data!.info! = {
      ...selectedNode!,
      triggers: selectedNode!.triggers?.concat({
        step_trigger_type: value,
        configuration: "",
        state:
          `${value}` === `${AutomationTriggerTypeEnum.CHANGE_LEAD_STATE}`
            ? LeadStatusEnum.NEW
            : undefined,
      }) ?? [
        {
          step_trigger_type: value,
          configuration: "",
          state:
            `${value}` === `${AutomationTriggerTypeEnum.CHANGE_LEAD_STATE}`
              ? LeadStatusEnum.NEW
              : undefined,
        },
      ],
    };
    updatedNodes[currentUpdatedNodeIndex] = currentUpdatedNode!;
    setNodes(updatedNodes);
  };

  const addTriggerState = (event: any) => {
    const { value } = event.target;
    const triggers = selectedNode?.triggers;
    const currentTriggerIndex = selectedNode?.triggers.findIndex(
      (x: any) =>
        `${x.step_trigger_type}` ===
        `${AutomationTriggerTypeEnum.CHANGE_LEAD_STATE}`
    );
    if (
      currentTriggerIndex !== undefined &&
      currentTriggerIndex > -1 &&
      triggers
    ) {
      triggers[currentTriggerIndex] = {
        state: value,
        step_trigger_type: AutomationTriggerTypeEnum.CHANGE_LEAD_STATE,
        configuration: JSON.stringify({ lead_state: value }),
      };
      setSelectedNode((prevFormData: any) => ({
        ...prevFormData!,
        triggers: triggers,
      }));
      const updatedNodes = nodes;
      const currentUpdatedNode = updatedNodes.find(
        (x) => +x.id === selectedNode.id
      );
      const currentUpdatedNodeIndex = updatedNodes.findIndex(
        (x) => +x.id === selectedNode.id
      );
      currentUpdatedNode!.data!.info! = {
        ...selectedNode!,
        triggers: triggers,
      };
      updatedNodes[currentUpdatedNodeIndex] = currentUpdatedNode!;
      setNodes(updatedNodes);
    }
  };

  const removeTrigger = (currentTrigger: StepTrigger) => {
    let triggers = selectedNode?.triggers;
    const currentTriggerIndex = selectedNode?.triggers.findIndex(
      (x: any) => x === currentTrigger
    );
    if (
      currentTriggerIndex !== undefined &&
      currentTriggerIndex > -1 &&
      triggers
    ) {
      triggers.splice(currentTriggerIndex, 1);
      setSelectedNode((prevFormData: any) => ({
        ...prevFormData!,
        triggers: triggers ?? [],
      }));
      const updatedNodes = nodes;
      const currentUpdatedNode = updatedNodes.find(
        (x) => +x.id === selectedNode.id
      );
      const currentUpdatedNodeIndex = updatedNodes.findIndex(
        (x) => +x.id === selectedNode.id
      );
      currentUpdatedNode!.data!.info! = {
        ...selectedNode!,
        triggers: triggers ?? [],
      };
      updatedNodes[currentUpdatedNodeIndex] = currentUpdatedNode!;
      setNodes(updatedNodes);
    }
  };

  return (
    <div className="flex h-full">
      {isLoading && <LoadingMask />}
      <ToastContainer progressStyle={{ background: "#D4AF37" }} />
      <div className="flex-grow p-5">
        {!collapsed && (
          <Button
            iconOnly
            classNames="fixed w-10 h-10 right-0 m-4 z-20"
            onClick={toggleSidebar}
          >
            <Icon className="w-5 h-5" />
          </Button>
        )}
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onEdgeClick={handleEdgeClick}
          onNodeClick={handleNodeClick}
          onConnect={onConnect}
        />
      </div>
      {collapsed && (
        <div className="md:w-1/3 w-full bg-white flex-shrink-0 px-4 transition-all duration-300 ease-in-out absolute md:relative h-full">
          <div className="shadow-sm">
            <div className="flex justify-between px-4 pb-4">
              <button
                className="grid text-asureis-gray content-center hover:text-black w-10 h-10 md:hidden"
                onClick={() => setCollapsed(!collapsed)}
              >
                <Icon className="w-6 h-6" />
              </button>
              <Button
                onClick={handleSubmit}
                classNames="fixed bottom-4 right-4 z-10 mx-3"
              >
                <span className="flex items-center font-semibold pr-3">
                  <div className="w-8 p-1 aspect-square mr-2">
                    <CheckIcon />
                  </div>
                  {t("SAVE")}
                </span>
              </Button>
            </div>
            <Form handleOnSubmit={handleSubmit}>
              <FormInput
                value={formData.code}
                name="code"
                handleOnChange={handleChange}
                label={t("CODE")}
              />
              <FormInput
                value={formData.name}
                name="name"
                handleOnChange={handleChange}
                label={t("NAME")}
              />
            </Form>
          </div>
          {selectedNode && (
            <div className="pb-12">
              <div className="font-semibold text-blue text-xl px-3 pt-3 pb-4">
                {t("QUESTION")}
              </div>
              <FormInput
                value={selectedNode.question}
                name="question"
                handleOnChange={handleChangeNode}
                label={t("QUESTION")}
              />
              <FormCheckbox
                checked={selectedNode.isLastStep}
                name="isLastStep"
                handleOnChange={handleChangeNode}
                label={t("IS_LAST_STEP")}
                inlineDisplay
                className="mb-2"
                isDisabled
              />
              <FormCheckbox
                checked={selectedNode.sendThisQuestion}
                isDisabled={!selectedNode.isLastStep}
                name="sendThisQuestion"
                handleOnChange={handleChangeNode}
                label={t("SEND_THIS_QUESTION")}
                inlineDisplay
                tooltip={t("SEND_THIS_QUESTION_TOOLTIP")}
              />
              <div className="font-semibold text-blue text-xl px-3 pt-3 pb-4">
                {t("TRIGGERS")}
              </div>
              <FormSelect
                name="step_trigger_type"
                options={filteredTriggers}
                label={t("ADD_TRIGGER")}
                handleOnChange={addTrigger}
              />
              <div className="grid gap-2 px-3 text-gray-700">
                {selectedNode.triggers?.map((element: StepTrigger) => {
                  return (
                    <div className="bg-neutral-100 text-sm">
                      <div className="flex flex-col justify-between align-center pt-3 relative">
                        {`${element.step_trigger_type}` ===
                        `${AutomationTriggerTypeEnum.CHANGE_LEAD_STATE}` ? (
                          <FormSelect
                            name="triggerUserState"
                            selectedValue={element.state}
                            options={getLeadStatuses()}
                            label={
                              AutomationTriggerTypeLabel(
                                `${element.step_trigger_type}`
                              ) ?? t("STATUS")
                            }
                            handleOnChange={addTriggerState}
                          />
                        ) : (
                          <div className="p-3 pt-0">
                            {AutomationTriggerTypeLabel(
                              `${element.step_trigger_type}`
                            )}
                          </div>
                        )}
                        <XMarkIcon
                          className="w-5 h-5 cursor-pointer absolute right-3"
                          onClick={() => removeTrigger(element)}
                        />
                        {`${element.step_trigger_type}` ===
                          `${AutomationTriggerTypeEnum.CREATE_CLIENT}` && (
                          <div className="p-3 pt-0 text-xs">
                            {t("CREATE_CLIENT_FROM_LEAD_EXPLANATION")}
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          {selectedAnswer && (
            <div>
              <div className="font-semibold text-blue text-xl px-3 pt-3 pb-4">
                {t("ANSWER")}
              </div>
              <FormSelect
                name="typeId"
                selectedValue={selectedAnswer.typeId}
                options={getStepAnswerTypes()}
                isDisabled
                label={t("ANSWER_TYPE")}
                handleOnChange={handleChangeEdge}
              />
              {selectedAnswer.typeId === StepAnswerTypeEnum.TEXT && (
                <>
                  <FormSelect
                    name="textType"
                    selectedValue={selectedAnswer.textType}
                    options={getAnswerTextTypes()}
                    label={t("OPTIONS")}
                    handleOnChange={handleChangeEdge}
                  />
                  {+selectedAnswer.textType === AnswerTextType.custom && (
                    <FormInput
                      name="customTextConfig"
                      value={selectedAnswer.configuration.regex}
                      label={t("CUSTOM")}
                      handleOnChange={handleChangeEdge}
                    />
                  )}
                </>
              )}

              {selectedAnswer.typeId === StepAnswerTypeEnum.NOT_ANSWER && (
                <>
                  <FormSelect
                    name="unit"
                    selectedValue={selectedAnswer.configuration?.unit}
                    options={getTimeUnits()}
                    label={t("TIME_UNIT")}
                    handleOnChange={handleChangeEdge}
                  />
                  <FormInput
                    name="timeNumber"
                    type="number"
                    value={+selectedAnswer.configuration?.timeNumber}
                    label={t("TIME")}
                    handleOnChange={handleChangeEdge}
                  />
                </>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
