import classNames from "classnames";

interface WidgetProps {
  children: React.ReactNode;
  doubleHeight?: boolean;
  halfWidth?: boolean;
  className?: string;
}

const Widget = ({
  children,
  className,
  doubleHeight,
  halfWidth,
}: WidgetProps) => {
  return (
    <div
      className={classNames(
        `w-full relative overflow-hidden bg-white rounded-lg p-5 min-h-96 text-center ${
          className ?? ""
        }`,
        {
          "row-span-1": !doubleHeight,
          "row-span-2": doubleHeight,
          "col-span-2": !halfWidth,
          "col-span-1": halfWidth,
        }
      )}
    >
      {children}
    </div>
  );
};

export default Widget;
