import { useTranslation } from "react-i18next";
import { EditPolicy, MatchedPolicyWithCommission, Policy } from "../../models/Policies";
import { set, SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { AppDispatch } from "../../state/store";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import { AgentCommissionRule } from "../../models/AgentCommissionRule";
import { toast, ToastContainer } from "react-toastify";
import { Modal } from "../../components/Modal";
import { DocumentPlusIcon, PaperAirplaneIcon, PencilIcon } from "@heroicons/react/24/outline";
import { ReactiveFormInput } from "../../components/form/ReactiveFormInput";
import { ReactiveFormSelect } from "../../components/form/ReactiveFormSelect";
import { getCarriers } from "../../enums/Carrier";
import { getPolicyStates } from "../../enums/PolicyState";
import { ReactiveFormRadioButtonSelect } from "../../components/form/ReactiveFormRadioButtonSelect";
import { RoleEnum } from "../../enums/Role";
import { User } from "../../models/User";
import { fetchAgencyUserCommissionRules } from "../../state/agencies/action";
import { fetchPoliciesByClientId, updatePolicy } from "../../state/policies/actions";
import { Toast } from "../../components/Toast";
import { LoadingMask } from "../../components/LoadingMask";
import { ReactiveFormSearchableSelect } from "../../components/form/ReactiveFormSearchableSelect";
import { ReactiveFormRemoteSearchableSelect } from "../../components/form/ReactiveFormRemoteSearchableSelect";
import { fetchClientById, searchClients } from "../../state/clients/actions";
import { Client } from "../../models/Client";
import { createMatchedPolicy } from "../../state/commissions/action";
import { ReactiveFormCheckbox } from "../../components/form/ReactiveFormCheckbox";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { SpinCircle } from "../../components/SpinCircle";

interface Props {
  info: {
    show: boolean;
    policy?: any;
  };
  onClose: () => void;
}

export const ReviewUnmatchedPolicyModal: React.FC<Props> = ({ info, onClose }) => {
  const { t } = useTranslation();
  const [showUpdateError, setShowUpdateError] = useState(false);
  const [isPolicyUpdateLoading, setIsPolicyUpdateLoading] = useState(false);
  const errorUpdate = useSelector((state: any) => state.policies.errorUpdate);

  const dispatch = useDispatch<AppDispatch>();
  const currentUser = useSelector((state: any) => state.users.currentUser);
  const agents = useSelector((state: any) => state.users.users);
  const [selectedPossibleClient, setSelectedPossibleClient] = useState<any | null>(null);
  const [loadingPossibleClient, setLoadingPossibleClient] = useState(false);
  const [loadingPolicies, setLoadingPolicies] = useState(false);

  const [commissionRules, setCommissionRules] = useState<AgentCommissionRule[]>(
    []
  );
  const [selectedCommissionRule, setSelectedCommissionRule] = useState<
    AgentCommissionRule | undefined
  >(undefined);
  const [loadingRules, setLoadingRules] = useState(false);

  const [selectedClientPolicies, setSelectedClientPolicies] = useState<Policy[]>([]);

  const methods = useForm<MatchedPolicyWithCommission>({
    defaultValues: {},
  });
  const { handleSubmit, control, getValues, reset } = methods;

  useEffect(() => {
    if (showUpdateError) {
      Toast(
        t(errorUpdate?.reason ?? "SOMETHING_WENT_WRONG"),
        t(errorUpdate?.cause_info)
      );
      setShowUpdateError(false);
    }
  }, [showUpdateError, errorUpdate, t]);

  useEffect(() => {
    info.policy ? reset(info.policy) : reset({});

    if (info.policy && info.policy.possible_agent_id) {
      if (agents.find((x: any) => x.id === info.policy!.possible_agent_id)) {
        methods.setValue("agent_id", info.policy.possible_agent_id);
        loadCommissionRules(+info.policy.possible_agent_id!);
      } else {
        info.policy.agent_id = undefined;
      }
    }

    if (info.policy && info.policy.possible_client_id) {
      setLoadingPossibleClient(true);
      dispatch(fetchClientById(info.policy.possible_client_id))
      .then((e: any) => setSelectedPossibleClient(
        { label: `${e.payload.first_name} ${e.payload.middle_name ?? ""} ${e.payload.last_name} ${e.payload.second_last_name ?? ""}`.trim().replaceAll("  ", " "), 
        value: e.payload.id }))
      .finally(() => setLoadingPossibleClient(false));
    }
  }, [info.policy, reset]);

  useEffect(() => {
    methods.setValue("client_id", selectedPossibleClient?.value);

    if (selectedPossibleClient) {
      setLoadingPolicies(true);
      dispatch(
        fetchPoliciesByClientId({
          agencyId: currentUser.agency_id,
          clientId: selectedPossibleClient.value,
        })
      ).then((e) => {
        setSelectedClientPolicies((e.payload as Policy[])
          .filter((x: Policy) => x.number === "" && x.carrier_id === +methods.getValues("carrier_id") && x.agency_id === currentUser.agency_id));

      }).catch(() => setSelectedClientPolicies([]))
      .finally(() => setLoadingPolicies(false));

    }
  }, [selectedPossibleClient]);
  
  const loadCommissionRules = (agentId: number) => {
    setLoadingRules(true);
        dispatch(
          fetchAgencyUserCommissionRules({
            agencyId: currentUser.agency_id,
            userId: agentId,
          })
        ).then((e) => {
          let rules = (e.payload || []) as AgentCommissionRule[];
          setCommissionRules(rules);

          let selectedRule = rules.find((x: AgentCommissionRule) => x.is_default);

          setSelectedCommissionRule(selectedRule);
          methods.setValue("agent_commission_rule_id", selectedRule?.id);
          setLoadingRules(false);
        });
  };
  const onSubmitInvalid: SubmitErrorHandler<EditPolicy> = (data) => {
    toast(t("PLEASE_ENTER_ALL_POLICY_REQUIRED_FIELDS"));
  };

  const onSubmit: SubmitHandler<any> = async (data: any) => {
    if (checkPolicyMembers(data)) {
      Toast(t("POLICY_MEMBERS_ERROR"), t("POLICY_MEMBERS_MESSAGE"));
    } else {
      
      const payload: MatchedPolicyWithCommission = {
        id: +data.id,
        carrier_id: +data.carrier_id,
        client_id: +data.client_id,
        policy_number: data.policy_number,
        effective_start_date: data.effective_start_date,
        effective_end_date: data.effective_end_date,
        members_insured: +data.members_insured,
        free_plan: data.free_plan,
        policy_state: data.policy_state,
        agent_id: +data.agent_id,
        agent_commission_rule_id: +data.agent_commission_rule_id,
        commission: +data.total_income,
        billing_date: data.billing_date,
        statement_date: data.statement_date,
        policy_id_to_override: data.override_policy == "true" && selectedClientPolicies.length > 0 ? +selectedClientPolicies[0].id : undefined,
      };

      setIsPolicyUpdateLoading(true);

      dispatch(createMatchedPolicy({ body: payload, agencyId: currentUser.agency_id })).then((e) => {
        if (e.type === "commissions/createMatchedPolicy/rejected") {
          setShowUpdateError(true);
          setIsPolicyUpdateLoading(false);
        } else {
          toast(t("SAVED_SUCCESFULLY"));
          setIsPolicyUpdateLoading(false);
          closeModal();
        }
      });
    }
  };


  const checkPolicyMembers = (data: any) => {
    let policiesMembers = 0;

    if (data.members_insured != undefined) {
      if (data.members_insured <= 0) {
        policiesMembers++;
      }
    } else {
      policiesMembers++;
    }

    return policiesMembers > 0;
  };

  const closeModal = () => {
    reset();
    setSelectedCommissionRule(undefined);
    setCommissionRules([]);
    setSelectedPossibleClient(null);
    onClose();
  };

  return (
    <>
      <ToastContainer progressStyle={{ background: "#D4AF37" }} />
      <Modal
        isOpen={info.show}
        onClose={closeModal}
        label={t("REVIEW_AND_CREATE_POLICY")}
        saveButton={{
          label: t("SAVE"),
          icon: <PaperAirplaneIcon />,
          onClick: handleSubmit(onSubmit, onSubmitInvalid),
        }}
      >
        {isPolicyUpdateLoading && <LoadingMask />}
        <div className="w-full flex flex-wrap">
          <Fragment>
            <ReactiveFormInput
              control={control}
              className="md:w-1/3"
              label={t("POLICY_NUMBER")}
              name={`policy_number`}
            />
            <ReactiveFormSelect
              className="md:w-1/3"
              name={`carrier_id`}
              label={t("CARRIER")}
              options={getCarriers()}
              control={control}
              isRequired
              isDisabled
            />

            <ReactiveFormInput
              control={control}
              className="md:w-1/3"
              label={t("MEMBERS_INSURED")}
              name={`members_insured`}
              type="number"
              isRequired
            />

            <ReactiveFormInput
              control={control}
              className="md:w-1/3"
              label={t("STATEMENT_CLIENT_NAME")}
              name={`client`}
              isDisabled={true}
            />

            <ReactiveFormRemoteSearchableSelect
              control={control}
              className="md:w-1/3"
              label={t("CLIENT")}
              name={`client_id`}
              defaultItem={selectedPossibleClient}
              dataSourceAsyncThunk={searchClients}
              data={{ agencyId: currentUser?.agency_id }}
              isDisabled={loadingPossibleClient}
              isRequired 
              onChange={(v: any) => setSelectedPossibleClient(v)}/>

            <div className="md:w-1/3"/>

            <ReactiveFormInput
              control={control}
              type="date"
              className="md:w-1/3"
              label={t("START_DATE")}
              name={`effective_start_date`}
              isRequired
            />
            <ReactiveFormInput
              control={control}
              type="date"
              className="md:w-1/3"
              label={t("END_DATE")}
              name={`effective_end_date`}
            />
            
            <ReactiveFormSelect
              className="md:w-1/3"
              name={`policy_state`}
              label={t("STATUS")}
              options={getPolicyStates()}
              control={control}
              isRequired
            />
            <div className="flex md:w-full">
              <ReactiveFormRadioButtonSelect
                className="mr-2"
                control={control}
                name={`free_plan`}
                label={t("FREE_PLAN")}
                options={[
                  { label: t("YES"), value: "1" },
                  { label: t("NO"), value: "0" },
                ]}
              />
            </div>
          </Fragment>
          <Fragment>
              <ReactiveFormInput
                className="md:w-1/3"
                name={`agent`}
                label={t("STATEMENT_AGENT_NAME")}
                control={control}
                isDisabled
              />

            <ReactiveFormSelect
                className="md:w-1/3"
                name={`agent_id`}
                label={t("AGENT")}
                options={agents.map((x: User) => {
                  return {
                    label: `${x.first_name} ${x.last_name}`,
                    value: `${x.id}`,
                  };
                })}
                control={control}
                handleOnChange={(e: any) => {
                  loadCommissionRules(+e.target.value);
                }}
                isRequired
            />

              <ReactiveFormSelect
                className="md:w-1/3"
                name={`agent_commission_rule_id`}
                label={t("AGENT_COMMISSION_RULE")}
                options={commissionRules.map((r: AgentCommissionRule) => {
                  return {
                    label: `${r.name} - ${
                      r.is_relative ? r.value! * 100 + "%" : "$" + r.value
                    }`,
                    value: `${r.id}`,
                  };
                })}
                control={control}
                isDisabled={
                  loadingRules
                }
                isRequired
                handleOnChange={(e: any) =>
                  setSelectedCommissionRule(
                    commissionRules.find(
                      (x: AgentCommissionRule) => x.id === +e.target.value
                    )
                  )
                }
              />

              {selectedCommissionRule !== undefined && (
                <p className="text-slate-500 text-xs px-3">
                  {selectedCommissionRule.description}
                </p>
              )}
            </Fragment>

          <Fragment>
            {loadingPolicies && <div className="ml-4 py-2"><SpinCircle/></div> } 
            {!loadingPolicies && selectedClientPolicies.length > 0 && <ReactiveFormCheckbox className="mt-2" name={"override_policy"} options={[{
              label: t("OVERRIDE_POLICY_CONFIRMATION"),
              value: "true"
            }]} control={control}/>}
          </Fragment>
        </div>
      </Modal>
    </>
  );
};
