import classNames from "classnames";
import { useTranslation } from "react-i18next";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import logoAsureis from "../../assets/images/Empty_logo.svg";
import Widget from "./Widget";
import { ChartData } from "../../models/ChartData";

interface ChartProps {
  data: ChartData[];
  isLightBlue?: boolean;
  title: string;
  subtitle: string;
  className?: string;
}

const Chart = ({
  data,
  isLightBlue,
  title,
  subtitle,
  className,
}: ChartProps) => {
  const { t } = useTranslation();
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="w-full bg-white rounded-lg border-2 p-4 flex flex-col justify-center">
          <p className="text-center text-lg font-medium">{`${label}`}</p>
          <p
            className={classNames({
              "text-center text-lg": true,
              "text-light-blue": isLightBlue,
              "text-gold": !isLightBlue,
            })}
          >{`value: ${payload[0].value}`}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <Widget>
      <div className="w-full flex flex-col">
        <div
          className={classNames({
            "text-xl font-bold text-gold mt-8 flex justify-center": true,
            "text-light-blue": isLightBlue,
            "text-gold": !isLightBlue,
          })}
        >
          {title}
        </div>
        <div className="text-blue text-base text-justify mt-3 mx-3 flex justify-center">
          {subtitle}
        </div>
      </div>
      {data.length > 0 && (
        <div style={{ width: "100%", height: "100%" }}>
          <ResponsiveContainer width={"99%"} height={320}>
            <LineChart
              data={data}
              margin={{
                top: 50,
                right: 60,
                left: 20,
                bottom: 50,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" stroke="#eee" />
              <Tooltip content={<CustomTooltip />} />
              <YAxis
                stroke="#577082"
                allowDecimals={false}
                interval={"preserveStartEnd"}
              />
              <XAxis dataKey={"key"} stroke="#577082" />
              <Line
                type="monotone"
                dataKey="value"
                stroke={isLightBlue ? "#1f99ef" : "#D4AF37"}
                strokeWidth={4}
                animationDuration={400}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      )}
      {data.length === 0 && (
        <div className="w-full flex flex-col items-center justify-center mt-7 mb-8">
          <img src={logoAsureis} alt="Asureis Logo" className="h-32" />
          <div className="text-gray-500 text-xl font-medium mt-2">
            {t("NO_DATA")}
          </div>
        </div>
      )}
    </Widget>
  );
};

export default Chart;
