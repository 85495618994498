import {
  ChevronLeftIcon,
  DocumentArrowDownIcon,
  DocumentArrowUpIcon,
  DocumentIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import moment from "moment";
import {
  ChangeEvent,
  createRef,
  RefObject,
  useEffect,
  useMemo,
  useState,
} from "react";
import { set, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import stringSimilarity from "string-similarity";
import * as xlsx from "xlsx";
import Button from "../../components/Button";
import FilteredTable from "../../components/FilteredTable";
import { FormSelect } from "../../components/form/FormSelect";
import { LoadingMask } from "../../components/LoadingMask";
import { Modal } from "../../components/Modal";
import {
  generateComissionsReport,
  getComissionsReport,
  pastPage,
} from "../../state/commissions/action";
import { AppDispatch } from "../../state/store";
import { Toast } from "../../components/Toast";
import { getCarriers } from "../../enums/Carrier";
import { fetchCommissionStatementMap, saveCommissionStatementMap } from "../../state/metadata/action";
import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
import { SpinCircle } from "../../components/SpinCircle";
import { ReviewUnmatchedPolicyModal } from "./ReviewUnmatchedPolicyModal";
import { ModalInformation } from "../../components/ModalInformation";
import { CheckIcon } from "@heroicons/react/24/solid";
import { BillingPeriod } from "../../models/Agency";

interface Props {}

export const Commissions = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const isLoading = useSelector((state: any) => state.commissions.isLoading);
  const [isOpenFilePreviewModal, setIsOpenFilePreviewModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const currentUser = useSelector((state: any) => state.users.currentUser);
  const inputFile: RefObject<HTMLInputElement> = createRef();
  const commissions = useSelector((state: any) => state.commissions.state);
  const commissionsError = useSelector((state: any) => state.commissions.error);
  const billingPeriods = useSelector(
    (state: any) => state.agencies.billingPeriods
  );
  const currentAgency = useSelector(
    (state: any) => state.users.currentUser?.agency
  );
  const [selectedBillingPeriod, setSelectedBillingPeriod] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("confirmed");
  const [showCommissionsError, setShowCommissionsError] = useState(false);
  const [selectedCarrier, setSelectedCarrier] = useState("");
  const [selectedCarrierCommissionStatementMap, setSelectedCarrierCommissionStatementMap] = useState<any[]>([]);
  const [openReviewUpdateModal, setOpenReviewModalModal] = useState({
    show: false,
    policy: undefined,
  })
  

  const [isExporting, setIsExporting] = useState(false);

  const [showPolicyNotReportedModal, setShowPolicyNotReportedModal] = useState(false);

  const [loadingMap, setLoadingMap] = useState(false);

  const [uploadingFile, setUploadingFile] = useState(false);
  const [generatingReport, setGeneratingReport] = useState(false);

  const [uploadProgress, setUploadProgress] = useState(0);

  const pageCommissions = useSelector(
    (state: any) => state.commissions.pageCommissions
  );
  const paginatedOptions = useSelector(
    (state: any) => state.commissions.paginatedOptions
  );

  const methods = useForm<{}>();
  const { control } = methods;

  const startMethods = useForm<{}>();
  const { handleSubmit: handleStartSubmit, control: startControl } =
    startMethods;

  const columns = useMemo(
    () => [
      {
        Header: t("POLICY_NUMBER"),
        accessor: "policy_number",
      },
      {
        Header: t("CARRIER"),
        accessor: "carrier",
      },
      {
        Header: t("BILLING_PERIOD"),
        accessor: "billing_period",
      },
      {
        Header: t("COMMISSION_TYPE"),
        accessor: "commission_rule_type",
      },
      {
        Header: t("COMMISSION_TYPE_VALUE"),
        accessor: "commission_rule_type_value",
      },
      {
        Header: t("AGENT"),
        accessor: "agent",
      },
      {
        Header: t("TOTAL_COMMISSION"),
        accessor: "total_income",
        Cell: (data: any) => <>{`$${data.row.original.total_income}`}</>,
      },
      {
        Header: t("AGENT_COMMISSION"),
        accessor: "agent_commission",
        Cell: (data: any) => <>{`$${data.row.original.agent_commission}`}</>,
      },
      {
        Header: t("AGENCY_COMMISSION", {agency_name: currentAgency.name}),
        accessor: "agency_commission",
        Cell: (data: any) => <>{`$${data.row.original.agency_commission}`}</>,
      },
    ],
    [t]
  );

  const unmatchedColumns = useMemo(
    () => [
      {
        Header: t("POLICY_NUMBER"),
        accessor: "policy_number",
      },
      {
        Header: t("STATEMENT_CLIENT_NAME"),
        accessor: "client",
      },
      {
        Header: t("CARRIER"),
        accessor: "carrier",
      },
      {
        Header: t("BILLING_PERIOD"),
        accessor: "billing_period",
      },
      {
        Header: t("STATEMENT_AGENT_NAME"),
        accessor: "agent",
      },
      {
        Header: t("TOTAL_COMMISSION"),
        accessor: "total_income",
        Cell: (data: any) => <>{`$${data.row.original.total_income}`}</>,
      },
      {
        Header: t("REVIEW_REASON"),
        accessor: "unmatch_reason",
        Cell: (data: any) => <>{t(data.row.original.unmatch_reason)}</>,
      },
      {
        Header: " ",
        Cell: (row: any) => {
          return (
            <div className="flex flex-row justify-center divide-x">
              <Button
                isTerciary
                iconOnly
                tooltip={t("REVIEW")}
                classNames="mr-2"
                onClick={() => {
                  if (row.row.original.unmatch_reason === "POLICY_NOT_REPORTED") {
                    setShowPolicyNotReportedModal(true);
                  } else {
                    let carriers = getCarriers();

                    const bestMatch = stringSimilarity.findBestMatch(
                      row.row.original.carrier,
                      carriers.map((x) => x.label)
                    );

                    setOpenReviewModalModal({ show: true, policy: {...row.row.original, carrier_id: carriers[bestMatch.bestMatchIndex].value} })}
                  }
                }
                >
                <ExclamationCircleIcon className="h-5 aspect-square stroke-gold" />
              </Button>
            </div>
          );
        },
      },
    ],
    [t]
  );

  useEffect(() => {
    if (showCommissionsError) {
      Toast(
        t(commissionsError?.reason ?? "SOMETHING_WENT_WRONG"),
        t(commissionsError?.cause_info)
      );
      setShowCommissionsError(false);
    }
  }, [commissionsError, showCommissionsError, t]);

  useEffect(() => {
    if (selectedCarrier && selectedCarrier !== "") {
      setLoadingMap(true);
      dispatch(fetchCommissionStatementMap({ agencyId: currentUser.agency_id, carrierId: +selectedCarrier }))
      .then((e: any) => {
        const payload = e.payload as any[];
        setSelectedCarrierCommissionStatementMap(payload);
      })
      .finally(() => setLoadingMap(false));
    }
  }, [selectedCarrier]);

  useEffect(() => {
    setSelectedBillingPeriod(billingPeriods.filter((x: any) => x.is_active)[0]?.id ?? "");
  }, [billingPeriods]);

  useEffect(() => {
    if(uploadProgress === 1) {
      setGeneratingReport(true);
      setUploadingFile(false);
    }
  }, [uploadProgress])

  const openFilePreviewModal = () => {
    setIsOpenFilePreviewModal(true);
  };

  const closeFilePreviewModal = () => {
    setIsOpenFilePreviewModal(false);
    setSelectedFile(null);
    setSelectedCarrier("");
    setUploadingFile(false);
    setUploadProgress(0);
    setGeneratingReport(false)
    if (inputFile.current) {
      inputFile.current.value = "";
    }
  };

  const onFileUploadSubmitted = () => {
    let matches = selectedFile.format;

    /* let data = selectedFile.fileData.map((obj: any) =>
      matches.reduce((acc: any, m: any) => {
        if (m.match && m.match.key in obj) {
          acc[m.key] = obj[m.match.key]; // Only add the keys that exist in the object
        }
        return acc;
      }, {})
    );

    data = data.map((obj: any) => {
      return {
        ...obj,
        commission: +obj.commission,
        statement_date: moment(obj.statement_date).format("YYYY-MM-DD"),
        billing_date: moment(obj.billing_date).format("YYYY-MM-DD"),
        members_insured: isNaN(obj.members_insured) ? null : +obj.members_insured,
      };
    });
 */
    dispatch(saveCommissionStatementMap({
      agencyId: currentUser.agency_id,
      carrierId: +selectedCarrier,
      map: matches
    }));

    setUploadingFile(true);
    dispatch(
      generateComissionsReport({ agencyId: currentUser.agency_id, 
        carrierId: +selectedCarrier, 
        matches: matches, 
        file: selectedFile.file, 
        progressCallback: (progress: number) => setUploadProgress(progress) })  
    ).then((e) => {
      if (e.type === "commissions/generateComissionsReport/rejected") {
        setShowCommissionsError(true);
      } else {
        toast(t("COMMISSIONS_UPLOADED_SUCCESFULLY"));
        setSelectedStatus("confirmed");
        closeFilePreviewModal();
      }
    }).finally(() => {
      setUploadingFile(false);
      setUploadProgress(0);
      setGeneratingReport(false)
    });
  };

  const exportReport = async () => {
    let total = paginatedOptions.totalCount;

    //setIsExporting(true);

    let commissions = await dispatch(
      getComissionsReport({
        agencyId: currentUser.agency_id,
        billingPeriodId: +selectedBillingPeriod,
        pageSize: total,
        page: 0,
        status: selectedStatus,
      })
    );

    const wb = xlsx.utils.book_new();

    // Convert HTML table to a worksheet
    const ws = xlsx.utils.json_to_sheet((commissions.payload as any).commissions);

    // Append the worksheet to the workbook
    xlsx.utils.book_append_sheet(wb, ws, "Sheet1");

    // Generate and download the XLSX file
    xlsx.writeFile(wb, "table.xlsx");

    //setIsExporting(false);
  };

  const missingMappingValues = () => {
    if (!selectedFile) return true;

    return selectedFile.format.some((x: any) => !x.match && x.key !== "members_insured")
  }

  const addFilePreviewModal = () => {
    return (
      <Modal
        isOpen={isOpenFilePreviewModal}
        onClose={closeFilePreviewModal}
        hideCloseButton={uploadingFile || generatingReport}
        label={t("UPLOAD_COMMISIONS")}
        saveButton={{
          label: t("UPLOAD"),
          icon: <DocumentArrowUpIcon />,
          onClick: onFileUploadSubmitted,
          disabled: missingMappingValues() || uploadingFile || generatingReport
        }}
        thirdButton={selectedFile && !uploadingFile && !generatingReport ? {
          label: t("BACK"),
          icon: <ChevronLeftIcon />,
          onClick: () => setSelectedFile(null),
        } : undefined}
        hideCancelButton
      >
        {selectedFile && !uploadingFile && !generatingReport && (<>
          <label className="text-sm text-slate-600">
            {t("EXPECTED_COMMISSION_FORMAT")}
          </label>
          <div className="flex flex-col divide-y mt-5">
            {selectedFile.format.map((x: any) => (
              <div className="flex grow justify-between">
                <div className="md:w-1/2 content-center">
                  <label>{x.header}{x.key !== "members_insured" && ' *'}</label>
                </div>

                <FormSelect
                  label=""
                  name=""
                  options={selectedFile.fileColumns.map((item: any) => ({
                    label: item,
                    value: item,
                  }))}
                  selectedValue={x.match?.key}
                  handleOnChange={(e) => {
                    setSelectedFile((prev: any) => {
                      let idx = prev.format.indexOf(
                        (y: any) => y.key === x.key
                      );

                      x.match = {};
                      x.match.key = e.target.value;
                      x.match.score = 2;

                      prev.format[idx] = x;

                      return { ...prev };
                    });
                  }}
                />
              </div>
            ))}
          </div>
          </>
        )}

        {selectedFile && uploadingFile && <>
          <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
            <div className="bg-blue h-2.5 rounded-full" style={{"width": `${uploadProgress*100}%`}}></div>
          </div>

          <label className="text-sm">
            {t("UPLOADING_STATEMENT", {progress: Math.round(uploadProgress*100)})}
          </label></>
        }

        {selectedFile && generatingReport && (
          <div className="flex flex-row gap-x-3">
            <SpinCircle />
            <label className="text-sm">
              {t("GENERATING_REPORT")}
            </label></div>
        )}

        {!selectedFile && <>
          <div className="flex flex-row items-center">
            <FormSelect
              className='md:w-1/2'
              label={t("PLEASE_CHOOSE_CARRIER")}
              selectedValue={selectedCarrier}
              name="carrier_id"
              options={getCarriers()}
              handleOnChange={(e) => setSelectedCarrier(e.target.value)} /> 

            {loadingMap && <span className="mt-3"><SpinCircle/></span>}
          </div>

          <Button onClick={() => showUploadDialog()} classNames="ml-3" isDisabled={!selectedCarrier || loadingMap}>
            <input
              type="file"
              id="file"
              ref={inputFile}
              accept=".csv, .xls, .xlsx, .numbers, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              onChange={(e) => handleFileSelected(e)}
              multiple={false}
              style={{ display: "none" }}
            />
            <span className="flex items-center font-semibold pr-3">
              <div className="w-8 p-1 aspect-square mr-2">
                <DocumentArrowUpIcon />
              </div>
              {t("UPLOAD_FILE")}
            </span>
          </Button>
        </>}
      </Modal>
    );
  };

  const addReviewUnmatchedPolicyModal = () => {
    return <><ReviewUnmatchedPolicyModal
      info={openReviewUpdateModal}
      onClose={() =>
        setOpenReviewModalModal({ show: false, policy: undefined })
      }
    />
    <ModalInformation isOpen={showPolicyNotReportedModal} onClose={() => setShowPolicyNotReportedModal(false)} label={t("REVIEW_STATEMENT")} children={
      <p>{t("PLEASE_UPLOAD_STATEMENT_FOR_POLICY")}</p>
    } 
    saveButton={{label: t("OK"), onClick: () => setShowPolicyNotReportedModal(false), icon: <CheckIcon />}}
    cancelButton={false}/>
    </>
  }

  const showUploadDialog = () => {
    inputFile.current!.click();
  };

  const findBestMatches = (
    source1Keys: string[],
    source2Keys: string[],
    threshold = 0.5
  ) => {
    const matches: any = {};

    const fileMatchedKeys: any = {};

    source1Keys.forEach((key1) => {
      const bestMatch = stringSimilarity.findBestMatch(
        key1.replaceAll("_", " "),
        source2Keys
      );

      const bestMatchKey = bestMatch.bestMatch.target;
      const bestMatchRating = bestMatch.bestMatch.rating;

      if (bestMatchRating >= threshold) {
        if (!fileMatchedKeys[bestMatchKey]) {
          matches[key1] = { key: bestMatchKey, score: bestMatchRating };
          fileMatchedKeys[bestMatchKey] = { key: key1, score: bestMatchRating };
        } else {
          let prevValue = fileMatchedKeys[bestMatchKey];

          if (prevValue.score < bestMatchRating) {
            matches[key1] = { key: bestMatchKey, score: bestMatchRating };
            matches[prevValue.key] = { key: null, score: 0 };
            fileMatchedKeys[bestMatchKey] = bestMatchRating;
          }
        }
      }
    });

    return matches;
  };

  const handleFileSelected = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];

    const reader = new FileReader();
    reader.onload = async (e: ProgressEvent<FileReader>) => {
      const data = e?.target?.result;

      let workbook = xlsx.read(data);

      let requiredFields = [
        { key: "policy_number", header: t("POLICY_NUMBER") },
        { key: "statement_date", header: t("STATEMENT_DATE") },
        { key: "billing_date", header: t("BILLING_DATE") },
        { key: "client_name", header: t("CLIENT_NAME") },
        { key: "carrier_name", header: t("CARRIER") },
        { key: "agent_name", header: t("AGENT_NAME") },
        { key: "effective_start_date", header: t("EFFECTIVE_START_DATE") },
        { key: "members_insured", header: t("MEMBERS_INSURED") },
        { key: "commission", header: t("COMMISSION") },
      ];

      /* let fileData = xlsx.utils.sheet_to_json(
        workbook.Sheets[workbook.SheetNames[0]],
        { raw: false}
      );

      let ds2 = Object.keys(fileData.at(0) as any); */

      var headers = [];
      var sheet = workbook.Sheets[workbook.SheetNames[0]];

      var range = xlsx.utils.decode_range(sheet['!ref']!);
      var C, R = range.s.r; /* start in the first row */
      /* walk every column in the range */
      for(C = range.s.c; C <= range.e.c; ++C) {
          var cell = sheet[xlsx.utils.encode_cell({c:C, r:R})] /* find the cell in the first row */

          var hdr = "UNKNOWN " + C; // <-- replace with your desired default 
          if(cell && cell.t) hdr = xlsx.utils.format_cell(cell);

          headers.push(hdr);
      }

      let matches = findBestMatches(requiredFields.map(x => x.key), headers);

      let format:any;
      
      if (selectedCarrierCommissionStatementMap.length === 0) {
        format = requiredFields.map((x) => {
          return {
            ...x,
            match: matches[x.key],
          };
        });
      } else {
        format = selectedCarrierCommissionStatementMap;
      }

      setSelectedFile({
        file: file,
        format: format,
        fileColumns: headers,
      });

      openFilePreviewModal();
    };

    reader.readAsArrayBuffer(file);
  };

  const getPaginationOptions = useMemo(() => {
    return {
      page: paginatedOptions.page,
      pageSize: paginatedOptions.pageSize,
      totalPages: paginatedOptions.totalPages,
      totalCount: paginatedOptions.totalCount,
      setPage: (value: number) => {
        value < paginatedOptions.page
          ? dispatch(pastPage())
          : dispatch(
              getComissionsReport({
                agencyId: currentUser.agency_id,
                billingPeriodId: +selectedBillingPeriod,
                page: value,
                pageSize: paginatedOptions.pageSize,
                status: selectedStatus,
              })
            );
      },
      setPageSize: (value: number) =>
        dispatch(
          getComissionsReport({
            agencyId: currentUser.agency_id,
            billingPeriodId: +selectedBillingPeriod,
            page: 0,
            pageSize: value,
            status: selectedStatus,
          })
        ),
    };
  }, [dispatch, paginatedOptions]);

  return (
    <>
      {isLoading && <LoadingMask />}
      {addFilePreviewModal()}
      {addReviewUnmatchedPolicyModal()}
      <ToastContainer progressStyle={{ background: "#D4AF37" }} />
      <FilteredTable
        columns={selectedStatus == "unmatched" ? unmatchedColumns : columns}
        data={pageCommissions}
        isPaginated
        paginationOptions={getPaginationOptions}
        secondRow={
          <div className="flex flex-row w-full justify-end">
            <FormSelect
              className="md:w-1/4"
              label={t("BILLING_PERIOD")}
              name=""
              options={billingPeriods.map((x: any) => ({
                value: x.id,
                label: moment({ month: x.month, year: x.year }).format(
                  "MMMM, yyyy"
                ),
              }))}
              selectedValue={selectedBillingPeriod}
              handleOnChange={(e) => {
                setSelectedBillingPeriod(e.target.value);
              }}
            />

            <FormSelect
              className="md:w-1/4"
              label={t("STATUS")}
              name=""
              options={[
                { value: "confirmed", label: t("CONFIRMED") },
                { value: "unmatched", label: t("PENDING_REVIEW") },
              ]}
              selectedValue={selectedStatus}
              handleOnChange={(e) => {
                setSelectedStatus(e.target.value);
              }}
            />

          <Button classNames="mt-8" onClick={() => {
            if (selectedBillingPeriod !== "" && (selectedStatus === "unmatched" || selectedStatus === "confirmed")) {
              dispatch(
                getComissionsReport({
                  agencyId: currentUser.agency_id,
                  billingPeriodId: +selectedBillingPeriod,
                  pageSize: paginatedOptions.pageSize,
                  page: 0,
                  status: selectedStatus,
                })
              );
            }
          }}>
            <span className="flex items-center font-semibold pr-3">
              <div className="w-8 p-1 aspect-square mr-2">
                <DocumentIcon />
              </div>
              {t("GET_REPORT")}
            </span>
          </Button>
          </div>
        }
        buttonAction={
          <Button onClick={() => openFilePreviewModal()}>
            <span className="flex items-center font-semibold pr-3">
              <div className="w-8 p-1 aspect-square mr-2">
                <DocumentArrowUpIcon />
              </div>
              {t("ADD_COMMISSION_STATEMENT")}
            </span>
          </Button>
        }
        secondaryActionButton={
          <Button
            isTerciary={true}
            classNames="ml-3"
            onClick={exportReport}
            isDisabled={pageCommissions.length === 0 || isExporting}
          >
            <span className="flex items-center font-semibold pr-3">
              <div className="w-8 p-1 aspect-square mr-2">
                {isExporting ? <SpinCircle/> : <DocumentArrowDownIcon /> }
              </div>
              {t("DOWNLOAD_REPORT")}
            </span>
          </Button>
        }
      />
    </>
  );
};
