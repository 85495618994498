import { PlusIcon, TrashIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import { t } from "i18next";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { AgentsTwilioNumbers } from "../../models/User";
import { AppDispatch } from "../../state/store";
import {
  joinConference as joinConferenceAction,
  startConference,
} from "../../state/twilio/actions";
import Button from "../Button";
import { Modal } from "../Modal";
import { Toast } from "../Toast";
import { FormSearchableSelect } from "../form/FormSearchableSelect";
import { ReactiveFormPhoneInput } from "../form/ReactiveFormPhoneInput";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  callSid: string;
}

interface PhoneItem {
  id: string;
  value: string;
}

export const ConferenceModal = (props: Props) => {
  const [selectedAgentOnModal, setSelectedAgentOnModal] = useState<string>();
  const users = useSelector((state: any) => state.users);
  const [selectedPhones, setSelectedPhones] = useState<PhoneItem[]>([]);
  const [showError, setShowError] = useState(false);
  const twilio = useSelector((state: any) => state.twilio);
  const dispatch = useDispatch<AppDispatch>();

  const methods = useForm<{}>();
  const { handleSubmit, control, reset } = methods;

  useEffect(() => {
    if (showError) {
      Toast(
        t(twilio.error?.reason ?? "SOMETHING_WENT_WRONG"),
        t(twilio.error?.cause_info)
      );
      setShowError(false);
    }
  }, [showError, twilio.error]);

  const getAgents = useMemo(() => {
    return users.agentsTwilioNumbers
      ?.filter((x: AgentsTwilioNumbers) => x.agent_id !== users.currentUser?.id)
      .map((user: AgentsTwilioNumbers) => {
        return {
          label: `${user.first_name} ${user.last_name} (${user.twilio_phone})`,
          number: user.twilio_phone,
          value: `${user.twilio_phone}`,
        };
      });
  }, [users]);

  const closeConferenceModal = () => {
    setSelectedAgentOnModal("");
    setSelectedPhones([]);
    reset();
    props.onClose();
  };

  const handleSelectAgentOnModal = (agent: string) => {
    const phoneItem: PhoneItem = {
      id: crypto.randomUUID(),
      value: agent,
    };
    setSelectedAgentOnModal(agent);
    setSelectedPhones((prev) => [...prev, phoneItem]);
  };

  const onSubmit = (data: any) => {
    const phoneItem: PhoneItem = {
      id: crypto.randomUUID(),
      value: data.phone,
    };
    setSelectedPhones((prev) => [...prev, phoneItem]);
  };

  const handleRemovePhone = (index: number) => {
    const list = [...selectedPhones];
    list.splice(index, 1);
    setSelectedPhones(list);
  };

  const joinConference = () => {
    const participants = selectedPhones?.map((phone) => phone.value);
    if (twilio.clientConferenceId && twilio.clientConferenceId !== "") {
      dispatch(
        joinConferenceAction({
          conference_id: twilio.clientConferenceId,
          participants: participants,
        })
      ).then((e) => {
        if (e.type === "calls/joinConference/rejected") {
          setShowError(true);
        } else {
          props.onClose();
        }
      });
    } else {
      dispatch(
        startConference({ call_sid: props.callSid, participants: participants })
      ).then((e) => {
        if (e.type === "calls/startConference/rejected") {
          setShowError(true);
        } else {
          props.onClose();
        }
      });
    }
  };

  return (
    <>
      {props.isOpen && (
        <Modal
          isOpen={props.isOpen}
          onClose={closeConferenceModal}
          label={t("CONFERENCE")}
          saveButton={{
            label: t("JOIN_CALL"),
            icon: <UserPlusIcon />,
            onClick: joinConference,
          }}
        >
          {/*(users.isLoading || twilio.isLoading) && <LoadingMask />*/}
          <div className="w-full grid grid-cols-5">
            <div className="col-span-5 md:col-span-3 mr-12">
              <div className="w-full">
                <div className="w-full text-center mb-4">
                  {t("SELECT_AGENT_OR_ENTER_PHONE_TO_CONFERENCE_CALL")}
                </div>
                <FormSearchableSelect
                  className="md:w-1/1"
                  value={selectedAgentOnModal ?? ""}
                  name="agent_number"
                  label={t("AGENTS")}
                  options={getAgents}
                  onChange={(val) => handleSelectAgentOnModal(val)}
                />
                <div className="w-full text-gray-700 text-sm mb-2 text-center">
                  {t("OR")}
                </div>
                <div className="flex md:w-1/1">
                  <ReactiveFormPhoneInput
                    control={control}
                    name="phone"
                    label={t("PHONE")}
                  />
                  <div className="self-end mb-3 mr-4">
                    <Button
                      tooltip={t("ADD_PHONE")}
                      iconOnly
                      classNames="h-10 w-10"
                      onClick={handleSubmit(onSubmit)}
                    >
                      <PlusIcon className="h-4 w-4 ml-1" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-5 md:col-span-2 pl-4 border-l-2">
              <div className="w-full text-center mb-6">
                {t("SELECTED_PHONES")}
              </div>
              <div className="w-full flex flex-col">
                {selectedPhones.map((phone, index) => {
                  return (
                    <Fragment key={phone.id}>
                      <div className="w-full flex justify-between px-4 mb-3">
                        <label>{phone.value}</label>
                        <Button
                          iconOnly
                          classNames="h-7 w-7 self-end"
                          onClick={() => handleRemovePhone(index)}
                          tooltip={t("REMOVE_PHONE")}
                        >
                          <TrashIcon className="h-3 w-3" />
                        </Button>
                      </div>
                    </Fragment>
                  );
                })}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
