import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { useEffect, useState } from "react";

interface Props {
    name: string
    value?: string | number
    label: string
    onClick?: () => void;
    handleOnChange?: (event: any) => void
    type?: string
    className?: string
    placeholder?: string
    error?:string
    isDisabled?:boolean
    onBlur?: (e: any) => void
}

export const FormLocalDateInput = (props: Props) => {

    const inputType = () => {
        return props.type ?? 'text'
    }

    return (
        <div className={"w-full px-3 " + props.className ?? ""}>
            <label className="block tracking-wide text-gray-700 text-sm mb-2">
                {props.label}
            </label>
            <input
                className={classNames({
                    "appearance-none block w-full border border-gray-200 text-gray-700 rounded px-4 mb-3 leading-tight focus:outline-none focus:border-blue": true,
                    "border-red-500": props.error,
                    "bg-slate-100": props.isDisabled == true,
                    "py-3": true
                })}
                type={inputType()}
                placeholder={props.placeholder ?? ""}
                name={props.name}
                onChange={props.handleOnChange}
                value={props.value}
                disabled={props.isDisabled}
                onClick={props.onClick}
                onBlur={props.onBlur}
            />
            {props.error && <p className="text-red-500 text-xs italic">{props.error}</p>}
        </div>

    );
};