export interface Reminder {
    id: number;
    message: string;
    date: Date;
    agentId: number;
    delete_at?: Date;
}

export interface EditReminderModal {
    id?: number;
    message?: string;
    date?: string;
    time?: string;
    agent?: number;
    is_to_myself?: string;
}

export const newReminderInitialValues = {
    is_to_myself: "1",
}

export interface EditReminder {
    id?: number;
    message?: string;
    date?: string;
    agent_id?: number
}