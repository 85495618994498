import { useTranslation } from "react-i18next";
import { EditPolicy, Policy } from "../../models/Policies";
import { SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { AppDispatch } from "../../state/store";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import { AgentCommissionRule } from "../../models/AgentCommissionRule";
import { toast, ToastContainer } from "react-toastify";
import { Modal } from "../../components/Modal";
import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import { ReactiveFormInput } from "../../components/form/ReactiveFormInput";
import { ReactiveFormSelect } from "../../components/form/ReactiveFormSelect";
import { getCarriers } from "../../enums/Carrier";
import { getPolicyStates } from "../../enums/PolicyState";
import { ReactiveFormRadioButtonSelect } from "../../components/form/ReactiveFormRadioButtonSelect";
import { RoleEnum } from "../../enums/Role";
import { User } from "../../models/User";
import { fetchAgencyUserCommissionRules } from "../../state/agencies/action";
import { updatePolicy } from "../../state/policies/actions";
import { Toast } from "../../components/Toast";
import { LoadingMask } from "../../components/LoadingMask";

interface Props {
  info: {
    show: boolean;
    policy?: any;
  };
  onClose: () => void;
}

export const PolicyUpdateModal: React.FC<Props> = ({ info, onClose }) => {
  const { t } = useTranslation();
  const [showUpdateError, setShowUpdateError] = useState(false);
  const [isPolicyUpdateLoading, setIsPolicyUpdateLoading] = useState(false);
  const errorUpdate = useSelector((state: any) => state.policies.errorUpdate);

  const dispatch = useDispatch<AppDispatch>();
  const currentUser = useSelector((state: any) => state.users.currentUser);
  const agents = useSelector((state: any) => state.users.users);

  const [commissionRules, setCommissionRules] = useState<AgentCommissionRule[]>(
    []
  );
  const [selectedCommissionRule, setSelectedCommissionRule] = useState<
    AgentCommissionRule | undefined
  >(undefined);
  const [loadingRules, setLoadingRules] = useState(false);

  const methods = useForm<Policy>({
    defaultValues: {},
  });
  const { handleSubmit, control, getValues, reset } = methods;

  useEffect(() => {
    if (showUpdateError) {
      Toast(
        t(errorUpdate?.reason ?? "SOMETHING_WENT_WRONG"),
        t(errorUpdate?.cause_info)
      );
      setShowUpdateError(false);
    }
  }, [showUpdateError, errorUpdate, t]);

  useEffect(() => {
    info.policy ? reset(info.policy) : reset({});

    if (info.policy && info.policy.agent_id) {
      if (agents.find((x: any) => x.id === info.policy!.agent_id)) {
        setLoadingRules(true);
        dispatch(
          fetchAgencyUserCommissionRules({
            agencyId: currentUser.agency_id,
            userId: +info.policy.agent_id!,
          })
        ).then((e) => {
          let rules = (e.payload || []) as AgentCommissionRule[];
          setCommissionRules(rules);

          if (info.policy?.agent_commission_rule_id) {
            setSelectedCommissionRule(
              rules.find(
                (x: AgentCommissionRule) =>
                  x.id === info.policy?.agent_commission_rule_id
              )
            );
          }

          setLoadingRules(false);
        });
      } else {
        info.policy.agent_id = undefined;
      }
    }
  }, [info.policy, reset]);

  const onSubmitInvalid: SubmitErrorHandler<EditPolicy> = (data) => {
    toast(t("PLEASE_ENTER_ALL_POLICY_REQUIRED_FIELDS"));
  };

  const onSubmit: SubmitHandler<any> = async (data: any) => {
    if (checkPolicyMembers(data)) {
      Toast(t("POLICY_MEMBERS_ERROR"), t("POLICY_MEMBERS_MESSAGE"));
    } else {
      setIsPolicyUpdateLoading(true);

      const editPolicy: EditPolicy = {
        id: data.id,
        carrier_id: data.carrier_id,
        number: data.number,
        effective_start_date: data.effective_start_date,
        effective_end_date: data.effective_end_date,
        members_insured: data.members_insured,
        free_plan: data.free_plan,
        policy_state: data.policy_state,
        agent_id: data.agent_id,
        agent_commission_rule_id: data.agent_commission_rule_id,
      };

      dispatch(updatePolicy({ policy: editPolicy })).then((e) => {
        if (e.type === "policies/update/rejected") {
          setShowUpdateError(true);
          setIsPolicyUpdateLoading(false);
        } else {
          toast(t("SAVED_SUCCESFULLY"));
          setIsPolicyUpdateLoading(false);
          closeModal();
          //navigate(ClientsPath)
        }
      });
    }
  };

  const checkPolicyMembers = (data: any) => {
    let policiesMembers = 0;

    if (data.members_insured != undefined) {
      if (data.members_insured <= 0) {
        policiesMembers++;
      }
    } else {
      policiesMembers++;
    }

    return policiesMembers > 0;
  };

  const closeModal = () => {
    reset();
    setSelectedCommissionRule(undefined);
    setCommissionRules([]);
    onClose();
  };

  return (
    <>
      <ToastContainer progressStyle={{ background: "#D4AF37" }} />
      <Modal
        isOpen={info.show}
        onClose={closeModal}
        label={t("UPDATE_POLICY")}
        saveButton={{
          label: t("SAVE"),
          icon: <PaperAirplaneIcon />,
          onClick: handleSubmit(onSubmit, onSubmitInvalid),
        }}
      >
        {isPolicyUpdateLoading && <LoadingMask />}
        <div className="w-full flex flex-wrap">
          <Fragment>
            <ReactiveFormInput
              control={control}
              className="md:w-1/3"
              label={t("POLICY_NUMBER")}
              name={`number`}
            />
            <ReactiveFormSelect
              className="md:w-1/3"
              name={`carrier_id`}
              label={t("CARRIER")}
              options={getCarriers()}
              control={control}
              isRequired
            />
            <ReactiveFormInput
              control={control}
              className="md:w-1/3"
              label={t("CLIENT")}
              name={`client`}
              isDisabled={true}
            />
{/*             <ReactiveFormInput
              control={control}
              className="md:w-1/3"
              label={t("AGENT")}
              name={`agent`}
              isDisabled={true}
            /> */}
            <ReactiveFormInput
              control={control}
              type="date"
              className="md:w-1/3"
              label={t("START_DATE")}
              name={`effective_start_date`}
              isRequired
            />
            <ReactiveFormInput
              control={control}
              type="date"
              className="md:w-1/3"
              label={t("END_DATE")}
              name={`effective_end_date`}
            />
            <ReactiveFormInput
              control={control}
              className="md:w-1/3"
              label={t("MEMBERS_INSURED")}
              name={`members_insured`}
              type="number"
              isRequired
            />
            <ReactiveFormSelect
              className="md:w-1/3"
              name={`policy_state`}
              label={t("STATUS")}
              options={getPolicyStates()}
              control={control}
              isRequired
            />
            <div className="flex md:w-1/2">
              <ReactiveFormRadioButtonSelect
                className="mr-2"
                control={control}
                name={`free_plan`}
                label={t("FREE_PLAN")}
                options={[
                  { label: t("YES"), value: "1" },
                  { label: t("NO"), value: "0" },
                ]}
              />
            </div>
          </Fragment>
          {currentUser?.role_id === RoleEnum.SUPER_ADMIN && (
            <Fragment>
              <ReactiveFormSelect
                className="md:w-1/3"
                name={`agent_id`}
                label={t("AGENT")}
                options={agents.map((x: User) => {
                  return {
                    label: `${x.first_name} ${x.last_name}`,
                    value: `${x.id}`,
                  };
                })}
                control={control}
                handleOnChange={(x: any) => {
                  methods.resetField("agent_commission_rule_id");
                  setSelectedCommissionRule(undefined);
                  setLoadingRules(true);
                  dispatch(
                    fetchAgencyUserCommissionRules({
                      agencyId: currentUser.agency_id,
                      userId: +x.target.value,
                    })
                  ).then((e) => {
                    setCommissionRules(
                      (e.payload || []) as AgentCommissionRule[]
                    );
                    setLoadingRules(false);
                  });
                }}
                isRequired
              />

              <ReactiveFormSelect
                className="md:w-1/3"
                name={`agent_commission_rule_id`}
                label={t("AGENT_COMMISSION_RULE")}
                options={commissionRules.map((r: AgentCommissionRule) => {
                  return {
                    label: `${r.name} - ${
                      r.is_relative ? r.value! * 100 + "%" : "$" + r.value
                    }`,
                    value: `${r.id}`,
                  };
                })}
                control={control}
                isDisabled={
                  methods.getValues("agent_id") === undefined || loadingRules
                }
                isRequired
                handleOnChange={(e: any) =>
                  setSelectedCommissionRule(
                    commissionRules.find(
                      (x: AgentCommissionRule) => x.id === +e.target.value
                    )
                  )
                }
              />

              {selectedCommissionRule !== undefined && (
                <p className="text-slate-500 text-xs px-3">
                  {selectedCommissionRule.description}
                </p>
              )}
            </Fragment>
          )}

          {currentUser?.role_id !== RoleEnum.SUPER_ADMIN && (
            <Fragment>
              <ReactiveFormSelect
                className="md:w-1/3"
                name={`agent_id`}
                label={t("AGENT")}
                options={agents.map((x: User) => {
                  return {
                    label: `${x.first_name} ${x.last_name}`,
                    value: `${x.id}`,
                  };
                })}
                control={control}
                isDisabled
              />

              <ReactiveFormSelect
                className="md:w-1/3"
                name={`agent_commission_rule_id`}
                label={t("AGENT_COMMISSION_RULE")}
                options={commissionRules.map((r: AgentCommissionRule) => {
                  return {
                    label: `${r.name} - ${
                      r.is_relative ? r.value! * 100 + "%" : "$" + r.value
                    }`,
                    value: `${r.id}`,
                  };
                })}
                control={control}
                isDisabled
              />

              {selectedCommissionRule !== undefined && (
                <p className="text-slate-500 text-xs px-3">
                  {selectedCommissionRule.description}
                </p>
              )}
            </Fragment>
          )}
        </div>
      </Modal>
    </>
  );
};
