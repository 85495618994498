import {
  ArrowDownTrayIcon,
  ArrowLeftIcon,
  CheckIcon,
  DocumentArrowDownIcon,
  NoSymbolIcon,
  PaperAirplaneIcon,
  PlusIcon,
  RectangleStackIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import moment from "moment";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import {
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import "react-phone-input-2/lib/style.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import states from "states-us";
import { Accordion } from "../../components/Accordion";
import Button from "../../components/Button";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { LoadingMask } from "../../components/LoadingMask";
import { Modal } from "../../components/Modal";
import { SuccessErrorModal } from "../../components/SuccessErrorModal";
import Table from "../../components/Table";
import { Toast } from "../../components/Toast";
import { ClientPoliciesTable } from "../../components/clients/ClientPoliciesTable";
import { ClientPolicyModal } from "../../components/clients/ClientPolicyModal";
import { Form } from "../../components/form/Form";
import { ReactiveFormInput } from "../../components/form/ReactiveFormInput";
import { ReactiveFormInputArea } from "../../components/form/ReactiveFormInputArea";
import { ReactiveFormPhoneInput } from "../../components/form/ReactiveFormPhoneInput";
import { ReactiveFormRadioButton } from "../../components/form/ReactiveFormRadioButton";
import { ReactiveFormRadioButtonText } from "../../components/form/ReactiveFormRadioButtonText";
import { ReactiveFormSMSelect } from "../../components/form/ReactiveFormSMSelect";
import { ReactiveFormSelect } from "../../components/form/ReactiveFormSelect";
import { ReactiveFormUploadFile } from "../../components/form/ReactiveFormUploadFile";
import { ClientsPath } from "../../constants/Routes";
import {
  ClientFormSectionEnum,
  getClientFormSections,
} from "../../enums/ClientFormSection";
import { getEmployeeTypes } from "../../enums/EmployeerType";
import { getGenders } from "../../enums/Gender";
import { getImmigrationTypes } from "../../enums/ImmigrationStatusType";
import { getMaritalStatuses } from "../../enums/MaritalStatus";
import { MessageTypeEnum } from "../../enums/MessageType";
import { getRelationships } from "../../enums/Relationship";
import { RoleEnum } from "../../enums/Role";
import { getCountries } from "../../i18n/countries";
import { newAddressInitialValues } from "../../models/Address";
import { Client, EditClient } from "../../models/Client";
import { ClientNote, NewClientNote } from "../../models/ClientNotes";
import {
  DocumentEnvelope,
  DocumentTemplate,
  NewSendDocument,
  NewSendDocumentAgent,
  NewSendDocumentClient,
  VoidDocumentData,
} from "../../models/ConsentDocument";
import { newDependentInitialValues } from "../../models/Dependant";
import { NewDocument } from "../../models/Document";
import { newIncomeSourceInitialValues } from "../../models/IncomeSource";
import { EditPolicy, NewPolicy } from "../../models/Policies";
import { Tag } from "../../models/Tags";
import { fetchAddressesByClientId } from "../../state/addresses/actions";
import {
  createClientNote,
  fetchNotesByClientId,
} from "../../state/clientNotes/actions";
import { updateClient } from "../../state/clients/actions";
import {
  fetchDocumentEnvelopes,
  getEnvelopeDownloadDocument,
  sendClientDocument,
  voidClientDocument,
} from "../../state/consentDocuments/actions";
import { fetchDependentsByClientId } from "../../state/dependents/actions";
import {
  fetchAttachmentsByClientId,
  getFileDownloadUrl,
  getFileSignedUrl,
} from "../../state/documents/actions";
import { fetchIncomeSourcesByClientId } from "../../state/incomeSources/actions";
import { fetchPoliciesByClientId } from "../../state/policies/actions";
import { AppDispatch } from "../../state/store";
import { fetchTagResponses } from "../../state/tagResponses/action";
import {
  actualDateFormat,
  dateFormat,
  longLocalDateFormat,
  shortDateFormat,
} from "../../utils/functions";

const initialOpenedSections: Map<ClientFormSectionEnum, boolean> = new Map();

export const UpdateClient = () => {
  const location = useLocation();
  const client = location.state.client;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [templates, setTemplates] = useState<DocumentTemplate[]>([]);
  const [envelopes, setEnvelopes] = useState<DocumentEnvelope[]>([]);
  const [isSendDocumentModalOpen, setIsSendDocumentModalOpen] = useState(false);
  const [isVoidDocumentModalOpen, setIsVoidDocumentModalOpen] = useState(false);
  const [openedSections, setOpenedSections] = useState<
    Map<ClientFormSectionEnum, boolean>
  >(initialOpenedSections);
  const dispatch = useDispatch<AppDispatch>();
  const incomeSourcesData = useSelector(
    (state: any) => state.incomeSources.incomeSources
  );
  const addressesData = useSelector((state: any) => state.addresses.addresses);
  const policiesData = useSelector(
    (state: any) => state.policies.clientPolicies
  );
  const dependentsData = useSelector(
    (state: any) => state.dependents.dependents
  );
  const tagResponsesData = useSelector(
    (state: any) => state.tagResponses.responses
  );
  const clientNotesData = useSelector((state: any) => state.clientNotes);
  const documentsData = useSelector(
    (state: any) => state.documents.attachments
  );
  const isIncomeSourceLoading = useSelector(
    (state: any) => state.incomeSources.isLoading
  );
  const isAddressLoading = useSelector(
    (state: any) => state.addresses.isLoading
  );
  const isClientLoading = useSelector((state: any) => state.clients.isLoading);
  const isPolicyLoading = useSelector((state: any) => state.policies.isLoading);
  const isDependentLoading = useSelector(
    (state: any) => state.dependents.isLoading
  );
  const isTagResponseLoading = useSelector(
    (state: any) => state.tagResponses.isLoading
  );
  const isDocumentLoading = useSelector(
    (state: any) => state.documents.isLoading
  );
  const currentUser = useSelector((state: any) => state.users.currentUser);
  const clients = useSelector((state: any) => state.clients.clients);
  const isEnvelopeLoading = useSelector(
    (state: any) => state.consentDocuments.isLoading
  );
  const isDownloadLoading = useSelector(
    (state: any) => state.consentDocuments.isLoadingDownload
  );
  const templatesData = useSelector((state: any) => state.templates.templates);
  const envelopesData = useSelector(
    (state: any) => state.consentDocuments.envelopes
  );
  const [isSendDocumentLoading, setIsSendDocumentLoading] = useState(false);
  const [isVoidDocumentLoading, setIsVoidDocumentLoading] = useState(false);
  const tags = useSelector((state: any) => state.tags.tags);
  const [agentTags, setAgentTags] = useState<Tag[]>([]);
  const error = useSelector((state: any) => state.clients.error);
  const [showClientNotesError, setShowClientNotesError] = useState(false);
  const [showCreateError, setShowCreateError] = useState(false);
  const errorSendDocument = useSelector(
    (state: any) => state.consentDocuments.error
  );
  const [showSendError, setShowSendError] = useState(false);
  const [selectedDocument, setSelectedDocument] =
    useState<DocumentEnvelope | null>(null);
  const users = useSelector((state: any) => state.users.users);
  const [newClientDocuments, setNewClientDocuments] = useState<any[]>([]);
  const [isUploadingFiles, setIsUploadingFiles] = useState(false);
  const [isDownloadingFile, setIsDownloadingFile] = useState(false);
  const [showClientPolicyModal, setShowClientPolicyModal] = useState<{
    show: boolean;
    update: boolean;
    policy?: EditPolicy;
    index?: number;
  }>({ show: false, update: false });

  const [showSuccessErrorModal, setShowSuccessErrorModal] = useState<{
    show: boolean;
    messageType: number;
    message: string;
  }>({ show: false, messageType: 2, message: "" });

  const [editClientContinueData, setEditClientContinueData] =
    useState<EditClient>();
  const [isWRenewalConfirmationModalOpen, setIsWRenewalConfirmationModalOpen] =
    useState(false);
  let hasToSendWRenewalMessage = false;

  useEffect(() => {
    if (showCreateError) {
      handleShowSuccessErrorModal(
        MessageTypeEnum.FAILED,
        t(error?.cause_info ?? "SOMETHING_WENT_WRONG")
      );
    }
  }, [showCreateError, error, t]);

  useEffect(() => {
    if (showClientNotesError) {
      Toast(
        t(clientNotesData?.error?.reason ?? "SOMETHING_WENT_WRONG"),
        t(clientNotesData?.error?.cause_info)
      );
      setShowClientNotesError(false);
    }
  }, [showClientNotesError, clientNotesData?.error, t]);

  const methods = useForm<EditClient>({
    defaultValues: client,
  });
  const { handleSubmit, control, setValue, getValues, reset } = methods;

  const methodsNotes = useForm<NewClientNote>({
    defaultValues: {
      note: "",
    },
  });
  const { control: notesControl, reset: notesRest, watch } = methodsNotes;

  const {
    fields: dependents,
    append: appendDependent,
    remove: removeDependent,
  } = useFieldArray({
    control,
    name: "dependents",
  });

  const {
    fields: addresses,
    append: appendAddress,
    remove: removeAddress,
  } = useFieldArray({
    control,
    name: "addresses",
  });

  const {
    fields: incomeSources,
    append: appendIncomeSource,
    remove: removeIncomeSource,
  } = useFieldArray({
    control,
    name: "income_sources",
  });

  const {
    fields: policies,
    append: appendPolicy,
    remove: removePolicy,
  } = useFieldArray({
    control,
    name: "policies",
  });

  const {
    fields: attachments,
    append: appendAttachment,
    remove: removeAttachment,
  } = useFieldArray({
    control,
    name: "attachments",
  });

  const sendMethods = useForm<any>({
    defaultValues: {},
  });
  const {
    handleSubmit: handleSendSubmit,
    control: sendControl,
    setValue: setSendValue,
  } = sendMethods;

  const voidMethods = useForm<any>({
    defaultValues: {},
  });
  const {
    handleSubmit: handleVoidSubmit,
    control: voidControl,
    setValue: setVoidValue,
  } = voidMethods;

  const updatePolicy = (index: number, policy: EditPolicy) => {
    setValue(`policies.${index}.number`, policy.number);
    setValue(`policies.${index}.carrier_id`, policy.carrier_id);
    setValue(`policies.${index}.effective_end_date`, policy.effective_end_date);
    setValue(
      `policies.${index}.effective_start_date`,
      policy.effective_start_date
    );
    setValue(`policies.${index}.free_plan`, policy.free_plan);
    setValue(`policies.${index}.is_new`, policy.is_new);
    setValue(`policies.${index}.members_insured`, policy.members_insured);
    setValue(`policies.${index}.policy_state`, policy.policy_state);
    setValue(`policies.${index}.agent_id`, policy.agent_id);
    setValue(
      `policies.${index}.agent_commission_rule_id`,
      policy.agent_commission_rule_id
    );
    reset(getValues());
  };

  useEffect(() => {
    if (showSendError) {
      Toast(
        t(errorSendDocument?.reason ?? "SOMETHING_WENT_WRONG"),
        t(errorSendDocument?.cause_info)
      );
      setShowSendError(false);
    }
  }, [showSendError, errorSendDocument, t]);

  useEffect(() => {
    if (currentUser) {
      setValue("agent_id", currentUser.id);
    }
  }, [currentUser, setValue]);

  useEffect(() => {
    if (currentUser) {
      dispatch(
        fetchIncomeSourcesByClientId({
          agencyId: currentUser.agency_id,
          clientId: client.id,
        })
      );
      dispatch(
        fetchAddressesByClientId({
          agencyId: currentUser.agency_id,
          clientId: client.id,
        })
      );
      dispatch(
        fetchPoliciesByClientId({
          agencyId: currentUser.agency_id,
          clientId: client.id,
        })
      );
      dispatch(
        fetchDependentsByClientId({
          agencyId: currentUser.agency_id,
          clientId: client.id,
        })
      );
      dispatch(fetchDocumentEnvelopes(client.id));
      dispatch(
        fetchTagResponses({
          agencyId: currentUser.agency_id,
          clientId: client.id,
        })
      );
      dispatch(fetchNotesByClientId({ clientId: client.id }));
      dispatch(fetchAttachmentsByClientId({ clientId: client.id }));
    }
  }, [client, dispatch, currentUser]);

  useEffect(() => {
    incomeSourcesData && incomeSourcesData.length > 0
      ? setValue("income_sources", incomeSourcesData)
      : setValue("income_sources", [newIncomeSourceInitialValues]);
    addressesData && addressesData.length > 0
      ? setValue("addresses", addressesData)
      : setValue("addresses", [newAddressInitialValues]);
    dependentsData && dependentsData.length > 0
      ? setValue("dependents", dependentsData)
      : setValue("dependents", [newDependentInitialValues]);
    setValue("policies", policiesData);
    documentsData && documentsData.length > 0
      ? setValue("attachments", documentsData)
      : setValue("attachments", []);
  }, [
    setValue,
    incomeSourcesData,
    addressesData,
    policiesData,
    dependentsData,
    documentsData,
  ]);

  useEffect(() => {
    setEnvelopes(envelopesData);
    setTemplates(templatesData);
  }, [envelopesData, templatesData]);

  useEffect(() => {
    if (currentUser) {
      if (currentUser.role_id === RoleEnum.ADMIN) {
        const aTags = tags.filter(
          (tag: any) => tag.user.id === currentUser.id || !tag.is_personal
        );
        setAgentTags(aTags);

        const initalTags = tags.map((tag: any) => {
          const res = tagResponsesData.find(
            (tResp: any) => tResp.tag.id === tag.id
          );
          return {
            id: res !== undefined ? res.id : null,
            tag_id: tag.id,
            response: res !== undefined ? res.response : "",
          };
        });
        setValue("tags_responses", initalTags);
      } else {
        setAgentTags(tags);

        const initalTags = tags.map((tag: any) => {
          const res = tagResponsesData.find(
            (tResp: any) => tResp.tag.id === tag.id
          );
          return {
            id: res !== undefined ? res.id : null,
            tag_id: tag.id,
            response: res !== undefined ? res.response : "",
          };
        });
        setValue("tags_responses", initalTags);
      }
    }
  }, [currentUser, setValue, tags, setAgentTags, tagResponsesData]);

  const openSendDocumentModal = () => {
    setIsSendDocumentModalOpen(true);
  };

  const closeSendDocumentModal = () => {
    setIsSendDocumentModalOpen(false);
  };

  const openVoidDocumentModal = (envelope: DocumentEnvelope) => {
    setSelectedDocument(envelope);
    setIsVoidDocumentModalOpen(true);
  };

  const closeVoidDocumentModal = () => {
    setSelectedDocument(null);
    setIsVoidDocumentModalOpen(false);
  };

  const downloadEnvelopeDocument = (envelope: DocumentEnvelope) => {
    //setSelectedEnvelope(envelope);
    //setIsShowDocumentModalOpen(true);
    dispatch(getEnvelopeDownloadDocument(envelope.docusign_envelope_id)).then(
      async (e: any) => {
        if (e.type === "getDownloadDocument/rejected") {
          toast(t("SOMETHING_WENT_WRONG"));
        } else {
          const data = e.payload.download_documents;
          const nameSplit = envelope.template.document_name.split(".");
          const filename = `${nameSplit[0]} - ${client.first_name} ${client.last_name}.pdf`;

          var binaryLen = data.length;
          var bytes = new Uint8Array(binaryLen);
          for (var i = 0; i < binaryLen; i++) {
            var ascii = data.charCodeAt(i);
            bytes[i] = ascii;
          }

          var blob = new Blob([bytes], { type: "application/pdf" });
          var link = window.URL.createObjectURL(blob);

          const aElement = document.createElement("a");
          document.body.appendChild(aElement);
          aElement.style.display = "none";
          aElement.href = link;
          aElement.download = filename;
          aElement.click();
          document.body.removeChild(aElement);
        }
      }
    );
  };

  const sendDocumentModal = () => {
    return (
      <Modal
        isOpen={isSendDocumentModalOpen}
        onClose={closeSendDocumentModal}
        label={t("SEND_DOCUMENT")}
        saveButton={{
          label: t("SEND"),
          icon: <PaperAirplaneIcon />,
          onClick: handleSendSubmit(onSendDocument),
        }}
      >
        {isSendDocumentLoading && <LoadingMask />}
        <div className="w-full flex flex-wrap">
          <ReactiveFormRadioButtonText
            className={"md:w-1/1"}
            name="sending_method"
            label={t("DELIVERY_METHOD")}
            control={sendControl}
            isRequired
            options={[
              { label: t("EMAIL"), value: "email" },
              { label: t("SMS"), value: "sms" },
              { label: t("WHATSAPP"), value: "whatsapp" },
            ]}
          />
          <ReactiveFormSelect
            className="md:w-1/2"
            name="template_id"
            label={t("TEMPLATE")}
            control={sendControl}
            options={getTemplates}
            isRequired
          />
          <ReactiveFormInput
            className="md:w-1/2"
            control={sendControl}
            name="requested_date"
            type="date"
            label={t("EFFECTIVE_DATE_REQUESTED")}
            isRequired
          />
          {currentUser?.role_id !== RoleEnum.AGENT && (
            <ReactiveFormSMSelect
              isMulti={false}
              className={"md:w-1/2"}
              selectClassName={"basic-single mb-3"}
              name="agent"
              label={t("AGENT")}
              control={sendControl}
              options={agents}
              menuPosition={"fixed"}
              placeholder={"Select agents"}
            />
          )}
        </div>
      </Modal>
    );
  };

  const onSendDocument = (data: any) => {
    if (data.template_id === undefined || data.template_id === null) {
      toast(t("PLEASE_SELECT_A_TEMPLATE"));
    } else if (
      data.requested_date === undefined ||
      data.requested_date === null
    ) {
      toast(t("PLEASE_SET_EFFECTIVE_DATE_REQUESTED"));
    } else {
      setIsSendDocumentLoading(true);

      let template = templates.find(
        (tem) => tem.id === Number(data.template_id!)
      );
      const agentData: NewSendDocumentAgent = {};

      if (currentUser?.role_id !== RoleEnum.AGENT) {
        if (
          data.agent === null ||
          data.agent === undefined ||
          data.agent === 0
        ) {
          let agentName = currentUser.first_name + " " + currentUser.last_name;

          agentData.id = currentUser.id;
          agentData.name = agentName.trim();
          agentData.email = currentUser.email;
          agentData.phone = currentUser.phone;
          agentData.npn = currentUser.national_producer_number;
        } else {
          let agentSelect = users.find((a: any) => a.id === data.agent);
          if (agentSelect !== undefined) {
            let agentName =
              agentSelect.first_name + " " + agentSelect.last_name;

            agentData.id = agentSelect.id;
            agentData.name = agentName.trim();
            agentData.email = agentSelect.email;
            agentData.phone = agentSelect.phone;
            agentData.npn = agentSelect.national_producer_number;
          } else {
            let agentName =
              currentUser.first_name + " " + currentUser.last_name;

            agentData.id = currentUser.id;
            agentData.name = agentName.trim();
            agentData.email = currentUser.email;
            agentData.phone = currentUser.phone;
            agentData.npn = currentUser.national_producer_number;
          }
        }
      } else {
        let agentName = currentUser.first_name + " " + currentUser.last_name;

        agentData.id = currentUser.id;
        agentData.name = agentName.trim();
        agentData.email = currentUser.email;
        agentData.phone = currentUser.phone;
        agentData.npn = currentUser.national_producer_number;
      }

      let name = client.first_name;
      if (client.middle_name !== undefined) {
        name = name + " " + client.middle_name;
      }
      name = name + " " + client.last_name;
      if (client.second_last_name) {
        name = name + " " + client.second_last_name;
      }

      const clientData: NewSendDocumentClient = {
        id: client.id,
        name: name.trim(),
        email: client.email,
        birthday: dateFormat(client.date_of_birth),
        phone: client.phone,
      };

      const sendDocumentData: NewSendDocument = {
        client: clientData,
        agent: agentData,
        requested_date: dateFormat(data.requested_date!),
        template_id: Number(data.template_id!),
        template_docusign_id: template!.docusign_template_id,
        sending_method: data.sending_method ?? "email",
        document_url: template!.document_url,
        document_name: template!.document_name,
        template_language: template!.language,
        date: actualDateFormat(),
      };

      dispatch(sendClientDocument({ data: sendDocumentData })).then((e) => {
        if (e.type === "sendClientDocument/rejected") {
          setShowSendError(true);
          setIsSendDocumentLoading(false);
        } else {
          toast(t("SAVED_SUCCESFULLY"));
          setIsSendDocumentLoading(false);
          setIsSendDocumentModalOpen(false);
        }
      });
    }
  };

  const voidDocumentModal = () => {
    return (
      <Modal
        isOpen={isVoidDocumentModalOpen}
        onClose={closeVoidDocumentModal}
        label={t("VOID_DOCUMENT")}
        saveButton={{
          label: t("VOID"),
          icon: <NoSymbolIcon />,
          onClick: handleVoidSubmit(onVoidDocument),
        }}
      >
        {isVoidDocumentLoading && <LoadingMask />}
        <div className="w-full flex flex-wrap">
          <ReactiveFormInputArea
            className="md:w-1/1"
            control={voidControl}
            name="void_reason"
            label={t("VOID_DOCUMENT_MESSAGE")}
            isRequired
          />
        </div>
      </Modal>
    );
  };

  const onVoidDocument = (data: any) => {
    if (
      data.void_reason == null ||
      data.void_reason == undefined ||
      data.void_reason == ""
    ) {
      toast(t("VOID_CONSENT_DOCUMENT_MESSAGE"));
    } else {
      setIsVoidDocumentLoading(true);

      const voidData: VoidDocumentData = {
        docusign_envelope_id: selectedDocument!.docusign_envelope_id,
        void_reason: data.void_reason,
      };

      dispatch(
        voidClientDocument({
          documentId: selectedDocument!.id,
          clientId: client.id,
          data: voidData,
        })
      ).then((e) => {
        if (e.type === "voidClientDocument/rejected") {
          setShowSendError(true);
          setIsVoidDocumentLoading(false);
        } else {
          toast(t("SAVED_SUCCESFULLY"));
          setIsVoidDocumentLoading(false);
          closeVoidDocumentModal();
        }
      });
    }
  };

  const saveNote = () => {
    dispatch(
      createClientNote({
        clietnNote: { note: `${watch("note")}` },
        clientId: client.id,
      })
    ).then((e) => {
      if (e.type === "clientNotes/create/rejected") {
        setShowClientNotesError(true);
      } else {
        toast(t("SAVED_SUCCESFULLY"));
        notesRest();
      }
    });
  };

  const getTemplates = useMemo(() => {
    return templates.map((template: DocumentTemplate) => {
      return {
        label: `${template.name}`,
        value: `${template.id}`,
        key: template.id,
      };
    });
  }, [templates]);

  const getDocumentEnvelopes = useMemo(() => {
    return envelopes?.map((envelope: DocumentEnvelope) => {
      let status = envelope.status;
      if (status === "completed") {
        status = status + " (" + t("SIGNED") + ")";
      } else {
        status = status + " (" + t("NO_SIGNED") + ")";
      }

      return {
        columns: [
          { value: envelope.template.name },
          { value: envelope.agent.name },
          {
            value:
              envelope.sent_date_time != null
                ? longLocalDateFormat(envelope.sent_date_time!)
                : "-",
          },
          {
            value:
              envelope.completed_date_time != null
                ? longLocalDateFormat(envelope.completed_date_time!)
                : "-",
          },
          { value: status, className: "capitalize" },
          {
            value: (
              <div className="flex flex-row justify-center divide-x">
                <Button
                  isTerciary
                  iconOnly
                  tooltip={t("DOWNLOAD_DOCUMENT")}
                  onClick={() => downloadEnvelopeDocument(envelope)}
                  classNames="mr-2"
                >
                  <DocumentArrowDownIcon className="h-5 aspect-square stroke-blue" />
                </Button>
                <Button
                  isTerciary
                  iconOnly
                  isDisabled={
                    envelope.status === "completed" ||
                    envelope.status === "voided"
                  }
                  tooltip={t("VOID_DOCUMENT")}
                  onClick={() => openVoidDocumentModal(envelope)}
                  classNames="mx-2"
                >
                  <NoSymbolIcon className="h-5 aspect-square stroke-red-600" />
                </Button>
              </div>
            ),
          },
        ],
      };
    });
  }, [envelopes, t]);

  const getStates = useMemo(() => {
    return states.map((state) => {
      return {
        label: state.name,
        value: state.abbreviation,
        key: state.abbreviation,
      };
    });
  }, []);

  const agents = useMemo(() => {
    let list = users.filter((x: any) => x.id !== currentUser?.id);
    return list.map((x: any) => {
      return {
        label: `${x.first_name} ${x.last_name}`,
        value: x.id,
      };
    });
  }, [users]);

  const isLoading = useMemo(() => {
    return (
      isAddressLoading ||
      isClientLoading ||
      isIncomeSourceLoading ||
      isPolicyLoading ||
      isDependentLoading ||
      isEnvelopeLoading ||
      isDownloadLoading ||
      isTagResponseLoading ||
      clientNotesData.isLoading ||
      isDocumentLoading
    );
  }, [
    isAddressLoading,
    isClientLoading,
    isIncomeSourceLoading,
    isPolicyLoading,
    isDependentLoading,
    isEnvelopeLoading,
    isDownloadLoading,
    isTagResponseLoading,
    clientNotesData.isLoading,
    isDocumentLoading,
  ]);

  const handleClientDocumentsDrop = (e: any) => {
    for (let i = 0; i < e.length; i++) {
      setNewClientDocuments((prev) => [...prev, e[i]]);
    }
  };

  const handleClientDocuments = (e: any) => {
    for (let i = 0; i < e.length; i++) {
      setNewClientDocuments((prev) => [...prev, e[i]]);
    }
  };

  const handleRemoveClientDocument = (index: number) => {
    const list = [...newClientDocuments];
    list.splice(index, 1);
    setNewClientDocuments(list);
  };

  const formatBytes = (bytes: number, decimals = 2) => {
    if (!+bytes) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  };

  const onSubmitInvalid: SubmitErrorHandler<EditClient> = (data) => {
    handleShowSuccessErrorModal(
      MessageTypeEnum.FAILED,
      t("PLEASE_ENTER_ALL_CLIENT_REQUIRED_FIELDS")
    );
  };

  const onSubmit: SubmitHandler<EditClient> = async (data: EditClient) => {
    if (checkInvalidStartDate(data)) {
      handleShowSuccessErrorModal(
        MessageTypeEnum.FAILED,
        t("POLICY_EFFECTIVE_START_DATE_MESSAGE")
      );
    } else if (checkInvalidEndDate(data)) {
      handleShowSuccessErrorModal(
        MessageTypeEnum.FAILED,
        t("POLICY_EFFECTIVE_END_DATE_MESSAGE")
      );
    } else if (checkPolicyMembers(data)) {
      handleShowSuccessErrorModal(
        MessageTypeEnum.FAILED,
        t("POLICY_MEMBERS_MESSAGE")
      );
    } else if (checkPolicyState(data)) {
      handleShowSuccessErrorModal(
        MessageTypeEnum.FAILED,
        t("POLICY_STATE_MESSAGE")
      );
    } else if (checkClientHasNewPolicy(data)) {
      setIsWRenewalConfirmationModalOpen(true);
      setEditClientContinueData(data);
    } else {
      if (newClientDocuments.length > 0) {
        uploadFiles(data);
      } else {
        updateClientSubmit(data, []);
      }
    }
  };

  const uploadFiles = async (data: EditClient) => {
    setIsUploadingFiles(true);

    const uploadPromises = newClientDocuments.map(async (file) => {
      const nameSplit = file.name.split(".");
      const filename = `${nameSplit[0]}-${Date.now()}.${nameSplit[1]}`;

      const result = await dispatch(
        getFileSignedUrl({ fileName: filename })
      ).unwrap();

      const uploadResponse = await fetch(result.upload_url, {
        method: "PUT",
        headers: {
          "Content-Type": "application/octet-stream",
        },
        body: file,
      });

      if (uploadResponse.ok && uploadResponse.status == 200) {
        const attachment: NewDocument = {
          name: filename,
          size: `${file.size}`,
        };

        return attachment;
      }

      return undefined;
    });

    // Esperar a que todas las subidas se completen
    const results = await Promise.all(uploadPromises);

    if (results.length > 0) {
      const attachaments: NewDocument[] = [];
      results.forEach((attach) => {
        if (attach != undefined) {
          attachaments.push(attach);
        }
      });

      setIsUploadingFiles(false);
      updateClientSubmit(data, attachaments);
    } else {
      setIsUploadingFiles(false);
      updateClientSubmit(data, []);
    }
  };

  const updateClientSubmit = async (
    data: EditClient,
    newClientAttachments: NewDocument[]
  ) => {
    const clientPolicies: NewPolicy[] = [];
    if (data.policies != undefined && data.policies!.length > 0) {
      data.policies!.forEach((po) => {
        if (po.carrier_id != undefined) {
          clientPolicies.push({
            id: po.id,
            number: po.number,
            effective_start_date: po.effective_start_date,
            effective_end_date: po.effective_end_date,
            carrier_id: +po.carrier_id,
            members_insured: po.members_insured,
            free_plan: po.free_plan,
            policy_state: po.policy_state,
            agent_id: po.agent_id,
            agent_commission_rule_id: po.agent_commission_rule_id,
          });
        }
      });
    }
    data.policies = clientPolicies;

    if (newClientAttachments.length > 0) {
      newClientAttachments.forEach((attach) => {
        data.attachments?.push(attach);
      });
    }

    data.has_to_send_renewal_message = hasToSendWRenewalMessage;

    dispatch(
      updateClient({
        client: data,
        clientId: client.id,
        agentId: currentUser.id,
      })
    ).then((e) => {
      if (e.type === "clients/updateClient/rejected") {
        setShowCreateError(true);
      } else {
        handleShowSuccessErrorModal(
          MessageTypeEnum.SUCCESS,
          t("SUCCESSFULLY_SAVED_CLIENT_INFORMATION")
        );
      }
    });
  };

  const checkInvalidStartDate = (data: EditClient) => {
    let policiesInvalidDates = 0;
    const todayDate = moment().format("YYYY-MM-DD");

    if (data.policies != undefined && data.policies!.length > 0) {
      data.policies!.forEach((po) => {
        if (po.carrier_id != undefined) {
          if (po.id == undefined) {
            if (po.effective_start_date != undefined) {
              if (moment(po.effective_start_date).isValid()) {
                if (moment(po.effective_start_date).isBefore(todayDate)) {
                  policiesInvalidDates++;
                }
              } else {
                policiesInvalidDates++;
              }
            } else {
              policiesInvalidDates++;
            }
          }
        }
      });
    }

    return policiesInvalidDates > 0;
  };

  const checkInvalidEndDate = (data: EditClient) => {
    let policiesInvalidDates = 0;
    const todayDate = moment().format("YYYY-MM-DD");

    if (data.policies != undefined && data.policies!.length > 0) {
      data.policies!.forEach((po) => {
        if (po.carrier_id != undefined) {
          if (po.id == undefined) {
            if (po.effective_end_date != undefined) {
              if (moment(po.effective_end_date).isValid()) {
                if (
                  moment(po.effective_end_date).isBefore(todayDate) ||
                  moment(po.effective_end_date).isSameOrBefore(
                    po.effective_start_date
                  )
                ) {
                  policiesInvalidDates++;
                }
              } else {
                policiesInvalidDates++;
              }
            } else {
              policiesInvalidDates++;
            }
          }
        }
      });
    }

    return policiesInvalidDates > 0;
  };

  const checkPolicyMembers = (data: EditClient) => {
    let policiesMembers = 0;

    if (data.policies != undefined && data.policies!.length > 0) {
      data.policies!.forEach((po) => {
        if (po.carrier_id != undefined) {
          if (po.members_insured != undefined) {
            if (po.members_insured <= 0) {
              policiesMembers++;
            }
          } else {
            policiesMembers++;
          }
        }
      });
    }

    return policiesMembers > 0;
  };

  const checkPolicyState = (data: EditClient) => {
    let policiesState = 0;

    if (data.policies != undefined && data.policies!.length > 0) {
      data.policies!.forEach((po) => {
        if (po.carrier_id != undefined) {
          if (po.policy_state == undefined || po.policy_state == null) {
            policiesState++;
          }
        }
      });
    }

    return policiesState > 0;
  };

  const downloadDocument = async (attach: any) => {
    try {
      setIsDownloadingFile(true);
      const result = await dispatch(
        getFileDownloadUrl({ fileName: attach.name })
      ).unwrap();

      const response = await fetch(result.download_url, {
        method: "GET",
        headers: {
          "Content-Type": "application/octet-stream",
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const blob = await response.blob();
      var link = window.URL.createObjectURL(blob);
      const aElement = document.createElement("a");
      document.body.appendChild(aElement);
      aElement.style.display = "none";
      aElement.href = link;
      aElement.download = attach.name;
      aElement.click();
      document.body.removeChild(aElement);

      setIsDownloadingFile(false);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };

  const getClients = useMemo(() => {
    return clients.map((client: Client) => {
      return {
        label: `${client.first_name} ${client.last_name}`,
        value: `${client.first_name} ${client.last_name}`,
        key: client.id,
      };
    });
  }, [clients]);

  const getAttachmentName = (name: string | undefined) => {
    if (name != undefined) {
      const nameSplit = name.split("-");
      const lastSegment = nameSplit[nameSplit.length - 1];
      const lastSegmentSplit = lastSegment.split(".");
      const extension = lastSegmentSplit[lastSegmentSplit.length - 1];

      const newName = `${name.replaceAll(`-${lastSegment}`, "")}.${extension}`;
      return newName;
    }
    return "";
  };

  const handleShowSuccessErrorModal = (
    messageType: number,
    message: string
  ) => {
    setShowSuccessErrorModal({
      show: true,
      messageType: messageType,
      message: message,
    });
  };

  const handleCloseSuccessErrorModal = () => {
    if (showSuccessErrorModal.messageType === MessageTypeEnum.SUCCESS) {
      setShowSuccessErrorModal({
        show: false,
        messageType: MessageTypeEnum.FAILED,
        message: "",
      });
      navigate(ClientsPath);
    } else if (showSuccessErrorModal.messageType === MessageTypeEnum.FAILED) {
      setShowSuccessErrorModal({
        show: false,
        messageType: MessageTypeEnum.FAILED,
        message: "",
      });
      setShowCreateError(false);
    }
  };

  const showPolicyMessageConfirmationModal = () => {
    return (
      <ConfirmationModal
        isOpen={isWRenewalConfirmationModalOpen}
        onClose={cancelWelcomeRenewalMessage}
        label={t("WELCOME_RENEWAL_MESSAGE")}
        cancelButton={{
          label: t("NO"),
          icon: <XMarkIcon />,
          onClick: cancelWelcomeRenewalMessage,
        }}
        saveButton={{
          label: t("SEND"),
          icon: <PaperAirplaneIcon />,
          onClick: sendWelcomeRenewalMessage,
        }}
      >
        {/*isConfirmationLoading && <LoadingMask />*/}
        <div className="w-full flex flex-wrap items-center justify-center">
          <label className="text-lg font-medium py-4">
            {t("SEND_WELCOME_RENEWAL_MESSAGE")}
          </label>
        </div>
      </ConfirmationModal>
    );
  };

  const cancelWelcomeRenewalMessage = () => {
    setIsWRenewalConfirmationModalOpen(false);
    //setHasToSendWRenewalMessage(false);
    hasToSendWRenewalMessage = false;
    if (editClientContinueData != undefined) {
      if (newClientDocuments.length > 0) {
        uploadFiles(editClientContinueData);
      } else {
        updateClientSubmit(editClientContinueData, []);
      }
    }
  };

  const sendWelcomeRenewalMessage = () => {
    setIsWRenewalConfirmationModalOpen(false);
    //setHasToSendWRenewalMessage(true);
    hasToSendWRenewalMessage = true;
    if (editClientContinueData != undefined) {
      if (newClientDocuments.length > 0) {
        uploadFiles(editClientContinueData);
      } else {
        updateClientSubmit(editClientContinueData, []);
      }
    }
  };

  const checkClientHasNewPolicy = (data: EditClient) => {
    let newPolicies = 0;

    if (data.policies != undefined && data.policies!.length > 0) {
      data.policies!.forEach((po) => {
        if (po.carrier_id != undefined || po.is_new) {
          newPolicies++;
        }
      });
    }

    return newPolicies > 0;
  };

  const PersonalDetailsSection = useMemo(() => {
    return (
      <div className="w-full flex flex-wrap">
        <ReactiveFormInput
          control={control}
          className="md:w-1/3"
          label={t("FIRST_NAME")}
          name="first_name"
          isRequired
        />
        <ReactiveFormInput
          control={control}
          className="md:w-1/3"
          label={t("MIDDLE_NAME")}
          name="middle_name"
        />
        <ReactiveFormInput
          control={control}
          className="md:w-1/3"
          label={t("LAST_NAME")}
          name="last_name"
          isRequired
        />
        <ReactiveFormInput
          control={control}
          type="date"
          className="md:w-1/4"
          label={t("DATE_OF_BIRTH")}
          name="date_of_birth"
          isRequired
        />
        <ReactiveFormSelect
          className="md:w-1/4"
          name="country_of_birth"
          label={t("COUNTRY_OF_BIRTH")}
          options={getCountries()}
          control={control}
          isRequired
        />
        <ReactiveFormSelect
          className="md:w-1/4"
          name="gender_id"
          label={t("GENDER")}
          options={getGenders()}
          control={control}
        />
        <ReactiveFormSelect
          className="md:w-1/4"
          name="marital_status_id"
          label={t("MARITAL_STATUS")}
          options={getMaritalStatuses()}
          control={control}
        />
        <ReactiveFormPhoneInput
          control={control}
          className="md:w-1/4"
          name="phone"
          label={t("PHONE")}
          isRequired
        />
        <ReactiveFormInput
          control={control}
          className="md:w-1/4"
          label={t("EMAIL")}
          name="email"
          type="email"
          isRequired
        />
        <ReactiveFormInput
          control={control}
          className="md:w-1/4"
          label={t("HEIGHT")}
          name="height"
          type="number"
        />
        <ReactiveFormInput
          control={control}
          className="md:w-1/4"
          label={t("WEIGHT")}
          name="weight"
          type="number"
        />
        <ReactiveFormSelect
          control={control}
          className={"w-full px-3 md:w-1/3"}
          name="referred_by"
          label={t("REFERENCE")}
          options={getClients}
        />
        <ReactiveFormInput
          control={control}
          className="md:w-1/3"
          label={t("DOCTOR_FULL_NAME")}
          name="doctor_full_name"
        />
        <ReactiveFormInput
          control={control}
          className="md:w-1/3"
          label={t("DOCTOR_CITY")}
          name="doctor_city"
        />
        <ReactiveFormRadioButton
          className={"w-full px-3 md:w-1/3"}
          name="tobacco"
          label={t("4_OR_MORE_TOBACCO")}
          control={control}
          options={[
            { label: t("YES"), value: "1" },
            { label: t("NO"), value: "0" },
          ]}
        />
        <ReactiveFormRadioButton
          className={"w-full px-3 md:w-1/3"}
          name="po_box"
          label={t("PO_BOX")}
          control={control}
          options={[
            { label: t("YES"), value: "1" },
            { label: t("NO"), value: "0" },
          ]}
        />
        <ReactiveFormRadioButton
          className={"w-full px-3 md:w-1/3"}
          name="file_tax_return"
          label={t("WILL_YOU_FILE_TAX_RETURN")}
          control={control}
          options={[
            { label: t("YES"), value: "1" },
            { label: t("NO"), value: "0" },
          ]}
        />
        {agentTags.length > 0 && (
          <div className="w-full">
            <div className="text-lg font-semibold py-3 px-3 w-full">
              {t("ADDITIONAL_INFORMATION")}
            </div>
            <div className="w-full flex flex-wrap gap-y-4">
              {agentTags.map((tag, index) => {
                return (
                  <ReactiveFormInput
                    control={control}
                    className="md:w-1/3"
                    label={tag.label}
                    name={`tags_responses.${index}.response`}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  }, [control, getClients, t]);

  const AddressesSection = useMemo(() => {
    return (
      <div className="w-full flex flex-wrap gap-y-4">
        {addresses
          ?.sort((x) => Number(x.is_main_address))
          .map((source, index) => {
            return (
              <Fragment key={source.id}>
                <div
                  className={classNames({
                    "w-full flex flex-wrap": true,
                    "border-b pb-5 mb-4 border-slate-300":
                      index + 1 !== addresses.length,
                  })}
                >
                  {source.is_main_address ? (
                    <div className="text-lg font-semibold pb-3 px-3 w-full">
                      {t("MAIN_ADDRESS")}
                    </div>
                  ) : (
                    <></>
                  )}
                  <ReactiveFormInput
                    control={control}
                    className="md:w-1/2"
                    label={t("STREET_ADDRESS")}
                    name={`addresses.${index}.address_line_1`}
                  />
                  <ReactiveFormInput
                    control={control}
                    className="md:w-1/2"
                    label={t("STREET_ADDRESS2")}
                    name={`addresses.${index}.address_line_2`}
                  />
                  <ReactiveFormInput
                    control={control}
                    className="md:w-1/3"
                    label={t("CITY")}
                    name={`addresses.${index}.city`}
                  />
                  <ReactiveFormSelect
                    className="md:w-1/3"
                    name={`addresses.${index}.state`}
                    label={t("STATE")}
                    options={getStates}
                    control={control}
                  />

                  <div className="flex md:w-1/3">
                    <ReactiveFormInput
                      control={control}
                      className="mr-2"
                      label={t("ZIP_CODE")}
                      name={`addresses.${index}.zip_code`}
                    />
                    <div className="self-end mb-3 mr-3">
                      {+index === 0 ? (
                        <Button
                          iconOnly
                          classNames="h-10 w-10"
                          onClick={() => appendAddress(newAddressInitialValues)}
                        >
                          <PlusIcon className="h-4 w-4 ml-1" />
                        </Button>
                      ) : (
                        <Button
                          iconOnly
                          classNames="h-10 w-10"
                          onClick={() => removeAddress(index)}
                        >
                          <TrashIcon className="h-4 w-4 ml-1" />
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </Fragment>
            );
          })}
      </div>
    );
  }, [addresses, t, control, getStates, appendAddress, removeAddress]);

  const ImmigrationStatusSection = useMemo(() => {
    return (
      <div className="w-full flex flex-wrap">
        <ReactiveFormSelect
          className="md:w-1/3"
          name="immigration_status.immigration_status_type_id"
          label={t("IMMIGRATION_STATUS_TYPE")}
          options={getImmigrationTypes()}
          control={control}
        />
        <ReactiveFormInput
          control={control}
          className="md:w-1/3"
          label={t("PASSPORT_NUMBER")}
          name="immigration_status.passport_number"
        />
        <ReactiveFormInput
          control={control}
          className="md:w-1/3"
          label={t("USCIS_NUMBER")}
          name="immigration_status.uscis_number"
        />
        <ReactiveFormInput
          control={control}
          className="md:w-1/4"
          label={t("SOCIAL_SECURITY_NUMBER")}
          name="immigration_status.social_security_number"
        />
        <ReactiveFormInput
          control={control}
          className="md:w-1/4"
          label={t("SSN_ISSUE_DATE")}
          name="immigration_status.ssn_issue_date"
          type="date"
        />
        <ReactiveFormInput
          control={control}
          className="md:w-1/4"
          label={t("GREEN_CARD_NUMBER")}
          name="immigration_status.green_card_number"
        />
        <ReactiveFormInput
          control={control}
          className="md:w-1/4"
          label={t("GC_EXPIRATION_DATE")}
          name="immigration_status.gc_expiration_date"
          type="date"
        />
        <ReactiveFormInput
          control={control}
          className="md:w-1/4"
          label={t("WORK_PERMIT_CARD")}
          name="immigration_status.work_permit_card"
        />
        <ReactiveFormInput
          control={control}
          className="md:w-1/4"
          label={t("WPC_EXPIRATION_DATE")}
          name="immigration_status.wpc_expiration_date"
          type="date"
        />
        <ReactiveFormInput
          control={control}
          className="md:w-1/4"
          label={t("DRIVER_LICENSE")}
          name="immigration_status.driver_license"
        />
        <ReactiveFormInput
          control={control}
          className="md:w-1/4"
          label={t("DL_EXPIRATION_DATE")}
          name="immigration_status.dl_expiration_date"
          type="date"
        />
      </div>
    );
  }, [control, t]);

  const IncomeSourcesSection = useMemo(() => {
    return (
      <div className="w-full flex flex-wrap">
        {incomeSources?.map((source, index) => {
          return (
            <Fragment key={source.id}>
              <ReactiveFormSelect
                className="md:w-1/5"
                name={`income_sources.${index}.employee_type_id`}
                label={t("EMPLOYEE_TYPE")}
                options={getEmployeeTypes()}
                control={control}
              />
              <ReactiveFormInput
                control={control}
                className="md:w-1/5"
                label={t("EMPLOYER_NAME")}
                name={`income_sources.${index}.employer_name`}
              />
              <ReactiveFormPhoneInput
                control={control}
                className="md:w-1/5"
                label={t("PHONE")}
                name={`income_sources.${index}.phone_number`}
              />
              <ReactiveFormInput
                control={control}
                className="md:w-1/5"
                label={t("OCCUPATION")}
                name={`income_sources.${index}.occupation`}
              />
              <div className="flex md:w-1/5">
                <ReactiveFormInput
                  control={control}
                  type="number"
                  className="mr-2"
                  label={t("INCOME")}
                  name={`income_sources.${index}.income`}
                />
                <div className="self-end mb-3">
                  {+index === 0 ? (
                    <Button
                      iconOnly
                      classNames="h-10 w-10"
                      onClick={() =>
                        appendIncomeSource(newIncomeSourceInitialValues)
                      }
                    >
                      <PlusIcon className="h-4 w-4 ml-1" />
                    </Button>
                  ) : (
                    <Button
                      iconOnly
                      classNames="h-10 w-10"
                      onClick={() => removeIncomeSource(index)}
                    >
                      <TrashIcon className="h-4 w-4 ml-1" />
                    </Button>
                  )}
                </div>
              </div>
            </Fragment>
          );
        })}
      </div>
    );
  }, [incomeSources, t, control, appendIncomeSource, removeIncomeSource]);

  const PoliciesSection = useMemo(() => {
    return (
      <div className="w-full">
        <ClientPoliciesTable
          policies={policies}
          addPolicy={() =>
            setShowClientPolicyModal({ show: true, update: false })
          }
          removePolicy={(index) => removePolicy(index)}
          updatePolicy={(index: number, policy: EditPolicy) =>
            setShowClientPolicyModal({
              show: true,
              update: true,
              policy: policy,
              index: index,
            })
          }
        />
      </div>
    );
  }, [policies, removePolicy]);

  const DependentsSection = useMemo(() => {
    return (
      <div className="w-full flex flex-wrap gap-y-4">
        {dependents?.map((source, index) => {
          return (
            <Fragment key={source.id}>
              <div
                className={classNames({
                  "w-full flex flex-wrap": true,
                  "border-b pb-5 mb-5 border-slate-300":
                    index + 1 !== dependents.length,
                })}
              >
                <ReactiveFormInput
                  control={control}
                  className="md:w-1/3 pl-3"
                  label={t("FULL_NAME")}
                  name={`dependents.${index}.name`}
                />
                <ReactiveFormInput
                  control={control}
                  className="md:w-1/3"
                  label={t("SOCIAL_SECURITY_NUMBER")}
                  name={`dependents.${index}.social_security_number`}
                />
                <ReactiveFormInput
                  control={control}
                  type="email"
                  className="md:w-1/3"
                  label={t("EMAIL")}
                  name={`dependents.${index}.email`}
                />
                <ReactiveFormSelect
                  className="md:w-1/4"
                  name={`dependents.${index}.gender_id`}
                  label={t("GENDER")}
                  options={getGenders()}
                  control={control}
                />
                <ReactiveFormInput
                  control={control}
                  type="date"
                  className="md:w-1/4"
                  label={t("DATE_OF_BIRTH")}
                  name={`dependents.${index}.date_of_birth`}
                />
                <ReactiveFormSelect
                  className="md:w-1/4"
                  name={`dependents.${index}.relationship_id`}
                  label={t("RELATIONSHIP")}
                  options={getRelationships()}
                  control={control}
                />

                <div className="flex md:w-1/4">
                  <ReactiveFormPhoneInput
                    control={control}
                    className="mr-2"
                    label={t("PHONE")}
                    name={`dependents.${index}.phone`}
                  />
                  <div className="self-end mb-3 mr-3">
                    {+index === 0 ? (
                      <Button
                        iconOnly
                        classNames="h-10 w-10"
                        onClick={() =>
                          appendDependent(newDependentInitialValues)
                        }
                      >
                        <PlusIcon className="h-4 w-4 ml-1" />
                      </Button>
                    ) : (
                      <Button
                        iconOnly
                        classNames="h-10 w-10"
                        onClick={() => removeDependent(index)}
                      >
                        <TrashIcon className="h-4 w-4 ml-1" />
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </Fragment>
          );
        })}
      </div>
    );
  }, [dependents, control, t, appendDependent, removeDependent]);

  const ConsentDocumentsSection = useMemo(() => {
    return (
      <Form handleOnSubmit={handleSubmit}>
        <div className="flex justify-between w-full">
          <div className="text-lg font-semibold p-3">
            {t("SIGNED_DOCUMENTS")}
          </div>
          <div className="text-end p-3">
            <Button
              iconOnly
              classNames="h-10 w-10"
              tooltip={t("SEND_DOCUMENT")}
              onClick={openSendDocumentModal}
            >
              <RectangleStackIcon className="h-4 w-4 ml-1" />
            </Button>
          </div>
        </div>
        <div className="w-full">
          <Table
            columns={[
              { header: `${t("NAME")}` },
              { header: `${t("SENT_BY")}` },
              { header: `${t("SENT_DOCUMENT_DATE")}` },
              { header: `${t("COMPLETED_DOCUMENT_DATE")}` },
              { header: `${t("STATUS")}` },
              { header: "" },
            ]}
            data={getDocumentEnvelopes}
          />
        </div>
      </Form>
    );
  }, [getDocumentEnvelopes, handleSubmit, t]);

  const DocumentsSection = useMemo(() => {
    return (
      <div className="w-full">
        <div className="pb-5">
          <ReactiveFormUploadFile
            control={control}
            className="w-full"
            label={t("UPLOAD_DOCUMENTS")}
            name="client_documents"
            handleOnChange={handleClientDocuments}
            handleOnDrop={handleClientDocumentsDrop}
          />
        </div>
        {newClientDocuments.map((attach, index) => {
          return (
            <Fragment key={attach.name}>
              <div className="w-full px-4 mb-3">
                <div className="flex justify-between">
                  <div className="self-center">
                    <label className="text-sm self-center">{attach.name}</label>
                    <label className="text-gray-600 text-sm self-center pl-3">
                      {formatBytes(attach.size)}
                    </label>
                  </div>
                  <Button
                    iconOnly
                    classNames="h-7 w-7 self-end"
                    onClick={() => handleRemoveClientDocument(index)}
                  >
                    <TrashIcon className="h-3 w-3" />
                  </Button>
                </div>
                <hr className="mt-3"></hr>
              </div>
            </Fragment>
          );
        })}
        {attachments?.map((attach, index) => {
          return (
            <Fragment key={attach.id}>
              <div className="w-full px-4 mb-3">
                <div className="flex justify-between">
                  <div className="self-center">
                    <label className="text-sm self-center">
                      {getAttachmentName(attach.name)}
                    </label>
                    <label className="text-gray-600 text-sm self-center pl-3">
                      {formatBytes(+attach.size!)}
                    </label>
                  </div>
                  <div className="self-end">
                    <Button
                      iconOnly
                      classNames="h-7 w-7 self-end mr-1"
                      onClick={() => downloadDocument(attach)}
                      tooltip={t("DOWNLOAD_DOCUMENT")}
                    >
                      <ArrowDownTrayIcon className="h-3 w-3" />
                    </Button>
                    <Button
                      iconOnly
                      classNames="h-7 w-7 self-end"
                      onClick={() => removeAttachment(index)}
                      tooltip={t("DELETE_DOCUMENT")}
                    >
                      <TrashIcon className="h-3 w-3" />
                    </Button>
                  </div>
                </div>
                <hr className="mt-3"></hr>
              </div>
            </Fragment>
          );
        })}
      </div>
    );
  }, [
    handleClientDocuments,
    handleClientDocumentsDrop,
    handleRemoveClientDocument,
    removeAttachment,
    downloadDocument,
    t,
  ]);

  const NotesSection = useMemo(() => {
    return (
      <div className="w-full flex flex-col">
        {clientNotesData.clientNotes.map((note: ClientNote, index: number) => {
          return (
            <div className="pb-5 px-3">
              <div className="flex flex-row items-center">
                <div className="text-sm font-medium text-black mb-0.5">
                  {t("CREATED_BY")} {note.creation_user.first_name}{" "}
                  {note.creation_user.last_name}
                </div>
                <span className="bg-asureisGray w-1 h-1 rounded-md text-center mx-1.5"></span>
                <div className="text-asureisGray text-sm">
                  {shortDateFormat(note.creation_date)}
                </div>
              </div>
              <div className="flex flex-row items-center mt-2">
                <div className="border border-slate-300 rounded-md bg-slate-100 shadow-sm py-2 px-3 min-w-64 min-h-15">
                  {note.note}
                </div>
              </div>
            </div>
          );
        })}
        <ReactiveFormInputArea
          className="mt-10"
          name={"note"}
          label={t("NEW_NOTE")}
          control={notesControl}
          placeholder="Write something here..."
        />
        <div className="w-full flex justify-end px-3 py-2">
          <Button onClick={saveNote}>
            <span className="flex items-center font-semibold pr-3">
              <div className="w-8 p-1 aspect-square mr-2">
                <CheckIcon />
              </div>
              {t("SAVE_NOTE")}
            </span>
          </Button>
        </div>
      </div>
    );
  }, [clientNotesData, notesControl, t]);

  const getSectionInfo = useCallback(
    (key: ClientFormSectionEnum) => {
      switch (key) {
        case ClientFormSectionEnum.PERSONAL_DETAILS:
          return PersonalDetailsSection;
        case ClientFormSectionEnum.ADDRESS:
          return AddressesSection;
        case ClientFormSectionEnum.IMMIGRATION_STATUS:
          return ImmigrationStatusSection;
        case ClientFormSectionEnum.INCOME_SOURCES:
          return IncomeSourcesSection;
        case ClientFormSectionEnum.POLICIES:
          return PoliciesSection;
        case ClientFormSectionEnum.DEPENDENTS:
          return DependentsSection;
        case ClientFormSectionEnum.CONSENT_DOCUMENTS:
          return ConsentDocumentsSection;
        case ClientFormSectionEnum.DOCUMENTS:
          return DocumentsSection;
        case ClientFormSectionEnum.NOTES:
          return NotesSection;
      }
    },
    [
      PersonalDetailsSection,
      AddressesSection,
      ImmigrationStatusSection,
      IncomeSourcesSection,
      PoliciesSection,
      DependentsSection,
      ConsentDocumentsSection,
      DocumentsSection,
      NotesSection,
    ]
  );

  const getBlocks = useMemo(() => {
    return getClientFormSections().map((section) => {
      const key = section.key as ClientFormSectionEnum;
      const currentSection = openedSections.get(key);
      const updatedSections = new Map(openedSections); // Create a new Map
      return {
        label: section.label,
        key: section.key,
        isOpen: currentSection,
        handleChange: () => {
          updatedSections.set(key, !currentSection); // Update the new Map
          setOpenedSections(updatedSections); // Set the new Map
        },
        info: getSectionInfo(key),
      };
    });
  }, [openedSections, getSectionInfo]);

  return (
    <div>
      {(isLoading || isUploadingFiles || isDownloadingFile) && <LoadingMask />}
      {sendDocumentModal()}
      {voidDocumentModal()}
      {showPolicyMessageConfirmationModal()}
      <ClientPolicyModal
        info={showClientPolicyModal}
        onClose={() => setShowClientPolicyModal({ show: false, update: false })}
        addPolicy={(policy: EditPolicy) => appendPolicy(policy)}
        updatePolicy={(index: number, policy: NewPolicy) =>
          updatePolicy(index, policy)
        }
      />
      <SuccessErrorModal
        info={showSuccessErrorModal}
        onClose={handleCloseSuccessErrorModal}
      />
      <ToastContainer progressStyle={{ background: "#D4AF37" }} />
      <Form handleOnSubmit={handleSubmit(onSubmit, onSubmitInvalid)}>
        <Accordion blocks={getBlocks} />
        <div className="px-5 pb-5 justify-between w-full flex">
          <Button isTerciary onClick={() => navigate(ClientsPath)}>
            <span className="flex items-center font-semibold pr-3">
              <div className="w-8 p-1 aspect-square mr-2">
                <ArrowLeftIcon />
              </div>
              {t("RETURN")}
            </span>
          </Button>
          <Button onClick={handleSubmit(onSubmit, onSubmitInvalid)}>
            <span className="flex items-center font-semibold pr-3">
              <div className="w-8 p-1 aspect-square mr-2">
                <CheckIcon />
              </div>
              {t("SAVE")}
            </span>
          </Button>
        </div>
      </Form>
    </div>
  );
};
