import React, { useState, useEffect, useRef } from "react";
import { Controller, RefCallBack } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormSelect } from "./FormSelect";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../state/store";
import { AsyncThunk } from "@reduxjs/toolkit";
import { FormInput } from "./FormInput";
import { FormRemoteSearchableSelect } from "./FormRemoteSearchableSelect";

interface Props {
    name: string;
    label: string;
    dataSourceAsyncThunk: AsyncThunk<{
        label: string;
        value: number;
    }[], {
        q: string;
        agencyId: number;
    }, any>;
    defaultItem?: {label: string, value: string};
    data: any;
    className?: string;
    placeholder?: string;
    error?: string;
    isDisabled?: boolean;
    control: any;
    isRequired?: boolean;
    onBlur?: () => void;
    onChange?: (item: {label: string, value: string}) => void;
}

export const ReactiveFormRemoteSearchableSelect = (props: Props) => {
    const { t } = useTranslation();

    return (
        <Controller
        name={props.name}
        control={props.control}
        rules={{ 
            required: { value: props.isRequired ?? false, message: t('FIELD_REQUIRED') }}
        }

        render={({ field: { onChange, value }, fieldState: { error } }) => (
            <>
                <FormRemoteSearchableSelect 
                    name={props.name} 
                    value={props.defaultItem?.value ?? value?.value} 
                    label={props.label} 
                    dataSourceAsyncThunk={props.dataSourceAsyncThunk} 
                    data={props.data}
                    isDisabled={props.isDisabled}
                    defaultItem={props.defaultItem}
                    onChange={(e) => {
                        onChange(e.value);
                        if(props.onChange) {
                            props.onChange(e);
                        }
                    }}
                    onBlur={props.onBlur}
                    error={error ? error.message ? error.message : t("FIELD_REQUIRED") : ""}
                    className={props.className}
                    placeholder={props.placeholder}
                    />
            </>
        )}
    />
    );
};
